import { BaseResourceStore, TInitialState } from "_common/resources/BaseResourceStore";
import {
    FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH,
    PROPERTY_PURPOSE,
    PROPERTY_TYPE,
    TPropertyDashboardListingMdl,
    TPropertyListingMdl,
} from "properties/_models/PropertyMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import i18next from "i18next";
import { action, computed, observable } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import {
    getInitialStateValue,
    getResourceInitialStateValue,
    putPromiseResourceResultInInitialState,
    putPromiseResultInInitialState,
} from "_common/_utils/initialStateUtils";
import { ListStore } from "_common/list/ListStore";
import { TLang } from "_configs/sharedConfig";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { TMapCoordinates, TMapLocation } from "maps/Map";
import { DEFAULT_LOCATION, DEFAULT_MAP_COORDINATES, DEFAULT_ZOOM } from "_common/_utils/searchUtils";
import { TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { TCity } from "_common/regions/regions";
import { TPropertyTypeCard } from "_common/propertyTypes/propertyTypes";
import _ from "lodash";
import { LatLng } from "leaflet";
import { WithRequiredProperty } from "_common/types/GenericTypes";

export const PROPERTIES_NAVIGATION_HISTORY_KEY = "PROPERTIES_NAVIGATION_HISTORY";

type TBtns = {
    [PROPERTY_PURPOSE.BUY]: {
        [PROPERTY_TYPE.house]: boolean;
        [PROPERTY_TYPE.condo]: boolean;
        [PROPERTY_TYPE.villa]: boolean;
        [PROPERTY_TYPE.land]: boolean;
        [PROPERTY_TYPE.commercial]: boolean;
        [PROPERTY_TYPE.hotel]: boolean;
    };
};

export class PropertiesStore extends BaseResourceStore<TPropertyListingMdl> {
    purpose: PROPERTY_PURPOSE | undefined;

    @observable btnsState = new LoadingStateMdl<TBtns>();
    @observable btns: TBtns = {
        [PROPERTY_PURPOSE.BUY]: {
            [PROPERTY_TYPE.house]: false,
            [PROPERTY_TYPE.condo]: false,
            [PROPERTY_TYPE.villa]: false,
            [PROPERTY_TYPE.land]: false,
            [PROPERTY_TYPE.commercial]: false,
            [PROPERTY_TYPE.hotel]: false,
        },
    };

    @observable count = 0;
    @observable addressParams: {
        region: string | undefined;
        regionLong: string | undefined;
        city: string | undefined;
        address: string | undefined;
        neighbourhood: string | undefined;
    } = {
        region: "",
        city: "",
        address: "",
        neighbourhood: "",
        regionLong: "",
    };

    @observable searchParams: {
        autocomplete: google.maps.places.Autocomplete | null;
        location: TMapLocation | LatLng;
        zoom: number;
        mapCoordinates: TMapCoordinates;
    } = {
        autocomplete: null,
        location: DEFAULT_LOCATION,
        zoom: DEFAULT_ZOOM,
        mapCoordinates: DEFAULT_MAP_COORDINATES,
    };

    @observable featuredPropertiesState: LoadingStateMdl<TPropertyListingMdl[]> = new LoadingStateMdl();
    @observable featuredProperties: TPropertyListingMdl[] = [];
    @observable sortCitiesState: LoadingStateMdl<TCity[]> = new LoadingStateMdl();
    @observable sortCities: TCity[] = [];
    @observable sortTypesState: LoadingStateMdl<TPropertyTypeCard[]> = new LoadingStateMdl();
    @observable sortTypes: TPropertyTypeCard[] = [];
    @observable items: TPropertyListingMdl[] = [];
    @observable mapPropertiesSelected: TPropertyListingMdl | undefined = undefined;
    @observable mapUnitsSelected: TUnitListingMdl[] | undefined = undefined;
    @observable propertiesSearchState: LoadingStateMdl<TPropertyListingMdl[]> = new LoadingStateMdl<
        TPropertyListingMdl[]
    >();
    @observable propertiesSearch: TPropertyListingMdl[] = [];
    abortControllers = {
        deepList: {
            ctrl: new AbortController(),
            state: new LoadingStateMdl(),
        },
        list: {
            ctrl: new AbortController(),
            state: new LoadingStateMdl(),
        },
    };

    setMapPropertiesSelected = _.debounce((property?: TPropertyListingMdl) => {
        this.mapPropertiesSelected = property;
    }, 400);

    @action setSearchParams(
        params: { [key in "autocomplete" | "location" | "zoom" | "mapCoordinates"]: any | number },
    ) {
        this.searchParams = {
            ...this.searchParams,
            ...params,
        };
    }

    constructor(purpose?: PROPERTY_PURPOSE) {
        super("properties");
        if (purpose) {
            this.purpose = purpose;
            this.onInit();
        }
    }

    @computed get getArrCache() {
        const q = this.items.length;
        if (this.listsStores[PROPERTY_PURPOSE.BUY]?.filters.length > 2) {
            return this.items;
        }
        return Object.values(this.cache);
    }

    @action
    protected onReset() {
        this.items = [];
        this.cache = {};
        this.listsStores = {};
        this.btnsState = new LoadingStateMdl();
        this.featuredPropertiesState = new LoadingStateMdl();
        this.featuredProperties = [];
        this.sortCitiesState = new LoadingStateMdl();
        this.sortCities = [];
        this.sortTypesState = new LoadingStateMdl();
        this.sortTypes = [];
        this.items = [];
        this.mapPropertiesSelected = undefined;
        this.mapUnitsSelected = [];
        this.propertiesSearchState = new LoadingStateMdl();
        super.onReset();
    }

    @action resetItems() {
        this.items = [];
    }

    @action setLocation(location?: TMapLocation) {
        if (location) this.searchParams.location = location;
        else this.searchParams.location = DEFAULT_LOCATION;
    }

    @action setBounds(bounds?: TMapCoordinates) {
        if (bounds) this.searchParams.mapCoordinates = bounds;
        else this.searchParams.location = DEFAULT_LOCATION;
    }

    @action setAutocomplete(autocomplete: google.maps.places.Autocomplete | null) {
        this.searchParams.autocomplete = autocomplete;
    }

    @action
    putItemInCache(item: TPropertyListingMdl) {
        super.putItemInCache(item);
        const itemIdex = this.items.findIndex((_item) => _item._id === item._id);
        if (itemIdex === -1) this.items.push(item);
    }

    list(
        offset = 0,
        limit?: number,
        _listId?: string,
        sort?: { [key: string]: number },
        filters?: TFilter[],
        countForStats = true,
    ) {
        if (this.abortControllers.list.state.isLoading) {
            this.abortControllers.list.ctrl.abort();
        }
        this.abortControllers.list.ctrl = new AbortController();
        this.abortControllers.list.state.startLoading();
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        if (!filters) filters = [];
        if (this.purpose && filters.findIndex((filter) => filter.id === "purpose") < 0) {
            filters.push({ id: "purpose", type: TFilterType.ENUM, value: this.purpose });
        }
        const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/listing?offset=${offset}&limit=${limit}${sortParam}${filtersParam}&lang=${
            i18next.language
        }&browser=${countForStats && __BROWSER__}`;
        const promise = fetchUtils
            .get<{ count: number; items: TPropertyListingMdl[] }>(url, {
                signal: this.abortControllers.list.ctrl.signal,
            })
            .then(
                ({ data: { count, items } }) => {
                    this.abortControllers.list.state.setSuccess();
                    return {
                        count,
                        items: items.map((item) => {
                            const reformattedItem = this.reformatItem(item);
                            this.putItemInCache(reformattedItem);
                            return reformattedItem;
                        }),
                    };
                },
                (e) => this.abortControllers.list.state.setError(e),
            );
        return promise;
    }

    listForDeveloper(sort?: { [key: string]: number }, filters?: TFilter[], token?: string) {
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        let filtersParam = "";
        if (!filters) filters = [];
        if (this.purpose && filters.findIndex((filter) => filter.id === "purpose") < 0) {
            filters.push({ id: "purpose", type: TFilterType.ENUM, value: this.purpose });
        }
        filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/dashboardListing${token ? `/${token}` : ""}?${sortParam}${filtersParam}&lang=${
            i18next.language
        }`;
        return fetchUtils.get<TPropertyDashboardListingMdl[]>(url).then(({ data }) => {
            data.map((property) => {
                const reformattedItem = this.reformatItem(property);
                this.putItemInCache(reformattedItem);
                return reformattedItem;
            });
            return { count: data.length, items: data };
        });
    }

    deepList(filters?: TFilter[]) {
        if (this.abortControllers.deepList.state.isLoading) {
            this.abortControllers.deepList.ctrl.abort();
        }
        this.abortControllers.deepList.ctrl = new AbortController();
        this.abortControllers.deepList.state.startLoading();
        if (!filters) filters = [];
        if (this.purpose && filters.findIndex((filter) => filter.id === "purpose") < 0) {
            filters.push({ id: "purpose", type: TFilterType.ENUM, value: this.purpose });
        }
        const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/deepListing?${filtersParam}&lang=${i18next.language}`;
        const promise = fetchUtils
            .get<{ count: number; items: TPropertyListingMdl[] }>(url, {
                signal: this.abortControllers.deepList.ctrl.signal,
            })
            .then(
                ({ data: { count, items } }) => {
                    this.abortControllers.deepList.state.setSuccess();
                    return {
                        count,
                        items: items.map((item) => {
                            const reformattedItem = this.reformatItem(item);
                            this.putItemInCache(reformattedItem);
                            return reformattedItem;
                        }),
                    };
                },
                (e) => this.abortControllers.deepList.state.setError(e),
            );
        return promise;
    }

    fetchPremiumProperties(
        limit?: number,
        sort?: { [key: string]: number },
        filters?: TFilter[],
        purpose?: PROPERTY_PURPOSE,
    ) {
        if (!this.featuredPropertiesState.isLoading && !this.featuredPropertiesState.isSucceeded) {
            this.featuredPropertiesState.startLoading();
            const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
            if (!filters) filters = [];
            if (purpose && filters.findIndex((filter) => filter.id === "purpose") < 0) {
                filters.push({ id: "purpose", type: TFilterType.ENUM, value: purpose });
            }
            const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
            const url = `${this.apiPath}/listing?limit=${limit}${sortParam}${filtersParam}&lang=${i18next.language}`;
            const promise = fetchUtils
                .get<{ count: number; items: TPropertyListingMdl[] }>(url)
                .then(({ data: { items } }) => {
                    const properties = items.map((item) => {
                        const reformattedItem = this.reformatItem(item);
                        this.putItemInCache(reformattedItem);
                        return reformattedItem;
                    });
                    this.featuredPropertiesState.setSuccess(properties);
                    this.featuredProperties = properties;
                    return properties;
                });
            putPromiseResourceResultInInitialState(
                "propertiesStore.featuredProperties",
                promise.then((properties) => properties),
            );
            return promise;
        }
        return;
    }

    fetchSortedCities() {
        if (!this.sortCitiesState.isLoading && !this.sortCitiesState.isSucceeded) {
            this.sortCitiesState.startLoading();
            const url = `${this.apiPath}/sortedCities`;
            const promise = fetchUtils.get<TCity[]>(url);
            promise
                .then(
                    action(({ data }) => {
                        this.sortCities = data;
                        this.sortCitiesState.setSuccess(data);
                    }),
                )
                .catch((e) => console.error(e));
            putPromiseResultInInitialState("sortedCities", promise);
        }
        return this.sortCitiesState;
    }

    fetchSortedTypes() {
        if (!this.sortTypesState.isLoading && !this.sortTypesState.isSucceeded) {
            this.sortTypesState.startLoading();
            const url = `${this.apiPath}/sortedTypes`;
            const promise = fetchUtils.get<TPropertyTypeCard[]>(url);
            promise
                .then(
                    action(({ data }) => {
                        this.sortTypes = data;
                        this.sortTypesState.setSuccess(data);
                    }),
                )
                .catch((e) => console.error(e));
            putPromiseResultInInitialState("sortedTypes", promise);
        }
        return this.sortTypesState;
    }

    fetchBtns(filters?: TFilter[], force = false, currentPropertyType?: PROPERTY_TYPE) {
        if ((!this.btnsState.isLoading && !this.btnsState.isSucceeded) || force) {
            this.btnsState.startLoading();
            if (!filters) filters = [];
            const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
            const url = `${this.apiPath}/btns${currentPropertyType ? `/${currentPropertyType}` : ""}?${filtersParam}`;
            const promise = fetchUtils.get<TBtns>(url).then(({ data: result }) => {
                this.btns = { BUY: { ...result.BUY } };
                this.btnsState.setSuccess(result);
                return result;
            });
            putPromiseResultInInitialState("propertiesStore.btns", promise);
        }
        return this.btnsState;
    }

    fetchPropertiesForSearch(value?: string, limit = 5) {
        if (!this.propertiesSearchState.isLoading) {
            this.propertiesSearchState.startLoading();
            const filters: TFilter[] = [];
            value ? filters.push({ id: "localized.en.title", type: TFilterType.STRING, value }) : "";
            if (this.purpose && filters.findIndex((filter) => filter.id === "purpose") < 0) {
                filters.push({ id: "purpose", type: TFilterType.STRING, value: this.purpose });
            }
            const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
            const url = `${this.apiPath}/listing?limit=${limit}&lang=${i18next.language}${filtersParam}&searchBar=true`;
            return fetchUtils.get<{ items: TPropertyListingMdl[] }>(url).then(({ data }) => {
                this.propertiesSearch = data.items;
                this.propertiesSearchState.setSuccess(data.items);
            });
        }
    }

    getByAliasUrl(urlAlias: string, lang: string = i18next.language, wantedLanguage = "") {
        if (!wantedLanguage) {
            for (const propertyId of Object.keys(this.cache)) {
                if (this.cache[propertyId]?.localized.urlAlias === urlAlias) {
                    return this.cache[propertyId];
                }
            }
        }
        const url = `${this.apiPath}/urlAlias/${urlAlias}/${lang}/${wantedLanguage}`;
        return fetchUtils
            .get<TPropertyListingMdl>(url)
            .then(({ data }) => data)
            .catch((e) => console.error(e));
    }

    protected reformatItem(item: TPropertyListingMdl | TPropertyDashboardListingMdl) {
        const formattedItem = { ...item };
        const formattedUnits: TUnitListingMdl[] = [];
        if (formattedItem.localized && "en" in formattedItem.localized) {
            formattedItem.localized = formattedItem.localized[i18next.language as TLang];
        }
        if (formattedItem.units) {
            for (const unit of formattedItem.units) {
                const formattedUnit = JSON.parse(JSON.stringify(unit));
                if ("en" in formattedUnit.localized) {
                    formattedUnit.localized = formattedUnit.localized[i18next.language as TLang];
                }
                formattedUnits.push(formattedUnit);
            }
        }
        formattedItem.units = formattedUnits;
        return super.reformatItem(formattedItem);
    }

    setLocationFromSearch(geoZone: TGeoZoneMdl | Pick<TGeoZoneMdl, "address" | "name">) {
        if (geoZone) {
            this.addressParams.city = geoZone.address.city;
            this.addressParams.region = geoZone.address.province;
            this.addressParams.regionLong = geoZone.address?.provinceLong;
            this.addressParams.neighbourhood = geoZone.address.neighbourhood;
            this.addressParams.address = geoZone.name;
        }
    }

    protected onInit(_fromRootCtor?: boolean) {
        super.onInit(_fromRootCtor);
        const initialState = getResourceInitialStateValue(this.purpose + this.name) as
            | TInitialState<TPropertyListingMdl>
            | undefined;
        if (initialState) {
            initialState.items.map((item) => this.putItemInCache(this.reformatItem(item)));

            for (const listId in initialState.list ?? {}) {
                const pages: { [offset: number]: TPropertyListingMdl[] } = {};
                for (const offset in initialState.list[listId].pages) {
                    // if (initialState?.["list"]) pages[Number(offset)] = initialState?.["list"][listId].pages[offset];
                    pages[Number(offset)] = initialState.list[listId].pages[offset].length
                        ? initialState.list[listId].pages[offset]
                        : initialState?.items;
                }
                this.listsStores[listId] = new ListStore(
                    listId,
                    this,
                    {
                        count: initialState.list[listId].count,
                        pages,
                    },
                    undefined,
                    undefined,
                    initialState?.list[listId]?.initialFilters,
                );
            }
        }

        const initialBtnsState = getInitialStateValue<TBtns>("propertiesStore.btns");
        if (initialBtnsState) {
            this.btns = initialBtnsState;
            this.btnsState.setSuccess(initialBtnsState);
        }

        const initialCenterState = getInitialStateValue<{ center: TMapLocation | LatLng; zoom: number }>("location");
        if (initialCenterState) {
            this.searchParams.location = initialCenterState.center;
            this.searchParams.zoom = initialCenterState.zoom;
        }

        const countInitialState = getInitialStateValue<{ count: number }>("properties");
        if (countInitialState) {
            this.count = countInitialState.count;
        }

        const initialGeoZoneState = getResourceInitialStateValue("geoZone") as
            | TInitialState<WithRequiredProperty<TGeoZoneMdl, "_id">>
            | undefined;
        if (initialGeoZoneState) {
            if (initialGeoZoneState.items[0]) {
                this.addressParams = {
                    region: initialGeoZoneState.items[0].address.province,
                    regionLong: initialGeoZoneState.items[0].address.provinceLong,
                    city: initialGeoZoneState.items[0].address.city,
                    address: initialGeoZoneState.items[0].name,
                    neighbourhood: initialGeoZoneState.items[0].address?.neighbourhood,
                };
            }
        }

        const initialFeaturedProperties = getResourceInitialStateValue("propertiesStore.featuredProperties") as
            | TInitialState<TPropertyListingMdl>
            | undefined;
        if (initialFeaturedProperties) {
            this.featuredProperties = initialFeaturedProperties.items;
            this.featuredPropertiesState.setSuccess(initialFeaturedProperties.items);
        }
    }

    addPropertyNavigationHistory(property: TPropertyListingMdl) {
        let propertyHistory = localStorage.getItem(PROPERTIES_NAVIGATION_HISTORY_KEY);
        if (!propertyHistory) propertyHistory = "[]";
        if (!JSON.parse(propertyHistory).find((_property: TPropertyListingMdl) => _property._id === property._id)) {
            localStorage.setItem(
                PROPERTIES_NAVIGATION_HISTORY_KEY,
                JSON.stringify([
                    ...JSON.parse(propertyHistory),
                    { ..._.omit(_.pick(property, ["_id", "urlAlias", "localized"]), "localized.description") },
                ]),
            );
        } else {
            const arrayPropertyHistory: Partial<TPropertyListingMdl>[] = JSON.parse(propertyHistory);
            const index = arrayPropertyHistory.findIndex(
                (propertyInStorage: Partial<TPropertyListingMdl>) => propertyInStorage._id === property._id,
            );
            if (index < 0) return null;
            arrayPropertyHistory.splice(index, 1);
            arrayPropertyHistory.push({
                ..._.omit(_.pick(property, ["_id", "urlAlias", "localized"]), "localized.description"),
            });

            localStorage.setItem(PROPERTIES_NAVIGATION_HISTORY_KEY, JSON.stringify(arrayPropertyHistory));
        }
    }

    static arePurposeOrLocationFilters(filters: TFilter[]) {
        return (
            filters.filter(
                (_filter) =>
                    _filter.id !== FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH.LOCATION &&
                    _filter.id !== FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH.PURPOSE &&
                    _filter.id !== FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH.PUBLISHED &&
                    _filter.id !== FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH.STATUS,
            ).length > 0
        );
    }
}

const propertiesStore = new PropertiesStore();
export { propertiesStore };
