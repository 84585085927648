import "mobx-react-lite/batchingForReactDom";
import React from "react";
import { hydrate } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import IntlProvider from "../shared/_common/i18n/IntlProvider";
import { App } from "App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "shared/_common/theme/theme";
import "_common/_utils/analyticsUtils";

// if (!sharedConfig.isDev) {
/*Sentry.init({
    dsn: sharedConfig.sentry.dsn,
    integrations: [new Sentry.BrowserTracing()],
    // tracePropagationTargets: ["localhost", /^https:\/\/sentry\.lesentrecodeurs\.io\/api/],
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});*/
// }

const Main = () => {
    React.useEffect(() => {
        const jssStyles = document.querySelector("#jss-server-side");
        jssStyles?.parentElement?.removeChild(jssStyles);
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <IntlProvider>
                    <HelmetProvider>
                        <App />
                    </HelmetProvider>
                </IntlProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

hydrate(<Main />, document.getElementById("app"));

if (process.env.NODE_ENV === "development") {
    if ((module as any).hot) {
        (module as any).hot.accept();
    }
}
