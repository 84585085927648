import React from "react";
import styles from "main/footer/_css/footerLinkList.module.css";
import { Link } from "react-router-dom";
import clsx from "clsx";

export type TList = {
    url: string;
    labelKey?: string;
    label: string;
};

type Props = {
    list: TList[];
    titleKeyLabel: string;
    small?: boolean;
};

export const FooterLinkList = React.memo((props: Props) => (
    <div className={styles.container} id={"popularSearches"}>
        <h3 className={styles.title}>{props.titleKeyLabel}</h3>
        <ul
            className={clsx(styles.blockList, {
                [styles.small]: props.small,
            })}
        >
            {props.list.map((link, key) => {
                if (!link.label) return null;
                return (
                    <li key={key} className={clsx(styles.list, "mb_10")}>
                        <Link target={"_blank"} rel="noopener noreferrer" to={link.url} className={styles.link}>
                            {link.label}
                        </Link>
                    </li>
                );
            })}
        </ul>
        <div hidden>
            <a href={"https://lesentrecodeurs.com"} target={"_blank"} rel="noopener noreferrer">
                Powered by Les Entrecodeurs (lesentrecodeurs.com)
            </a>
        </div>
    </div>
));
