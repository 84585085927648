import React, { useState } from "react";
import { UiSelect } from "_common/ui/mui/forms/UiSelect";
import { IconButton, MenuItem } from "@material-ui/core";
import { ListStore } from "_common/list/ListStore";
import { useTranslation } from "react-i18next";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import styles from "./_css/propertiesSort.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import arrowDown from "../assets/images/icons/arrowDown.svg";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export function PropertiesSort(props: Props) {
    const [keySort, setKeySort] = useState({
        key: props.listStore.sort ? Object.keys(props.listStore.sort)[0] : "position",
        sort: props.listStore.sort ? Object.values(props.listStore.sort)[0] : 1,
    });
    const { t } = useTranslation();

    function sort(updatedSort: { key: string; sort: number }) {
        setKeySort(updatedSort);
        props.listStore.setSort({ [updatedSort.key]: updatedSort.sort });
    }

    return (
        <div className="flex_row_center">
            <UiSelect
                className={styles.sort}
                defaultValue={keySort.key}
                title={"Sort"}
                onClick={(e) => sort({ ...keySort, key: e.target.value as string })}
            >
                <MenuItem value={"position"}>{t("propertiesPage.sort.popular")}</MenuItem>
                <MenuItem value={"deliveryDates"}>{t("propertiesPage.sort.date")}</MenuItem>
                <MenuItem value={"price.min"}>{t("propertiesPage.sort.price")}</MenuItem>
            </UiSelect>
            <div className="flex_row_center">
                <IconButton
                    className="ml_10"
                    size={"small"}
                    color={keySort.sort === -1 ? "primary" : "default"}
                    onClick={() => sort({ ...keySort, sort: -1 })}
                >
                    <LazyLoadImage src={arrowDown} className={styles.arrowUp} alt={"Sort down"} />
                </IconButton>
                <IconButton
                    className="ml_5"
                    size={"small"}
                    color={keySort.sort === 1 ? "primary" : "default"}
                    onClick={() => sort({ ...keySort, sort: 1 })}
                >
                    <LazyLoadImage src={arrowDown} alt={"Sort down"} />
                </IconButton>
            </div>
        </div>
    );
}
