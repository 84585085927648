import React, { forwardRef, PropsWithChildren } from "react";
import { ButtonProps } from "@material-ui/core";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import sharedConfig from "_configs/sharedConfig";

type ButtonNotificationProps = ButtonProps & {};

const ButtonNotification = forwardRef<HTMLButtonElement, ButtonNotificationProps>(
    (props: PropsWithChildren<ButtonNotificationProps>, ref) => {
        return (
            <div className="g-recaptcha" data-sitekey={sharedConfig.reCaptcha.siteKey} data-size="invisible">
                <UiButton {...props} ref={ref}>
                    {props.children}
                </UiButton>
            </div>
        );
    },
);
export { ButtonNotification };
