import React from "react";

type Props = {
    color?: string;
    size?: string;
};

export function IconParkingSpot(props: Props) {
    return (
        <svg
            width={props.size ?? "60"}
            height={props.size ?? "60"}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M43.9999 49.0001V38C43.9999 36.3 42.7 35.0001 40.9999 35.0001H40.4999C39.9999 35.0001 39.5999 34.7001 39.5 34.3001L36 22.1002V22.0002C35.1999 20.2002 33.4 19.0002 31.4 19.0002H12.5999C10.5999 19.0002 8.79996 20.2002 7.99993 22.0002V22.1002L4.49994 34.3001C4.2999 34.7001 3.89995 35.0001 3.49999 35.0001H2.99996C1.29994 35.0001 0 36.3 0 38V49.0001C0 50.7001 1.29994 52 2.99996 52V57C2.99996 58.7 4.2999 59.9999 5.99992 59.9999H7.99993C9.69995 59.9999 10.9999 58.7 10.9999 57V52H32.9999V57C32.9999 58.7 34.2998 59.9999 35.9999 59.9999H37.9999C39.6999 59.9999 40.9998 58.7 40.9998 57V52C42.7 52 43.9999 50.7001 43.9999 49.0001ZM8.99999 57C8.99999 57.6 8.60004 57.9999 8.00005 57.9999H6.00004C5.40004 57.9999 5.00009 57.6 5.00009 57V52H9.00011V57H8.99999ZM38.9999 57C38.9999 57.6 38.6 57.9999 38 57.9999H36C35.4 57.9999 35 57.6 35 57V52H39.0001V57H38.9999ZM42 49.0001C42 49.6 41.6001 50 41.0001 50H40.0001H34.0001H9.99994H4.00002H2.99996C2.39997 50 2.00001 49.6 2.00001 49.0001V38C2.00001 37.4001 2.39997 37.0001 2.99996 37.0001H3.49999C4.79993 37.0001 6.00004 36.1001 6.39999 34.8001L9.89998 22.7C10.4 21.7001 11.4 21 12.5999 21H31.4C32.5 21 33.6 21.6 34.0999 22.7L37.5999 34.8001C37.9999 36.1 39.2 37.0001 40.4999 37.0001H40.9999C41.5999 37.0001 41.9999 37.4001 41.9999 38V49.0001H42Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M9.00003 39C6.79998 39 5 40.8 5 43C5 45.2001 6.79998 47 9.00003 47C11.2001 47 13.0001 45.2001 13.0001 43C13.0001 40.8 11.2001 39 9.00003 39ZM9.00003 45C7.9 45 7.00001 44.1 7.00001 43C7.00001 41.9 7.9 41 9.00003 41C10.1001 41 11 41.9 11 43C11 44.1 10.1001 45 9.00003 45Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M35 39C32.8 39 31 40.8 31 43C31 45.2001 32.8 47 35 47C37.2001 47 39.0001 45.2001 39.0001 43C39.0001 40.8 37.2 39 35 39ZM35 45C33.9 45 33 44.1 33 43C33 41.9 33.9 41 35 41C36.1001 41 37 41.9 37 43C37 44.1 36.0999 45 35 45Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M24 44H19.9999C19.4 44 19 44.4 19 44.9999C19 45.5999 19.4 45.9999 19.9999 45.9999H24C24.6 45.9999 24.9999 45.5999 24.9999 44.9999C24.9999 44.4001 24.6 44 24 44Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M26 40H17.9999C17.4 40 17 40.4 17 40.9999C17 41.5999 17.4 41.9999 17.9999 41.9999H26C26.6 41.9999 26.9999 41.5999 26.9999 40.9999C27.0001 40.4 26.6001 40 26 40Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M12.4005 35.0001H31.7004C32.3004 35.0001 32.9004 34.7001 33.3004 34.2C33.7005 33.7 33.8005 33.0001 33.6004 32.4001L31.2004 24.4C31.0003 23.6 30.2003 23 29.3003 23H14.8004C13.9004 23 13.2004 23.5 12.9004 24.4L10.5004 32.4001C10.3004 33.0001 10.4004 33.7 10.8004 34.2C11.2005 34.7 11.8005 35.0001 12.4005 35.0001ZM14.8004 25H29.3004L31.7004 33.0001H12.4005L14.8004 25Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M58.9999 0H41.9999C41.4 0 41 0.399955 41 0.999946V18C41 18.6 41.4 18.9999 41.9999 18.9999H48.9999V49.9999H46.9999C46.3999 49.9999 46 50.3999 46 50.9999V53.9998H44C43.4 53.9998 43 54.3998 43 54.9998V58.9998C43 59.5998 43.4 59.9998 44 59.9998H55.9999C56.5999 59.9998 56.9999 59.5998 56.9999 58.9998V54.9998C56.9999 54.3998 56.5999 53.9998 55.9999 53.9998H53.9999V50.9999C53.9999 50.3999 53.5999 49.9999 53 49.9999H50.9999V18.9999H59C59.6 18.9999 59.9999 18.6 59.9999 18V0.999946C59.9999 0.399955 59.5999 0 58.9999 0ZM54.9998 58H44.9999V56H46.9999H53H55V58H54.9998ZM51.9999 54H47.9999V52H51.9999V54ZM57.9999 17H42.9999V2.00001H57.9999V17Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M49.0001 15.0001C49.6001 15.0001 50 14.6002 50 14.0002V12H51C53.2 12 55 10.2001 55 8.00002C55 5.79997 53.2 4 51 4H48.9999C48.4 4 48 4.39995 48 4.99995V11V13.9999C48 14.6 48.4001 15.0001 49.0001 15.0001ZM53 8.00014C53 9.10017 52.1 10.0002 51 10.0002H50V6.00013H51C52.1 6.00013 53 6.90012 53 8.00014Z"
                fill={props.color ?? "black"}
            />
        </svg>
    );
}
