import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TLocation, TPropertyMdl } from "properties/_models/PropertyMdl";
import { s6 } from "_common/_utils/coreUtils";
import { TLang } from "_configs/sharedConfig";
import { EMPLOYEE_RIGHTS, IAddressMdl } from "users/_models/UserMdl";
import axios from "axios";
import { googleUri } from "_configs/googleConfig";
import { fetchUtils, TFilesData } from "_common/_utils/fetchUtils";
import { createFilesData, storeFile } from "_common/_utils/fileUtils";
import { TImage } from "_common/types/baseTypes";
import { unitsAdminStore } from "admin/units/_stores/unitsAdminStore";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { createDefaultUnit, TUnitListingMdl } from "units/_models/UnitMdl";
import dayjs from "dayjs";

export class PropertiesAdminStore extends BaseResourceStore<TPropertyMdl> {
    constructor() {
        super("properties");
    }

    async create(item: Partial<TPropertyMdl>, files?: TFilesData) {
        if (!item) return;
        if (item.address && !item.location) {
            const location = await this.getLocationFromAddress(item.address);
            item = { ...item, location };
        }
        return super.create(item, files);
    }

    async patch(item: Partial<TPropertyMdl>, files?: TFilesData) {
        if (!item) return;
        /*if (item.address) {
            const location = await this.getLocationFromAddress(item.address);
            item = { ...item, location };
        }*/
        return super.patch(item, files);
    }

    async duplicate(itemId: string, photosItemId?: string) {
        let item = await this.getAsync(itemId);
        const itemForPhotos = photosItemId ? await this.getAsync(photosItemId) : item;
        if (!item || !itemForPhotos) throw { key: "ITEM_NOT_FOUND" };

        for (const lang in item.localized) {
            item.localized[lang as TLang].urlAlias = item.localized[lang as TLang].urlAlias + s6();
            item.localized[lang as TLang].title = item.localized[lang as TLang].title + " - copy";
        }
        const { photos, filesData } = await this.duplicatePhotos(itemForPhotos.photos);

        item = {
            ...item,
            photos,
            floorPlans: [],
            showroom: [],
            previousProjects: [],
            finishings: [],
            premiumPhotos: [],
            previousProjectsVideos: [],
            inventory: [],
            price: { min: item.price.min * 100, max: (item.price?.max ?? 0) * 100 },
            priceInPesos: { min: item.priceInPesos.min * 100, max: (item.priceInPesos?.max ?? 0) * 100 },
            stats: { printed: { count: 0, date: new Date() }, visited: { count: 0, date: new Date() } },
            firstDayOnSite: dayjs(),
        };
        delete item._id;
        const newProperty = await this.duplicateItem(item, filesData);
        if (!newProperty) return undefined;
        const { items: units } = await unitsAdminStore.list(0, 200, undefined, undefined, [
            {
                id: "property",
                type: TFilterType.ID,
                value: photosItemId,
            },
        ]);
        if (units) {
            for (let unit of units) {
                const { photos, filesData } = await this.duplicatePhotos(unit.photos);

                unit = {
                    ...unit,
                    photos,
                    floorPlans: [],
                    price: { min: unit.price.min * 100, max: (unit.price?.max ?? 0) * 100 },
                };
                delete unit._id;
                delete unit.property;
                await unitsAdminStore.create(
                    {
                        ...createDefaultUnit(newProperty._id),
                        ...unit,
                    },
                    filesData,
                );
            }
        }
        return new Promise((resolve) => resolve(newProperty));
    }

    async duplicatePhotos(item: TImage[]) {
        const photos = [];
        const photosForFileData = [];
        for (const photo of item) {
            const blobImg = await fetch(photo.url).then((image) => image.blob());
            const blobUrl = storeFile(blobImg);
            photosForFileData.push(blobUrl);
            photos.push({ url: blobUrl });
        }
        const filesData = await createFilesData(photosForFileData, `photos.*.url`, 1920);
        return { filesData, photos };
    }

    async getLocationFromAddress(address: IAddressMdl | string): Promise<TLocation> {
        const { data } = await axios.get(googleUri.locationFromAddress(address));
        const { results } = data;
        if (results[0]) {
            return {
                type: "Point",
                coordinates: [results[0].geometry.location.lng, results[0].geometry.location.lat],
            } as TLocation;
        }
    }

    updateUnits(propertyId: string, itemPatch: Partial<TUnitListingMdl>) {
        return fetchUtils.patch(this.apiPath + "/updateUnits/" + propertyId, { itemPatch });
    }

    saveRights(rights: {
        [propertyId: string]: {
            [employeeId: string]: EMPLOYEE_RIGHTS | undefined;
        };
    }) {
        return fetchUtils.post(this.apiPath + "/rights", {
            rights,
        });
    }

    updateItemsFromInventory(propertyId: string) {
        return fetchUtils.post(this.apiPath + "/updateItemsFromInventory/" + propertyId);
    }

    protected reformatItem(item: TPropertyMdl) {
        const reformatedItem = super.reformatItem(item);
        return {
            ...reformatedItem,
            price: {
                min: reformatedItem.price.min / 100,
                max: (reformatedItem?.price?.max ?? 0) / 100,
            },
            priceInPesos: {
                min: reformatedItem.priceInPesos?.min / 100,
                max: (reformatedItem?.priceInPesos?.max ?? 0) / 100,
            },
        };
    }
}

const propertiesAdminStore = new PropertiesAdminStore();
export { propertiesAdminStore };
