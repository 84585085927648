import React from "react";
import styles from "./_css/videoComponent.module.css";
import clsx from "clsx";

enum VIDEO_TYPE {
    YOUTUBE = "youtube",
    VIMEO = "vimeo",
}

type Props = {
    url?: string;
    size?: "100%" | 360 | 720;
    className?: string;
};

function parseVideoUrl(url: string) {
    let type: VIDEO_TYPE | undefined;
    let id;

    const ytRegex = /https:\/\/youtu\.be\/[a-zA-Z0-9]*/;
    const vimeoRegex = /https:\/\/vimeo\.com\/[a-zA-Z0-9]*/;
    if (url.match(ytRegex)) {
        type = VIDEO_TYPE.YOUTUBE;
    } else if (url.match(vimeoRegex)) {
        type = VIDEO_TYPE.VIMEO;
    }

    const splittedUrl = url.split("/");
    if (splittedUrl[splittedUrl.length - 1].match(/[a-zA-Z0-9]*/)) {
        id = splittedUrl[splittedUrl.length - 1];
    }

    return { type, id };
}

export function VideoComponent(props: Props) {
    const { type, id } = parseVideoUrl(props.url ?? "https://youtu.be/RB8PKb7DhDo");
    const width = props.size === 360 ? 640 : props.size === 720 ? 1280 : "100%";
    const height = props.size === 360 ? 360 : props.size === 720 ? 720 : "100%";
    return (
        <div className={clsx(styles.container, "flex-1", props.className)}>
            {type === VIDEO_TYPE.YOUTUBE && (
                <iframe
                    src={`https://www.youtube.com/embed/${id}`}
                    width={width}
                    height={height}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            )}
            {type === VIDEO_TYPE.VIMEO && (
                <iframe
                    src={`https://player.vimeo.com/video/${id}`}
                    frameBorder="0"
                    width={width}
                    height={height}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                />
            )}
        </div>
    );
}
