import React from "react";
import { testimonialsStore } from "reviews/_stores/testimonialsStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useTranslation } from "react-i18next";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "home/_css/homeTestimonial.module.css";
import "swiper/swiper-bundle.min.css";
import clsx from "clsx";

SwiperCore.use([Pagination]);

export function HomeTestimonials() {
    testimonialsStore.fetchReviews();
    const { t } = useTranslation();
    return (
        <div id="testimonialImg" className={styles.container}>
            <LoadableFromLoading
                loading={testimonialsStore.reviewsState}
                renderer={(_status, error) => {
                    if (error || testimonialsStore.reviews.length === 0) return null;
                    return (
                        <Swiper
                            navigation
                            loop
                            autoplay={{ delay: 6000, disableOnInteraction: true }}
                            pagination={{
                                clickable: true,
                            }}
                            initialSlide={0}
                        >
                            {testimonialsStore.reviews.map((review) => {
                                if (!review) return;
                                return (
                                    <SwiperSlide key={review._id}>
                                        <div className={clsx("position_relative", styles.swiperContent)}>
                                            <h3 className={styles.title}>{t("homePage.testimonialBlock.title")}</h3>
                                            <div className={clsx(styles.band, "flex_center_center")}>
                                                <div className={clsx(styles.content, "flex_column_center")}>
                                                    <h4 className={styles.testimonialTitle}>
                                                        {t("homePage.testimonialBlock.reviewTitle", {
                                                            city: review.city,
                                                            country: review.country,
                                                        })}
                                                    </h4>
                                                    <div
                                                        className={styles.text}
                                                        dangerouslySetInnerHTML={{
                                                            __html: review.localized.description,
                                                        }}
                                                    />
                                                    <div className={clsx(styles.author, "mt_20")}>
                                                        {review.name}{" "}
                                                        <span className={styles.country}>{review.region}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.placeholder} />
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    );
                }}
            />
        </div>
    );
}
