import React from "react";
import { IPageMdl } from "pages/_models/PageMdl";
import styles from "./_css/articleSideBar.module.css";
import clsx from "clsx";
import rightSideArrow from "../assets/images/icons/rightSideArrow.svg";
import { IconButton } from "@material-ui/core";
import { userStore } from "users/_stores/userStore";
import { AC_TAGS } from "users/_models/UserMdl";
import { ArticlesSimilar } from "blog/ArticlesSimilar";
import { BlogResources } from "pages/blog/BlogRessources";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { useTranslation } from "react-i18next";
import { ArticlesSearchBar } from "blog/ArticlesSearchBar";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    blog: IPageMdl;
    setIsOpen: (open: boolean) => void;
    isOpen: boolean;
    page: IPageMdl;
};

export function ArticleSideBar(props: Props) {
    const { t } = useTranslation();
    return (
        <div
            className={clsx(styles.container, {
                [styles.openContainer]: props.isOpen,
            })}
        >
            <IconButton
                className={clsx(styles.closeIcon, "flex_center_center", {
                    [styles.openIconContainer]: props.isOpen,
                })}
                onClick={() => props.setIsOpen(!props.isOpen)}
            >
                <LazyLoadImage src={rightSideArrow} alt={"Right arrow"} />
            </IconButton>
            <div className={clsx(styles.content, "flex_column_center")}>
                <ArticlesSimilar blogId={props.page._id} blogType={props.blog.blogType} />
                <div className={clsx("mv_20", styles.searchBarContent)}>
                    <div className="mb_10">{t("blogPage.searchTitle")}</div>
                    <ArticlesSearchBar isArticlePage />
                </div>
                <div className={"mb_20"}>
                    <h2 className={"mb_10"}>{t("blogPage.resources.title")}</h2>
                    <BlogResources />
                </div>
                <UiButton
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() =>
                        userStore.openContactModalDisplayed(AC_TAGS.L7_BLOG, {
                            blogType: props.blog.blogType,
                            blogName: props.blog.localized[i18next.language as TLang]?.title,
                        })
                    }
                >
                    {t("blogPage.getInTouch")}
                </UiButton>
            </div>
        </div>
    );
}
