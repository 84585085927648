import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { HomeCardList } from "home/HomeCardList";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";

export function HomeCardCities() {
    propertiesBuyingStore.fetchSortedCities();
    return (
        <LoadableFromLoading
            loading={propertiesBuyingStore.sortCitiesState}
            renderer={(_status, error) => {
                if (error) return null;
                return <HomeCardList type={"cities"} items={propertiesBuyingStore.sortCities} />;
            }}
        />
    );
}
