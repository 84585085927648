import React, { useEffect, useState } from "react";
import { pagesStore } from "pages/_stores/pagesStore";
import { ListStorePaginator } from "admin/_common/list/ListStorePaginator";
import { ListStore } from "_common/list/ListStore";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { NewsCardComponent, NewsCardComponentProps } from "components/news/newsCard/NewsCardComponent";
import { Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { TLang } from "_configs/sharedConfig";
import i18next from "i18next";
import { useParams } from "react-router";
import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";

export enum ITEM_MODEL {
    PAGE = "page",
    ARTICLE = "article",
}

function renderItem<T>(type: ITEM_MODEL, item: T) {
    const lang = i18next.language;
    const props: NewsCardComponentProps = {};
    if (!item) return null;
    switch (type) {
        case ITEM_MODEL.ARTICLE:
            props.title = item.localized[lang as TLang]?.title;
            props.text = item.localized[lang as TLang]?.description;
            props.image = item.localized[lang as TLang]?.image;
            props.url = item.localized[lang as TLang]?.url;
            props.date = item.localized[lang as TLang]?.publishedAt;
            props.blogType = item.blogType;
            return (
                <Grid key={item._id} item xs={12} md={6}>
                    <NewsCardComponent {...props} />
                </Grid>
            );
    }
    return null;
}

type PaginatedItemsComponentProps = {
    listStore: ListStore<any>;
    itemType: ITEM_MODEL;
};

const PaginatedItemsComponent = observer((props: PaginatedItemsComponentProps) => {
    return (
        <Grid container spacing={5}>
            {props.listStore.paginatedItems.map((item) => renderItem(props.itemType, item))}
        </Grid>
    );
});

type ItemsComponentProps = {
    listStore: ListStore<any>;
    itemType: ITEM_MODEL;
};

export function ItemsComponent(props: ItemsComponentProps) {
    const loadingState = props.listStore.currentLoadingState;
    if (!loadingState) return null;
    return (
        <div>
            <LoadableFromLoading
                loading={loadingState}
                renderer={(_status, error) => {
                    if (error) {
                        return <ErrorBlock error={error} />;
                    }
                    return (
                        <>
                            <PaginatedItemsComponent listStore={props.listStore} itemType={props.itemType} />
                            {props.listStore.paginatedItems.length > 3 && (
                                <div className="mt_20">
                                    <ListStorePaginator listStore={props.listStore} />
                                </div>
                            )}
                        </>
                    );
                }}
            />
        </div>
    );
}

type Props = {
    item_model: ITEM_MODEL;
    pageSize: number;
    className?: string;
};

export function ItemsListComponent(props: Props) {
    let listStore;

    const [forceReload, setForceReload] = useState(false);
    const { blogType } = useParams();
    const filters: TFilter[] = [];

    useEffect(() => {
        if (blogType) setForceReload(true);
    }, [blogType]);
    switch (props.item_model) {
        case ITEM_MODEL.PAGE:
            listStore = pagesStore.getListStore("pagesList", props.pageSize);
            break;
        case ITEM_MODEL.ARTICLE:
            if (blogType && blogType !== "") {
                filters.push({
                    id: "blogType",
                    type: TFilterType.ENUM,
                    value: blogType.toUpperCase(),
                });
            }
            listStore = pagesStore.getListStore("articlesList", props.pageSize, filters, undefined, forceReload);
            break;
    }

    return (
        <div className={props.className}>
            <ItemsComponent itemType={props.item_model} listStore={listStore} store={pagesStore} />
        </div>
    );
}
