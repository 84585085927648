import React, { useEffect } from "react";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { Link } from "react-router-dom";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { refreshPage } from "_common/_utils/pageUtils";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { getLabelLinkGeoZone, getUrlLinkGeoZone } from "_common/_utils/geoZoneUtils";
import clsx from "clsx";
import styles from "./_css/propertiesLinks.module.css";
import { useNoSsr } from "_common/_utils/useNoSsr";

type Props = {
    propertyType: PROPERTY_TYPE;
};

export function PropertiesLinks(props: Props) {
    const propertiesStore = usePropertiesStore();
    const { t } = useTranslation();
    const firstRender = useNoSsr();

    useEffect(() => {
        if (!firstRender) propertiesStore.fetchBtns(undefined, true, props.propertyType);
    }, [geoZonesStore.geoZone]);

    if (!geoZonesStore.geoZone) {
        propertiesStore.fetchBtns(undefined, false, props.propertyType);
    } else {
        propertiesStore.fetchBtns(
            [
                {
                    id: "location",
                    value: {
                        geoZoneId: geoZonesStore.geoZone?._id,
                    },
                    type: TFilterType.ZONE,
                },
            ],
            false,
            props.propertyType,
        );
    }
    return (
        <LoadableFromLoading
            loading={propertiesStore.btnsState}
            contentRender={<UiButton className={"mr_5 mt_5"} color={"primary"} variant={"outlined"} />}
            renderer={() => {
                return (
                    <>
                        {Object.entries(propertiesStore.btns).map(([purpose, types]) => {
                            return (
                                <div className={clsx("flex_row flexWrap_wrap pb_10", styles.container)} key={purpose}>
                                    {Object.entries(types)
                                        .filter(([type, _display]) => type !== PROPERTY_TYPE.commercial)
                                        .map(([type, display]) => {
                                            if (!display) return null;
                                            const url = getUrlLinkGeoZone(geoZonesStore.geoZone, type as PROPERTY_TYPE);
                                            const labelBtn = getLabelLinkGeoZone(
                                                propertiesStore.addressParams,
                                                geoZonesStore.geoZone,
                                                type as PROPERTY_TYPE,
                                            );
                                            return (
                                                <Link
                                                    key={purpose + type}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        refreshPage(url);
                                                    }}
                                                    to={url}
                                                    title={`${t("admin.property.purpose." + purpose)} ${t(
                                                        "property.types." + type,
                                                    )}`}
                                                >
                                                    <UiButton
                                                        className={"mr_5 mt_5"}
                                                        color={"primary"}
                                                        variant={"outlined"}
                                                    >
                                                        {labelBtn}
                                                    </UiButton>
                                                </Link>
                                            );
                                        })}
                                </div>
                            );
                        })}
                    </>
                );
            }}
        />
    );
}
