import { GEO_ZONE_TYPE, TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";

export function getUrlLinkGeoZone(geoZone?: TGeoZoneMdl | Pick<TGeoZoneMdl, "type" | "address">, type?: PROPERTY_TYPE) {
    if (!geoZone) {
        return reformatStringForUrls(URLS.buy(i18next.language, type ? i18next.t("property.typesUrl." + type) : ""));
    }
    switch (geoZone.type) {
        case GEO_ZONE_TYPE.NEIGHBORHOOD:
            return reformatStringForUrls(
                URLS.buy(
                    i18next.language,
                    geoZone?.address.province,
                    geoZone?.address.city,
                    geoZone?.address.neighbourhood,
                    type ? i18next.t("property.typesUrl." + type) : "",
                ),
            );
        case GEO_ZONE_TYPE.PROVINCE:
            return reformatStringForUrls(
                URLS.buy(
                    i18next.language,
                    geoZone?.address.provinceLong,
                    type ? i18next.t("property.typesUrl." + type) : "",
                ),
            );
        default:
            return reformatStringForUrls(
                URLS.buy(
                    i18next.language,
                    geoZone?.address.province,
                    geoZone?.address.city,
                    geoZone?.address.neighbourhood
                        ? geoZone?.address.neighbourhood
                        : type
                        ? i18next.t("property.typesUrl." + type)
                        : "",
                    geoZone?.address.neighbourhood ? (type ? i18next.t("property.typesUrl." + type) : "") : undefined,
                ),
            );
    }
}

export function getLabelLinkGeoZone(
    addressParams: {
        region: string | undefined;
        regionLong: string | undefined;
        city: string | undefined;
        address: string | undefined;
        neighbourhood: string | undefined;
    },
    geoZone?: TGeoZoneMdl,
    type?: PROPERTY_TYPE,
) {
    if (!geoZone) {
        if (geoZonesStore.geoZone) {
            return i18next.t("propertiesPage.meta.geoZone.BUY.h1." + type, {
                geoZoneName: addressParams.city,
            });
        } else {
            return i18next.t("propertiesPage.meta.BUY.h1." + type);
        }
    }

    switch (geoZone.type) {
        case GEO_ZONE_TYPE.NEIGHBORHOOD:
            return i18next.t("propertiesPage.meta.geoZone.BUY.h1.neighborhood." + type, {
                neighborhood: addressParams.neighbourhood,
                city: addressParams.city,
            });
        case GEO_ZONE_TYPE.PROVINCE:
            return i18next.t("propertiesPage.meta.geoZone.BUY.h1." + type, {
                geoZoneName: geoZonesStore.geoZone ? addressParams.regionLong : "Mexico",
            });
        default:
            return i18next.t("propertiesPage.meta.geoZone.BUY.h1." + type, {
                geoZoneName: geoZonesStore.geoZone ? addressParams.city : "Mexico",
            });
    }
}
