import React from "react";
import { IPageShortMdl } from "pages/_models/PageMdl";
import styles from "./_css/blogCard.module.css";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import i18next from "i18next";
import { useForceRefreshPage } from "_common/_utils/hookUtils";
import { Link } from "react-router-dom";

type Props = {
    blog: IPageShortMdl;
};

export function BlogCard(props: Props) {
    const { t } = useTranslation();
    const { refreshPage } = useForceRefreshPage();

    return (
        <div className={clsx(styles.card, "p_25")}>
            <Link
                onClick={(e) => {
                    e.preventDefault();
                    refreshPage(`/${i18next.language}${props.blog.localized.url}`);
                }}
                to={`/${i18next.language}${props.blog.localized.url}`}
            >
                <LazyLoadImage
                    className={styles.image}
                    src={props.blog.localized.image}
                    alt={props.blog.localized.image}
                />
                <h3 className={clsx(styles.title, "mb_10")}>{props.blog.localized.title}</h3>
                <div className={styles.text}>{props.blog.localized.description}</div>
                <div className={clsx(styles.date, "mv_10")}>
                    {dayjs(props.blog.localized.publishedAt).format("MMMM D, YYYY")}
                </div>
                <div className={clsx(styles.cta, "flex_center_center")}>{t("homePage.blog.read")}</div>
            </Link>
        </div>
    );
}
