import React from "react";
import styles from "./_css/unitLeftLabel.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { NoSsr } from "@material-ui/core";
import { INVENTORY_STATUS } from "units/_models/UnitMdl";

type Props = {
    numberOfUnitLeft: number;
};

export function UnitLeftLabel(props: Props) {
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();
    const isLandOrCommercial =
        propertyStore.property.type === PROPERTY_TYPE.land || propertyStore.property.type === PROPERTY_TYPE.commercial;

    if (!propertyStore.property.units) return null;

    let status: INVENTORY_STATUS | undefined = undefined;
    const atListOneAvailable = propertyStore.property?.units.some(
        (unit) => unit?.status === INVENTORY_STATUS.AVAILABLE,
    );
    const atListOneReserved = propertyStore.property?.units.some((unit) => unit?.status === INVENTORY_STATUS.RESERVED);
    if (atListOneAvailable) {
        status = INVENTORY_STATUS.AVAILABLE;
    } else if (atListOneReserved) {
        status = INVENTORY_STATUS.RESERVED;
    } else {
        status = INVENTORY_STATUS.SOLD;
    }

    return (
        <NoSsr>
            <div className={clsx(styles.container, "p_20")}>
                {status !== INVENTORY_STATUS.AVAILABLE
                    ? t("propertyPage.inventory.status." + status).toUpperCase()
                    : props.numberOfUnitLeft > 0
                    ? t(isLandOrCommercial ? "propertyPage.lot" : "unit.unitLeft", { count: props.numberOfUnitLeft })
                    : t(isLandOrCommercial ? "propertyPage.lotZero" : "unit.unitLeftZero", {
                          count: props.numberOfUnitLeft,
                      })}
            </div>
        </NoSsr>
    );
}
