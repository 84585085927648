import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { TAskForVisitOrInfoForm } from "properties/propertyPage/PropertyGalleryCta";
import { TContactForm } from "components/misc/contactUs/ContactFormComponent";
import { Recaptcha } from "components/misc/notifications/recaptcha";

class NotifierStore extends Recaptcha {
    constructor(private apiPath = `${sharedConfig.apiUrl}/notifier`) {
        super();
    }

    async askForInfo(data: TAskForVisitOrInfoForm) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/askForInfo", { ...data, recaptcha });
    }

    async askForAgentAppointment(data: TContactForm) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/askForAgentAppointment", { ...data, recaptcha });
    }

    async contact(data: TContactForm) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/contact", { ...data, recaptcha });
    }
}

const notifierStore = new NotifierStore();
export { notifierStore };
