import { useEffect, useState } from "react";
import _ from "lodash";

export function useWindowSize(throttle?: number) {
    const [windowSize, setWindowSize] = useState({ width: -1, height: -1 });
    useEffect(() => {
        if (__BROWSER__) {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            const listener = _.throttle(
                () =>
                    setWindowSize({
                        width: window.innerWidth,
                        height: window.innerHeight,
                    }),
                throttle ?? 100,
            );
            window.addEventListener("resize", listener);
            return () => window.removeEventListener("resize", listener);
        }
    }, []);

    const xs = 0 < windowSize.width && 600 >= windowSize.width;
    const sm = 600 < windowSize.width && 960 >= windowSize.width;
    const md = 960 < windowSize.width && 1280 >= windowSize.width;
    const lg = 1280 < windowSize.width && 1920 >= windowSize.width;
    const xl = 1920 < windowSize.width;
    const isMobile = windowSize.width < 680;
    return { windowSize, xs, sm, md, lg, xl, isMobile };
}
