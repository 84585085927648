import React, { lazy } from "react";
import Header from "main/header/Header";
import { Footer } from "main/footer/Footer";
import styles from "_css/appContent.module.css";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { isBuyOrRentPage } from "_common/_utils/pageUtils";
import Suspenser from "_common/loaders/Suspenser";

export const PAGE_REF: { current: HTMLDivElement | null } = { current: null };

const LazyPageRegularObservers = lazy(() => import("pages/PageRegularObservers"));

export function PageRegular(props: any) {
    const { pathname } = useLocation();
    const isRentOrBuyPage = isBuyOrRentPage(pathname);
    return (
        <div ref={(ref) => (PAGE_REF.current = ref)} className={styles.container}>
            <Suspenser noText noSpinner>
                <LazyPageRegularObservers />
            </Suspenser>
            <div className={clsx("flex_column", styles.innerContainer)}>
                <div className={clsx(styles.bg, "position_absolute")} />
                <Header isMapPage={isRentOrBuyPage} />
                <div className={clsx("flex-1 flex_column")}>{props.children}</div>
                <div hidden={isRentOrBuyPage} className={styles.footerContainer}>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
