import React, { lazy } from "react";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import styles from "./_css/propertyCard.module.css";
import "./_css/propertyCard.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { URLS } from "_configs/URLS";
import { ToggleToFavoriteBtn } from "properties/ToggleToFavoriteBtn";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { formatPesosPrice, formatPrice } from "_common/_utils/currencyUtils";
import { CompareBtn } from "shared/compare/CompareBtn";
import bed from "../assets/images/icons/bed.svg";
import square from "../assets/images/icons/square.svg";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { useForceRefreshPage } from "_common/_utils/hookUtils";
import { isInPesosProperty, isLandOrCommercial } from "_common/_utils/propertyUtils";
import { NoSsr } from "@material-ui/core";
import { INVENTORY_STATUS } from "units/_models/UnitMdl";
import { Label } from "properties/label/Label";
import { Helmet } from "react-helmet-async";
import Suspenser from "_common/loaders/Suspenser";
import discount from "../assets/images/icons/discount.svg";
import { LabelType } from "properties/label/LabelType";
import { reformatUrlForOriginalName } from "_common/_utils/alphaNumUtils";

type Props = {
    property: TPropertyListingMdl;
    isMapCard?: boolean;
    isFeaturedCard?: boolean;
    isPrimaryCard?: boolean;
    isPropertiesMap?: boolean;
    isSimilarCard?: boolean;
    bgColor?: boolean;
    odd?: boolean;
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
    newTab?: boolean;
};

const LazyPropertyGalleryCard = lazy(() => import("properties/PropertyGalleryCard"));

function _PropertyCard(props: Props) {
    const { t } = useTranslation();

    const { refreshPage } = useForceRefreshPage();

    const sortAddress = [
        props.property.address.neighbourhood,
        props.property.address.city,
        reformatUrlForOriginalName(props.property.address.province),
        t("propertyPage.country." + (props.property.address.country ?? "MEX")),
    ];
    const completeAddress = sortAddress
        .filter((addressPart) => addressPart !== undefined && addressPart !== "")
        .slice(0)
        .join(", ");

    return (
        <div
            id="propertyCard"
            className={clsx(styles.container, "flex_column p_10", {
                [styles.containerMap]: props.isMapCard,
                [styles.bgColor]: props.odd,
                [styles.containerPropertiesPage]: props.isPropertiesMap,
                [styles.containerSimilarCard]: props.isSimilarCard,
                ["m_0"]: props.isFeaturedCard,
            })}
            onMouseOver={props.onMouseOver}
            onMouseLeave={props.onMouseLeave}
        >
            <Helmet>
                <link rel="preload" as="image" href={props.property.photos?.[0]?.thumbnail} />
            </Helmet>
            <h3 hidden>{props.property.localized.title}</h3>
            <div className={clsx("position_relative", { [styles.imageContainer]: !props.isMapCard })}>
                <LabelType text={props.property.type} />
                {!props.isMapCard && (
                    <NoSsr>
                        <CompareBtn
                            type={RESOURCE.PROPERTIES}
                            id={props.property._id}
                            isForCard
                            color={"white"}
                            size={"24"}
                        />
                        <ToggleToFavoriteBtn
                            item={props.property}
                            type={RESOURCE.PROPERTIES}
                            isForCard
                            color={"white"}
                            size={"24"}
                        />
                    </NoSsr>
                )}
                {props.property.status !== INVENTORY_STATUS.AVAILABLE && (
                    <div className={styles.label}>
                        <Label
                            label={t("unit.soldOut")}
                            className={clsx(styles.tag, "flex_center_center")}
                            bgColor={"black"}
                        />
                    </div>
                )}
                <Suspenser
                    fallback={
                        <img
                            alt={`${props.property.localized.title}-1`}
                            title={`${props.property.localized.title} photo 1`}
                            className={clsx(styles.image, {
                                [styles.imagePrimaryCard]: props.isPrimaryCard,
                                [styles.imageSimilarCard]: props.isSimilarCard,
                                [styles.imageMapCard]: props.isMapCard,
                            })}
                            src={props.property.photos?.[0]?.thumbnail}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "https://via.placeholder.com/250?Text=Zisla.com";
                            }}
                        />
                    }
                >
                    <LazyPropertyGalleryCard {...props} />
                </Suspenser>
            </div>
            <ExternalOrNavLink
                aria-label={props.property.localized.title}
                aria-labelledby={props.property.localized.title}
                className={clsx({ ["pb_20"]: !props.isMapCard }, styles.cardLink)}
                onClick={() => {
                    if (props.isSimilarCard) {
                        refreshPage(URLS.property(props.property.localized.urlAlias));
                    }
                    if (props.newTab) {
                        const win = window.open(URLS.property(props.property.localized.urlAlias), "_blank");
                        if (win) win.focus();
                    }
                }}
                url={URLS.property(props.property.localized.urlAlias)}
            >
                <div className={clsx(styles.header, "ph_5")}>
                    <h4 className={clsx("mb_0", styles.title)}>{props.property.localized.title}</h4>
                    {!props.isMapCard && (
                        <div className={"flex_row_center"}>
                            {props.property.priceIsNotDisplayed ? (
                                <div className={clsx(styles.price, "fontWeight_bold mb_5")}>
                                    {t("propertiesPage.card.priceOnRequest")}
                                </div>
                            ) : (
                                <div className={clsx(styles.price, "flex_column fontWeight_bold mt_3 mb_5")}>
                                    <div>
                                        {t("propertiesPage.card.from")}
                                        <span className={"text_noWrap"}>
                                            {formatPrice(
                                                props.property.price?.min,
                                                false,
                                                isInPesosProperty(props.property),
                                            )}
                                        </span>
                                    </div>
                                    {isInPesosProperty(props.property) && (
                                        <div>({formatPesosPrice(props.property.priceInPesos?.min)})</div>
                                    )}
                                </div>
                            )}
                            {props.property?.discount && (
                                <div className={"ml_5"}>
                                    <img width={30} height={30} alt={"Discount icon"} src={discount} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={clsx(styles.address, "flex_row_center mv_10 ph_5")}>
                    <div className="flex-1 flexWrap_wrap" dangerouslySetInnerHTML={{ __html: completeAddress }} />
                </div>
                {!props.isMapCard && (
                    <div>
                        {!isLandOrCommercial(props.property) && (
                            <div className={clsx(styles.rooms, "mt_10 ph_5")}>
                                <div className={"flex_column"}>
                                    <div className="flex_row_center">
                                        <div className="mr_10">
                                            <img src={bed} alt={"bed icon"} />
                                        </div>
                                        {props.property.bedrooms.max &&
                                        props.property.bedrooms.max != props.property.bedrooms.min ? (
                                            props.property.bedrooms.min === 0 ? (
                                                <div>
                                                    {t("propertyPage.header.rangeOfRoomsWithStudio", {
                                                        count: props.property.bedrooms.max,
                                                    })}
                                                </div>
                                            ) : (
                                                <div>
                                                    {t("propertyPage.header.rangeOfRooms", {
                                                        nbOfRoomsMin: props.property.bedrooms.min,
                                                        count: props.property.bedrooms.max,
                                                    })}
                                                </div>
                                            )
                                        ) : props.property.bedrooms.min === 0 ? (
                                            <div>{t("propertyPage.header.studio")}</div>
                                        ) : (
                                            <div>
                                                {t("propertyPage.header.nbOfRooms", {
                                                    count: props.property.bedrooms.min,
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {props.property.squareSurface &&
                            !!props.property.squareSurface?.min &&
                            props.property.squareSurface?.min !== 0 && (
                                <div className={clsx("flex_row_center mt_10 ph_5", styles.surface)}>
                                    <img src={square} alt={"square icon"} className={"mr_10"} />
                                    <div className="flex-1 flexWrap_wrap">
                                        {t("propertiesPage.card.surfaces", {
                                            ft2: convert(props.property.squareSurface?.min, UNIT_CONVERSION.M2_TO_FT2),
                                            m2: props.property.squareSurface?.min,
                                        })}
                                    </div>
                                </div>
                            )}
                    </div>
                )}
            </ExternalOrNavLink>
        </div>
    );
}

export function PropertyCard(props: Props) {
    if (!props.property) return null;
    return props.isFeaturedCard ? (
        <div className={"p_10"}>
            <_PropertyCard {...props} />
        </div>
    ) : (
        <_PropertyCard {...props} />
    );
}
