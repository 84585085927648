import dayjs from "dayjs";
import { TMeta, TRootComponent } from "pages/_models/PageMdl";
import { INVENTORY_STATUS, TStatusByPhase, TUnitListingMdl, TUnitMdl } from "units/_models/UnitMdl";
import { EMPLOYEE_RIGHTS, TUserSummaryMdl } from "users/_models/UserMdl";
import { TImage } from "_common/types/baseTypes";
import { TPropertyConstructProgress } from "properties/_models/PropertyConstructProgressMdl";

export enum FILTER_EXCLUDED_FOR_FILTERS_BTN_SAVED_SEARCH {
    LOCATION = "location",
    PURPOSE = "purpose",
    PUBLISHED = "published",
    STATUS = "status",
}

export const PARTICULARITY = [
    "beachfront",
    "closingInPesos",
    "closingInMultipleCurrencies",
    "rentalProgram",
    "financingAvailable",
    "mortgageCredit",
    "bitcoinAccepted",
    "longTermFinancing",
];

export enum CURRENCY {
    MXN = "MXN",
    US = "US",
}

export enum PROPERTY_PURPOSE {
    BUY = "BUY",
}

export enum PROPERTY_TYPE {
    condo = "condo",
    house = "house",
    villa = "villa",
    land = "land",
    commercial = "commercial",
    hotel = "hotel",
}

export const HIDDEN_PROPERTY_TYPE: string[] = [PROPERTY_TYPE.hotel, PROPERTY_TYPE.commercial];

export enum FEES {
    dollarPerSquareMeter = "dollarPerSquareMeter",
    dollarsPerMonth = "dollarsPerMonth",
    dollarsPerSquareFoot = "dollarsPerSquareFoot",
    pesosPerMonth = "pesosPerMonth",
    pesosPerSquareMeter = "pesosPerSquareMeter",
    percentPerMonth = "percentPerMonth",
    percentPerYear = "percentPerYear",
    toBeDetermined = "toBeDetermined",
    seeUnit = "seeUnit",
    noFees = "noFees",
}

export enum PROPERTY_SUBTYPE {
    residential = "residential",
    commercial = "commercial",
    mix = "mix",
    townHouse = "townHouse",
    singleHouse = "singleHouse",
    buildableHouse = "buildableHouse",
}

export enum PROPERTY_STATUS {
    NONE = "NONE",
    READY_TO_MOVE_IN = "READY_TO_MOVE_IN",
    UNDER_CONSTRUCTION = "UNDER_CONSTRUCTION",
    AVAILABLE_X_MONTHS_AFTER = "AVAILABLE_X_MONTHS_AFTER",
}

export enum PREMIUM {
    NONE,
    PROMO,
}

export enum PARKING_TYPE {
    COVERED = "COVERED",
    GARAGE = "GARAGE",
    OUTDOOR = "OUTDOOR",
    STREET = "STREET",
    UNDERGROUND = "UNDERGROUND",
}

export enum SEASONS {
    SPRING = "SPRING",
    SUMMER = "SUMMER",
    AUTUMN = "AUTUMN",
    WINTER = "WINTER",
}

export interface TPremiumPeriod {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
}

export interface TRangeNumber {
    min: number;
    max?: number;
}

export interface TInstalment {
    start: string;
    year: string;
    delivery: string;
    discount: string;
}

export interface TLocation {
    type: "Point";
    coordinates: number[];
}

export interface TAddressMdl {
    street?: string;
    extra?: string;
    city: string;
    postalCode?: string;
    neighbourhood: string;
    country?: string;
    province: string;
    provinceLong: string;
    provinceShort?: string;
}

export interface TFees {
    type: FEES;
    value: number;
}

export interface TFinancing {
    signin: number;
    building: number;
    delivery: number;
    deeding: number;
    discount: number;
}

export interface TAmenity {
    [key: string]: string | number | boolean | undefined;
}

export interface TFeature {
    [key: string]: string | number | boolean | undefined;
}

export interface TPropertyLocalized {
    title: string;
    description: string;
    urlAlias: string;
    discountText: string;
    meta: TMeta;
    component: TRootComponent;
}

export interface TFloor {
    name?: string;
    units?: TUnitOfInventory[];
    buildingName?: string;
}

export interface TBuilding {
    name: string;
    floors: TFloor[];
}

export interface TPhase {
    numberPhase: number;
    status: PROPERTY_STATUS;
    months_number?: number;
    deliveryDate?: dayjs.Dayjs;
}

export interface TInventory {
    phase: TPhase;
    buildings: TBuilding[];
}

export interface TUnitOfInventory {
    unitId: string;
    squareSurface: number;
    price: number;
    status?: INVENTORY_STATUS;
    number?: string;
    phaseIndex?: number;
}

export type TPropertyBaseMdl = {
    _id: string;
    published: boolean;
    purpose: PROPERTY_PURPOSE;
    type: PROPERTY_TYPE;
    subType?: PROPERTY_SUBTYPE;

    price: TRangeNumber;
    priceIsNotDisplayed: boolean;
    priceInPesos: TRangeNumber;
    isFinancing: boolean;
    financing: TFinancing[];
    fees: TFees;
    sold: boolean;
    status: INVENTORY_STATUS;
    statusByPhase?: TStatusByPhase[];
    premiumPlan: PREMIUM;
    premiumPeriod?: TPremiumPeriod;
    discount: boolean;
    address: TAddressMdl;
    location: TLocation;
    visit3D: string;
    video: string;
    video2: string;

    amenities: TAmenity;
    features: TFeature;

    photos: TImage[];
    premiumPhotos: TImage[];
    floorPlans: TImage[];
    constructProgress: TPropertyConstructProgress[];
    finishings: TImage[];
    previousProjects: TImage[];
    previousProjectsVideos: TImage[];
    showroom: TImage[];
    nbOfLevels: number;
    nbOfUnits: number;
    inventory: TInventory[];
    bedrooms: TRangeNumber;
    bathrooms: TRangeNumber;
    basementRooms: number;
    carParks: number;
    lockers: number;
    buildingHeight: number;
    squareSurface: TRangeNumber;
    coOwner: boolean;
    coDevelopers: { [employeeId: string]: EMPLOYEE_RIGHTS };
    isUpdateFromConversion: boolean;

    instalments: TInstalment[];

    position: number;
    waterFront: boolean;
    hideInventory: boolean;
    contactPhone: string;
    contactMail: string;
    contactName: string;
    propertyLink: string;

    firstDayOnSite: dayjs.Dayjs;
    lastUpdate: dayjs.Dayjs;
    createdAt: dayjs.Dayjs;
    updatedAt: dayjs.Dayjs;
};

export type TPropertyMdl = TPropertyBaseMdl & {
    localized: {
        fr: TPropertyLocalized;
        es: TPropertyLocalized;
        en: TPropertyLocalized;
        zh: TPropertyLocalized;
    };
    units: TUnitMdl[];
    developer: string;
    stats: {
        printed: {
            count: number;
            date: Date;
        };
        visited: {
            count: number;
            date: Date;
        };
    };
};

export type TPropertyListingMdl = TPropertyBaseMdl & {
    localized: TPropertyLocalized;
    urlAlias: {
        [lang: string]: string;
    };
    developer: TUserSummaryMdl;
    units: TUnitListingMdl[];
};

export type TPropertyDashboardListingMdl = TPropertyListingMdl & {
    stats: {
        printed: {
            count: number;
            date: Date;
        };
        visited: {
            count: number;
            date: Date;
        };
        favorited: {
            count: number;
        };
    };
};

export function createDefaultProperty() {
    return {
        purpose: PROPERTY_PURPOSE.BUY,
        type: PROPERTY_TYPE.house,
        localized: {
            fr: {
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
            },
            en: {
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
            },
            es: {
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
            },
            zh: {
                title: "",
                description: "",
                urlAlias: "",
                meta: {
                    title: "",
                    description: "",
                },
                component: { id: "div", props: { style: {}, children: [] } },
            },
        },
        amenities: [],
        developer: "",
        coDevelopers: [],
        discount: false,
        discountText: "",
        features: [],
        floorPlans: [],
        instalments: [],
        location: {
            locationType: "Point",
            coordinates: [0, 0],
        },
        fees: {
            value: 0,
            type: FEES.toBeDetermined,
        },
        photos: [],
        showroom: [],
        finishings: [],
        position: 0,
        premiumPlan: PREMIUM.NONE,
        premiumPeriod: { startDate: dayjs().startOf("day"), endDate: dayjs().endOf("day").add(1, "year") },
        lastUpdate: new Date(),
        nbOfLevels: 0,
        nbOfUnits: 0,
        price: { min: 0, max: 0 },
        priceInPesos: { min: 0, max: 0 },
        isFinancing: false,
        financing: [
            {
                signin: 0,
                building: 0,
                delivery: 0,
                deeding: 0,
                discount: 0,
            },
        ],
        priceIsNotDisplayed: false,
        bedrooms: { min: 0, max: 0 },
        bathrooms: { min: 0, max: 0 },
        squareSurface: { min: 0, max: 0 },
        published: false,
        sold: false,
        status: INVENTORY_STATUS.AVAILABLE,
        units: [],
        inventory: [{ phase: { status: PROPERTY_STATUS.READY_TO_MOVE_IN }, buildings: [] }],
        visit3D: "",
        video: "",
        video2: "",
        previousProjectsVideos: [],
        contactPhone: "",
        contactMail: "",
        propertyLink: "",
        contactName: "",
        coOwner: false,
        waterFront: false,
        isUpdateFromConversion: false,
        address: {
            street: "",
            extra: "",
            city: "",
            postalCode: "",
            neighbourhood: "",
            country: "MEX",
            province: "QUINTANA_ROO",
        },
        stats: {
            printed: {
                count: 0,
                date: new Date(),
            },
            visited: {
                count: 0,
                date: new Date(),
            },
        },
    };
}
