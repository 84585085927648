export const ADMIN_PATH = "/zisla-admin";

export const ADMIN_URLS = {
    home: () => ADMIN_PATH,

    users: {
        base: () => `${ADMIN_PATH}/users`,
        new: () => `${ADMIN_PATH}/users/edit`,
        edit: (id: string) => `${ADMIN_PATH}/users/edit/${id}`,
    },

    pages: {
        base: () => `${ADMIN_PATH}/pages`,
        new: () => `${ADMIN_PATH}/pages/edit`,
        edit: (id: string) => `${ADMIN_PATH}/pages/edit/${id}`,
    },

    properties: {
        base: () => `${ADMIN_PATH}/properties`,
        new: () => `${ADMIN_PATH}/properties/edit`,
        edit: (id: string) => `${ADMIN_PATH}/properties/edit/${id}`,
    },

    units: {
        airdna: (id: string) => `${ADMIN_PATH}/units/airdna/${id}`,
        base: () => `${ADMIN_PATH}/units`,
        new: () => `${ADMIN_PATH}/units/edit`,
        edit: (id: string) => `${ADMIN_PATH}/units/edit/${id}`,
    },

    developerNotifications: {
        base: () => `${ADMIN_PATH}/developerNotifications`,
        edit: (id: string) => `${ADMIN_PATH}/developerNotifications/edit/${id}`,
    },

    geoZones: {
        base: () => `${ADMIN_PATH}/geoZones`,
        new: () => `${ADMIN_PATH}/geoZones/edit`,
        edit: (id: string) => `${ADMIN_PATH}/geoZones/edit/${id}`,
    },

    reviews: {
        base: () => `${ADMIN_PATH}/reviews`,
        new: () => `${ADMIN_PATH}/reviews/edit`,
        edit: (id: string) => `${ADMIN_PATH}/reviews/edit/${id}`,
    },

    settings: {
        base: () => `${ADMIN_PATH}/settings`,
    },

    toolBox: {
        base: () => `${ADMIN_PATH}/toolBox`,
    },

    menu: {
        base: () => `${ADMIN_PATH}/menu`,
    },
};
