import { getI18nExpByLang } from "_common/_utils/pageUtils";

export const URLS_ALTERNATES = {
    unit: (propertyAlias: string, unitAlias: string, lang: string) => {
        switch (lang) {
            case "en":
                return `/en/properties/${propertyAlias}/units/${unitAlias}`;
            case "fr":
                return `/fr/proprietes/${propertyAlias}/unites/${unitAlias}`;
            case "es":
                return `/es/propiedades/${propertyAlias}/unidades/${unitAlias}`;
            case "zh":
                return `/zh/properties/${propertyAlias}/units/${unitAlias}`;
            default:
                return `/en/properties/${propertyAlias}/units/${unitAlias}`;
        }
    },

    property: (propertyAlias: string, lang: string) => {
        switch (lang) {
            case "en":
                return `/en/properties/${propertyAlias}`;
            case "fr":
                return `/fr/proprietes/${propertyAlias}`;
            case "es":
                return `/es/propiedades/${propertyAlias}`;
            case "zh":
                return `/zh/properties/${propertyAlias}`;
            default:
                return `/en/properties/${propertyAlias}`;
        }
    },

    blog: (lang: string, blogType?: string) => {
        let url = `/${lang}/${getI18nExpByLang(lang, "routes.blog")}`;
        if (blogType) {
            url += `/${blogType}`;
        }
        return url;
    },

    about: (lang: string) => `/${lang}/${getI18nExpByLang(lang, "routes.about")}`,
};
