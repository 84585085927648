import React from "react";

type Props = {
    color?: string;
    size?: string;
};

export function IconType(props: Props) {
    return (
        <svg
            width={props.size ?? "64"}
            height={props.size ?? "64"}
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_941_185)">
                <path
                    d="M58.6667 42.6665C58.0768 42.6665 57.6001 43.1444 57.6001 43.7331V61.8663H50.1335C49.5436 61.8663 49.0669 62.3442 49.0669 62.933C49.0669 63.5217 49.5436 63.9996 50.1335 63.9996H58.6668C59.2567 63.9996 59.7334 63.5217 59.7334 62.933V43.7331C59.7333 43.1444 59.2564 42.6665 58.6667 42.6665Z"
                    fill={props.color ?? "black"}
                />
                <path
                    d="M55.4663 52.2664V43.7331C55.4663 43.1444 54.9896 42.6665 54.3997 42.6665H47.9997C47.4099 42.6665 46.9331 43.1444 46.9331 43.7331V52.2664C46.9331 52.8552 47.4099 53.333 47.9997 53.333H54.3997C54.9896 53.333 55.4663 52.8552 55.4663 52.2664ZM53.3331 51.1997H49.0665V44.7997H53.3331V51.1997Z"
                    fill={props.color ?? "black"}
                />
                <path
                    d="M41.6 18.1336C42.1899 18.1336 42.6667 17.6557 42.6667 17.0669V11.7336C42.6667 11.1449 42.1899 10.667 41.6 10.667H9.60031C9.01044 10.667 8.53369 11.1449 8.53369 11.7336V49.0667C8.53369 49.6555 9.01044 50.1333 9.60031 50.1333C10.1902 50.1333 10.6669 49.6555 10.6669 49.0667V46.9334H11.7335C12.3234 46.9334 12.8002 46.4555 12.8002 45.8667C12.8002 45.278 12.3234 44.8001 11.7335 44.8001H10.6669V38.4002H14.9335V42.6668C14.9335 43.2555 15.4103 43.7334 16.0001 43.7334C16.59 43.7334 17.0667 43.2555 17.0667 42.6668V38.4002H21.3333C21.9232 38.4002 22.4 37.9223 22.4 37.3335C22.9898 37.3335 23.4666 36.8557 23.4666 36.2669V29.867H27.7332C27.7332 30.4557 28.2099 30.9336 28.7998 30.9336C29.3896 30.9336 29.8664 30.4557 29.8664 29.867C30.4563 29.867 30.933 29.3891 30.933 28.8004C30.933 28.2116 30.4563 27.7337 29.8664 27.7337V21.3338H34.133V23.4672C34.133 24.0559 34.6097 24.5338 35.1996 24.5338C35.7895 24.5338 36.2662 24.0559 36.2662 23.4672V21.3335H38.3996C38.9894 21.3335 39.4662 20.8557 39.4662 20.2669C39.4662 19.6782 38.9894 19.2003 38.3996 19.2003H36.2662V12.8004H40.5328V17.0669C40.5334 17.6557 41.0102 18.1336 41.6 18.1336ZM14.9336 36.2668H10.6669V29.8669H14.9335V36.2668H14.9336ZM14.9336 27.7335H10.6669V21.3335H14.9335V27.7335H14.9336ZM14.9336 19.2002H10.6669V12.8002H14.9335V19.2002H14.9336ZM21.3336 36.2668H17.067V29.8669H21.3336V36.2668ZM21.3336 27.7335H17.067V21.3335H21.3336V27.7335ZM21.3336 19.2002H17.067V12.8002H21.3336V19.2002ZM27.7335 27.7335H23.4669V21.3335H27.7335V27.7335ZM27.7335 19.2002H23.4669V12.8002H27.7335V19.2002ZM34.1335 19.2002H29.8669V12.8002H34.1335V19.2002Z"
                    fill={props.color ?? "black"}
                />
                <path
                    d="M5.33371 54.3999C5.92358 54.3999 6.40032 53.9221 6.40032 53.3333V8.53367H13.8669C14.4568 8.53367 14.9335 8.0558 14.9335 7.46705V4.26708H22.4001C22.9899 4.26708 23.4667 3.78921 23.4667 3.20046V2.13372H27.7333V3.20034C27.7333 3.78908 28.21 4.26695 28.7999 4.26695H36.2664V6.40031H18.1336C17.5437 6.40031 17.067 6.87818 17.067 7.46693C17.067 8.05567 17.5437 8.53355 18.1336 8.53355H44.8V12.8001C44.8 13.3889 45.2767 13.8668 45.8666 13.8668C46.4565 13.8668 46.9332 13.3889 46.9332 12.8001V7.46705C46.9332 6.87831 46.4565 6.40044 45.8666 6.40044H38.4001V3.20046C38.4001 2.61172 37.9233 2.13385 37.3334 2.13385H29.8669V1.0671C29.8669 0.47836 29.3901 0.000488281 28.8003 0.000488281H22.4003C21.8104 0.000488281 21.3337 0.47836 21.3337 1.0671V2.13372H13.8671C13.2773 2.13372 12.8005 2.61159 12.8005 3.20034V6.40031H5.33371C4.74384 6.40031 4.26709 6.87818 4.26709 7.46693V53.3333C4.26696 53.9221 4.74384 54.3999 5.33371 54.3999Z"
                    fill={props.color ?? "black"}
                />
                <path
                    d="M62.933 23.4667H55.4664V22.4001C55.4664 21.8114 54.9897 21.3335 54.3998 21.3335H50.1332C49.5433 21.3335 49.0666 21.8114 49.0666 22.4001V24.5335C49.0666 25.1222 49.5433 25.6001 50.1332 25.6001C50.7231 25.6001 51.1998 25.1222 51.1998 24.5335V23.4669H53.3332V24.5335C53.3332 25.1222 53.8099 25.6001 54.3998 25.6001H61.8664V40.0917L45.554 23.7794C45.1369 23.3624 44.4627 23.3624 44.0458 23.7794C43.6286 24.1965 43.6286 24.8706 44.0458 25.2876L62.1789 43.4207C62.3826 43.6244 62.6556 43.7332 62.933 43.7332C63.0706 43.7332 63.2092 43.7066 63.3415 43.6521C63.7405 43.4867 63.9996 43.0975 63.9996 42.6665V24.5333C63.9996 23.9445 63.5228 23.4667 62.933 23.4667Z"
                    fill={props.color ?? "black"}
                />
                <path
                    d="M45.8664 59.7332H35.1999C34.61 59.7332 34.1333 60.2111 34.1333 60.7998V61.8664H23.4667V46.9333C23.4667 46.3446 22.99 45.8667 22.4001 45.8667C21.8102 45.8667 21.3335 46.3446 21.3335 46.9333V62.9332C21.3335 63.5219 21.8102 63.9998 22.4001 63.9998H35.2C35.7899 63.9998 36.2666 63.5219 36.2666 62.9332V61.8666H44.7999V62.9332C44.7999 63.5219 45.2767 63.9998 45.8666 63.9998C46.4564 63.9998 46.9332 63.5219 46.9332 62.9332V60.7998C46.9332 60.2111 46.4563 59.7332 45.8664 59.7332Z"
                    fill={props.color ?? "black"}
                />
                <path
                    d="M63.6873 0.312748C63.2703 -0.104249 62.596 -0.104249 62.179 0.312748L0.312748 62.179C-0.104249 62.5962 -0.104249 63.2703 0.312748 63.6873C0.520746 63.8953 0.793869 63.9998 1.06687 63.9998C1.33999 63.9998 1.61299 63.8953 1.82099 63.6873L63.6873 1.82111C64.1042 1.40399 64.1042 0.72987 63.6873 0.312748Z"
                    fill={props.color ?? "black"}
                />
                <path
                    d="M43.7332 57.5996C44.323 57.5996 44.7998 57.1218 44.7998 56.533V43.7331C44.7998 43.1444 44.323 42.6665 43.7332 42.6665H37.3332C36.7434 42.6665 36.2666 43.1444 36.2666 43.7331V56.533C36.2666 57.1218 36.7434 57.5996 37.3332 57.5996C37.9231 57.5996 38.3998 57.1218 38.3998 56.533V44.7997H42.6664V49.0663H41.5998C41.0099 49.0663 40.5332 49.5442 40.5332 50.1329C40.5332 50.7217 41.0099 51.1996 41.5998 51.1996H42.6664V56.5329C42.6666 57.1218 43.1433 57.5996 43.7332 57.5996Z"
                    fill={props.color ?? "black"}
                />
                <path
                    d="M26.6667 42.6665C26.0768 42.6665 25.6001 43.1444 25.6001 43.7331V52.2664C25.6001 52.8552 26.0768 53.333 26.6667 53.333H33.0667C33.6565 53.333 34.1333 52.8552 34.1333 52.2664V43.7331C34.1333 43.1444 33.6565 42.6665 33.0667 42.6665H26.6667ZM31.9999 51.1997H27.7333V44.7997H31.9999V51.1997Z"
                    fill={props.color ?? "black"}
                />
            </g>
            <defs>
                <clipPath id="clip0_941_185">
                    <rect width="64" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
