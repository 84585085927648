import { PROPERTY_PURPOSE, PROPERTY_TYPE, TAddressMdl, TRangeNumber } from "properties/_models/PropertyMdl";
import { TMeta } from "pages/_models/PageMdl";
import { Dayjs } from "dayjs";
import { TMapLocation } from "maps/Map";
import { TLang } from "_configs/sharedConfig";

export enum GEO_ZONE_TYPE {
    PROVINCE = "PROVINCE",
    CITY = "CITY",
    NEIGHBORHOOD = "NEIGHBORHOOD",
}

export type TGeoZoneLocalize = {
    [lang in TLang]: TLocalizedGeoZoneLocalize;
};

export type TLocalizedGeoZoneLocalize = {
    beachfront: {
        [propertyType in PROPERTY_TYPE]: {
            meta: TMeta;
            text: string;
            title: string;
        };
    } & {
        meta: TMeta;
        text: string;
        title: string;
    };
    [PROPERTY_PURPOSE.BUY]: {
        [propertyType in PROPERTY_TYPE]: {
            meta: TMeta;
            text: string;
            title: string;
        };
    } & {
        meta: TMeta;
        text: string;
        title: string;
    };
};

export type TPropertyData = {
    publishedProperties: number;
    prices: TRangeNumber;
};

export type TPropertiesData = {
    [type in PROPERTY_TYPE]: TPropertyData;
} & {
    generic: TPropertyData;
};

export type TGeoZoneBaseMdl = {
    _id?: string;

    osmId?: string;
    name: string;
    slug: string;
    address: TAddressMdl;
    location: { type: string; coordinates: number[] };
    zoneLocations: TMapLocation[];
    type?: GEO_ZONE_TYPE;
    position?: number;
    properties?: TPropertiesData;

    updatedAt?: Dayjs;
    createdAt?: Dayjs;
};

export interface TGeoZoneMdl extends TGeoZoneBaseMdl {
    localized: TGeoZoneLocalize;
}

const defaultLocalized = {
    BUY: { meta: { title: "", description: "" }, text: "", title: "" },
    beachfront: { meta: { title: "", description: "" }, text: "", title: "" },
};

for (const propertyType of Object.keys(PROPERTY_TYPE)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (!defaultLocalized.BUY[propertyType]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        defaultLocalized.BUY[propertyType] = { meta: { title: "", description: "" }, text: "", title: "" };
    } // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (!defaultLocalized.beachfront[propertyType]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        defaultLocalized.beachfront[propertyType] = { meta: { title: "", description: "" }, text: "", title: "" };
    }
}

const propertiesDatas: Partial<TPropertiesData> = { generic: { publishedProperties: 0, prices: { min: 0, max: 0 } } };
for (const type of Object.keys(PROPERTY_TYPE)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (!propertiesDatas[type]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        propertiesDatas[type] = { publishedProperties: 0, prices: { min: 0, max: 0 } };
    }
}

export const defaultGeoZone: Partial<TGeoZoneMdl> = {
    osmId: "",
    name: "",
    address: {
        city: "",
        province: "",
        neighbourhood: "",
    },
    location: {
        type: "Point",
        coordinates: [],
    },
    properties: propertiesDatas as TPropertiesData,
    zoneLocations: [],
    type: GEO_ZONE_TYPE.CITY,
    localized: {
        en: defaultLocalized as TLocalizedGeoZoneLocalize,
        fr: defaultLocalized as TLocalizedGeoZoneLocalize,
        es: defaultLocalized as TLocalizedGeoZoneLocalize,
        zh: defaultLocalized as TLocalizedGeoZoneLocalize,
    },
};
