import React from "react";
import { HeaderBlock } from "home/HeaderBlock";
import styles from "./_css/homePage.module.css";
import { HomeFaq } from "home/HomeFaq";
import { HomeNews } from "home/HomeNews";
import { HomeCardCities } from "home/HomeCardCities";
import { HomeCardTypes } from "home/HomeCardTypes";
import { Meta } from "_common/_utils/Meta";
import i18next from "i18next";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { HomeService } from "home/HomeService";
import { HomeTestimonials } from "home/HomeTestimonials";

export function HomePage() {
    const history = useHistory();
    const meta = {
        title: i18next.t("homePage.meta.title"),
        description: i18next.t("homePage.meta.description"),
        url: sharedConfig.appUrl + history.location.pathname,
        alternateUrls: [] as { url: string; lang: TLang }[],
    };
    for (const lang of Object.keys(sharedConfig.languages)) {
        if (lang === "en") {
            meta.alternateUrls.push({ url: sharedConfig.appUrl + "/", lang: lang as TLang });
        } else {
            meta.alternateUrls.push({ url: sharedConfig.languages[lang as TLang].baseUrl, lang: lang as TLang });
        }
    }
    const lang = i18next.language;
    return (
        <div>
            <Meta title={meta.title} url={meta.url} alternateUrls={meta.alternateUrls} description={meta.description} />
            <HeaderBlock />
            <Container className={styles.serviceContainer}>
                <HomeService />
            </Container>
            <div className={styles.testimonialContainer}>
                <HomeTestimonials />
            </div>
            <div className={styles.propertiesContainer}>
                <HomeCardTypes />
            </div>
            <div className={styles.homeContainer}>
                <HomeCardCities />
            </div>
            <div className={styles.homeContainer}>
                <HomeNews />
            </div>
            {lang !== "zh" && (
                <div className={styles.faqContainer}>
                    <HomeFaq />
                </div>
            )}
        </div>
    );
}
