import React from "react";
import { BLOG_TYPE } from "pages/_models/PageMdl";
import { ArticleCategoryCard } from "blog/ArticleCategoryCard";

export function ArticleCategories() {
    return (
        <div>
            {Object.keys(BLOG_TYPE).map((blogType, index) => {
                return (
                    <div key={blogType}>
                        <ArticleCategoryCard odd={index % 2 === 0} blogType={blogType} />
                    </div>
                );
            })}
        </div>
    );
}
