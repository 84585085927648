import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
    typography: {
        fontFamily: ["Montserrat", "Arial", "sans-serif"].join(","),
        fontSize: 14,
    },
    palette: {
        primary: {
            main: "#1a3a45",
        },
        secondary: {
            main: "#53b6db",
        },
        error: {
            main: "#FA6F4F",
        },
        background: {
            default: "#fff",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1440,
            xl: 1920,
        },
    },
});
