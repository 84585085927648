import React, { useEffect } from "react";
import styles from "./_css/galleryProperty.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { UnitStore } from "units/_stores/UnitStore";
import { RESOURCE, RESOURCE_KEY } from "admin/_common/photos/AdminPhotosStore";
import { TImage } from "_common/types/baseTypes";

type Props = {
    store: PropertyStore | UnitStore;
    resource: RESOURCE;
    imageResource?: RESOURCE_KEY;
};

export function GalleryProperty(props: Props) {
    const item =
        props.resource === RESOURCE.PROPERTIES
            ? (props.store as PropertyStore).property
            : (props.store as UnitStore).unit;
    let items: TImage[] = item[props.imageResource ?? RESOURCE_KEY.PHOTOS];
    if (props.imageResource === RESOURCE_KEY.PHOTOS && item.showroom && item.showroom.length > 0) {
        items = items.concat(item.showroom);
    }
    //TODO ????
    useEffect(() => props.store.reformatPhotosForModal(), []);
    if (!items) return null;
    return (
        <div className={styles.container}>
            <div className={"flex_row flexWrap_wrap"}>
                {items.map((photo, index) => {
                    let imageWidth = 100;
                    switch (index % 9) {
                        case 2:
                            imageWidth = 100;
                            break;
                        case 1:
                        case 0:
                        case 6:
                        case 7:
                            imageWidth = 50;
                            break;
                        case 3:
                        case 4:
                        case 5:
                            imageWidth = 33.33;
                            break;
                    }
                    return (
                        <div key={index} style={{ width: imageWidth + "%" }} className={styles.imgContainer}>
                            <LazyLoadImage
                                onClick={() =>
                                    props.store.setOpenedImageModal(props.imageResource ?? RESOURCE_KEY.PHOTOS, index)
                                }
                                className={styles.image}
                                src={photo.url}
                                alt={`${item.localized.title}-${index}`}
                                title={`${item.localized.title} photo ${index}`}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "https://via.placeholder.com/1000/cce9f3/337286?text=Zisla.com";
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
