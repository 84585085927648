import { appConfig } from "_configs/appConfig";
import sharedConfig from "_configs/sharedConfig";
import TagManager from "react-gtm-module";
import { ADMIN_PATH } from "admin/_configs/ADMIN_URLS";
import ReactGA from "react-ga";
import i18next from "i18next";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { unitsStore } from "units/_stores/unitsStore";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";

class AnalyticsUtils {
    readonly crispPromise: Promise<any> | undefined;
    readonly enabled = !sharedConfig.isDev;

    constructor(private apiPath = `${sharedConfig.apiUrl}/analytics`) {
        if (!__BROWSER__) {
            return;
        }
        ReactGA.initialize(appConfig.google.analytics, { debug: !sharedConfig.isProd, testMode: !sharedConfig.isProd });
        TagManager.initialize(appConfig.googleGtm);
        this.loadReCaptcha();
    }

    setUserInfo(email: string) {
        this.crispPromise?.then((crisp) => {
            crisp.push(["set", "user:email", email]);
        });
    }

    trackPage(url: string) {
        if (url.startsWith(ADMIN_PATH)) {
            return;
        }

        try {
            ReactGA.ga("set", "page", url);
            ReactGA.pageview(url);
        } catch (err) {
            console.error(err);
        }
    }

    async tracksPropertiesAndUnitsPage(url: string) {
        const urlInArr = url.split("/");
        const lang = i18next.language;
        if (url.startsWith(`/${lang}/${getI18nExpByLang(lang, "routes.properties")}`)) {
            const propertyAlias = urlInArr[3];
            if (urlInArr.length < 5) {
                const property = await propertiesStore.getByAliasUrl(propertyAlias, lang);
                if (property) {
                    propertiesStore.addPropertyNavigationHistory(property);
                    await fetchUtils.post(`${this.apiPath}/tracks/${lang}/${RESOURCE.PROPERTIES}/${property._id}`);
                }
            } else {
                const unitAlias = urlInArr[5];
                const unit = await unitsStore.getByAliasUrl(unitAlias, propertyAlias, lang);
                if (unit) {
                    await fetchUtils.post(`${this.apiPath}/tracks/${urlInArr[1]}/${RESOURCE.UNITS}/${unit._id}`);
                }
            }
        }
    }

    private _loadPipedrive() {
        return new Promise((resolve) => {
            (window as any).pipedriveLeadboosterConfig = {
                base: "leadbooster-chat.pipedrive.com",
                companyId: 7915705,
                playbookUuid: "a3f102ea-2663-48da-93a0-a5912487f66b",
                version: 2,
            };
            (function () {
                const w = window as any;
                if (w.LeadBooster) {
                    console.warn("LeadBooster already exists");
                } else {
                    w.LeadBooster = {
                        q: [],
                        on: function (n, h) {
                            this.q.push({ t: "o", n: n, h: h });
                        },
                        trigger: function (n) {
                            this.q.push({ t: "t", n: n });
                        },
                    };
                }
            })();
            resolve();
        });
    }

    public loadPipedrive(url: string) {
        const lang = i18next.language;
        if (url.split("/")[1] === "en" && lang === "en") {
            return this._loadPipedrive().then(() => {
                const scriptElement = document.createElement("script");
                scriptElement.src = "https://leadbooster-chat.pipedrive.com/assets/loader.js";
                scriptElement.async = true;
                document.getElementsByTagName("head")[0].appendChild(scriptElement);
            });
        }
    }

    private loadReCaptcha() {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=" + sharedConfig.reCaptcha.siteKey;
        // script.addEventListener("load", handleLoaded);
        document.body.appendChild(script);
    }
}

const analyticsUtils = new AnalyticsUtils();
export { analyticsUtils };
