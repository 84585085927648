import React from "react";
import styles from "./_css/aboutUsHeader.module.css";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import roofTop from "../assets/images/aboutUs/roofTopPool.jpg";
import montreal from "../assets/images/aboutUs/montreal.jpg";
import beachfront from "../assets/images/aboutUs/beachfront.jpg";
import team from "../assets/images/aboutUs/team.jpg";
import clsx from "clsx";

export function AboutUsHeader() {
    const { t } = useTranslation();
    return (
        <div className={clsx("flex_row mt_20", styles.container)}>
            <div className="flex-2">
                <div
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: t("aboutUsPage.header.title", { color: "#5FB8DF" }) }}
                />
                <div
                    className={clsx(styles.text, "mt_40 pr_40")}
                    dangerouslySetInnerHTML={{ __html: t("aboutUsPage.header.text") }}
                />
            </div>
            <div className={clsx("flex-1", styles.images)}>
                <div className={"flex_row"}>
                    <LazyLoadImage className={clsx("flex-1", styles.img)} src={team} />
                </div>
                <div className={"flex_row mt_20"}>
                    <div className="flex_row flex-1 mr_10">
                        <LazyLoadImage className={styles.img} src={roofTop} />
                    </div>
                    <div className="flex_row flex-1 ml_10">
                        <LazyLoadImage className={styles.img} src={montreal} />
                    </div>
                </div>
                <div className={"flex_row mt_20"}>
                    <LazyLoadImage className={clsx("flex-1", styles.img)} src={beachfront} />
                </div>
            </div>
        </div>
    );
}
