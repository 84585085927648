import React, { useState } from "react";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from "clsx";
import styles from "./_css/menu.module.css";
import { IconButton, NoSsr } from "@material-ui/core";
import { userStore } from "users/_stores/userStore";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import { IconComponent } from "components/base/icon/IconComponent";
import { panelStore } from "main/header/panel/_stores/panelStore";
import { MobileMenu } from "main/header/menu/mobile/MobileMenu";
import { DASHBOARD_URLS } from "users/dashboard/_configs/DASHBOARD_URLS";
import { USER_ROLES } from "users/_models/UserMdl";
import { MenuButtonPropertiesPage } from "main/header/menu/MenuButtonPropertiesPage";

export const MENU_ITEMS = [
    {
        key: "buy",
        itemLabelKey: "menu.buy",
        itemLink: (lang: TLang) => URLS.buy(lang),
    },
    {
        key: "aboutUs",
        itemLabelKey: "menu.aboutUs",
        itemLink: (lang: TLang) => URLS.about(lang),
    },
    {
        key: "contactUs",
        itemLabelKey: "menu.contactUs",
        itemLink: (lang: TLang) => URLS.contact(lang),
    },
    {
        key: "faq",
        itemLabelKey: "menu.faq",
        itemLink: (lang: TLang) => URLS.faq(lang),
    },
    {
        key: "blog",
        itemLabelKey: "menu.blog",
        itemLink: (lang: TLang) => URLS.blog(lang),
    },
];

export const USER_MENU_ITEMS = [
    {
        key: "profile",
        itemLabelKey: "menu.profile",
        itemLink: (lang: TLang) => URLS.user.profile(lang),
    },
    {
        key: "dashboard",
        itemLabelKey: "menu.dashboard",
        itemLink: (lang: TLang) => DASHBOARD_URLS.home(lang),
        roles: [USER_ROLES.MASTER, USER_ROLES.ADMIN, USER_ROLES.DEVELOPER],
    },
];

type Props = {
    className?: any;
};

export function Menu({ className }: Props) {
    const { t } = useTranslation();
    const [isProfileMenuOpened, setIsProfileMenuOpened] = useState(false);
    const IsLogged = __BROWSER__ && userStore.isLogged;
    return (
        <>
            <div className={clsx(styles.mobileContainer, "flex_row_center justifyContent_spaceBetween")}>
                <IconButton
                    size={"medium"}
                    name={"menu mobile"}
                    onClick={() =>
                        panelStore.open({
                            children: <MobileMenu />,
                            onClose: () => panelStore.close(),
                            withSpinner: false,
                            opened: true,
                        })
                    }
                    title={"menu mobile"}
                >
                    <IconComponent
                        icon={"menu"}
                        className={styles.menuIcon}
                        aria-labelledby={"menu mobile"}
                        title={"menu mobile"}
                        aria-label={"menu mobile"}
                    />
                </IconButton>
            </div>
            <nav className={clsx(className, styles.container, "flex_row")}>
                <ul className={clsx(styles.menu, "flex_row_center")}>
                    <li className={clsx("ph_20 position_relative", styles.menuItem)}>
                        <MenuButtonPropertiesPage />
                    </li>
                    {MENU_ITEMS.map((menuItem) => {
                        if (menuItem.key === "buy") return null;
                        return (
                            <li
                                key={menuItem.key}
                                className={clsx("ph_20 flex_center_center position_relative", styles.menuItem)}
                            >
                                <Link to={menuItem.itemLink(i18next.language as TLang)}>
                                    {t(menuItem.itemLabelKey)}
                                </Link>
                            </li>
                        );
                    })}
                    <NoSsr
                        fallback={
                            <li key={"signIn"} className={clsx(styles.signIn, "ml_40 ph_40")}>
                                <Link to={URLS.auth.signIn()}>{t("menu.signIn")}</Link>
                            </li>
                        }
                    >
                        {IsLogged ? (
                            <li
                                onMouseEnter={() => setIsProfileMenuOpened(true)}
                                onMouseLeave={() => setIsProfileMenuOpened(false)}
                                onClick={() => setIsProfileMenuOpened(!isProfileMenuOpened)}
                                key={"profile"}
                                className={clsx("ph_10 position_relative", styles.menuItem)}
                            >
                                <Link to={URLS.user.profile()}>{t("menu.profile")}</Link>
                                {isProfileMenuOpened && (
                                    <div className={clsx("position_absolute pt_5", styles.subMenuContainer)}>
                                        <ul
                                            className={clsx(styles.subMenu, {
                                                [styles.subMenuOpened]: isProfileMenuOpened,
                                            })}
                                        >
                                            {USER_MENU_ITEMS.map((menuItem) => {
                                                if (
                                                    !menuItem.roles ||
                                                    (userStore.user?.roles &&
                                                        userStore.user.roles.find(
                                                            (userRole) =>
                                                                menuItem.roles && menuItem.roles?.includes(userRole),
                                                        ))
                                                ) {
                                                    return (
                                                        <li key={menuItem.key} className={"ph_10"}>
                                                            <Link to={menuItem.itemLink(i18next.language as TLang)}>
                                                                {t(menuItem.itemLabelKey)}
                                                            </Link>
                                                        </li>
                                                    );
                                                }
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        ) : (
                            <li key={"signIn"} className={clsx(styles.signIn, "ml_40 ph_40")}>
                                <Link to={URLS.auth.signIn()}>{t("menu.signIn")}</Link>
                            </li>
                        )}
                    </NoSsr>
                </ul>
            </nav>
        </>
    );
}
