import React, { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";

type Props = {
    url?: string;
    className?: string;
    activeClassName?: string;
    onClick?: () => void;
    ariaLabel?: string;
};

export function ExternalOrNavLink(props: PropsWithChildren<Props>) {
    const url = props.url ?? "/";
    return url.startsWith("/") ? (
        <NavLink
            className={props.className}
            activeClassName={props.activeClassName}
            to={url}
            exact
            onClick={props.onClick}
            aria-label={props.ariaLabel ?? url}
        >
            {props.children}
        </NavLink>
    ) : (
        <a
            className={props.className}
            href={url}
            aria-label={props.ariaLabel ?? url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={props.onClick}
        >
            {props.children}
        </a>
    );
}
