import React from "react";
import z from "../assets/images/icons/Z.svg";
import styles from "./_css/homeService.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function HomeService() {
    const { t } = useTranslation();
    return (
        <div className={clsx("flex_row_center justifyContent_spaceBetween", styles.container)}>
            <div className={"flex-3 position_relative"}>
                <h3 className={styles.title}>{t("homePage.serviceBlock.title1")}</h3>
                <div className={styles.text}>{t("homePage.serviceBlock.text1")}</div>
                <h3 className={clsx(styles.title, "mt_20")}>{t("homePage.serviceBlock.title2")}</h3>
                <div className={styles.text}>
                    {t("homePage.serviceBlock.text2")}
                    <Link to={URLS.contact()} className={styles.link}>
                        {t("homePage.serviceBlock.contactUs")}
                    </Link>
                </div>
            </div>
            <div className={clsx("flex-1", styles.placeholder)} />
            <div className={"flex-2 textAlign_center"}>
                <LazyLoadImage src={z} alt={"Zisla logo"} className={styles.img} />
            </div>
        </div>
    );
}
