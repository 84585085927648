import { getI18nExpByLang } from "_common/_utils/pageUtils";
import i18next from "i18next";

export function formatPrice(price: number, alreadyDivided = false, asterix = false) {
    if (!price) return "0";
    let displayPrice = Math.round(price);
    displayPrice = alreadyDivided ? displayPrice : displayPrice / 100;
    const language = getI18nExpByLang(i18next.language, `currency.${i18next.language}`);
    const formatter = new Intl.NumberFormat(language, {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
    });
    let formatPrice = formatter.format(displayPrice);
    if (language !== "fr-CA") {
        formatPrice = formatter.format(displayPrice).replaceAll(",", " ").replace("$", " $ ");
    }
    if (asterix) formatPrice += "*";
    return formatPrice;
}

export function formatPesosPrice(price: number, alreadyDivided = false) {
    let displayPrice = Math.round(price);
    displayPrice = alreadyDivided ? displayPrice : displayPrice / 100;

    const language = getI18nExpByLang(i18next.language, `currency.${i18next.language}`);
    const formatter = new Intl.NumberFormat(language, {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 0,
    });
    let formatPrice = formatter.format(displayPrice);
    if (language !== "fr") {
        formatPrice = formatter.format(displayPrice).replaceAll(",", " ").replace("$", " $ ");
    }
    return formatPrice;
}

export enum PRICE_CONVERSION {
    PESOS_TO_USD = 0.0486827,
    USD_TO_PESOS = 20.5412,
}

export function convertPrices(value: number, fromToRatio: PRICE_CONVERSION) {
    return Math.round(value * fromToRatio);
}

export function numberWithCommas(number?: number | string) {
    if (!number) return "";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function nFormatter(price: number, digits: number, alreadyDivided = false) {
    let num = price;
    if (!alreadyDivided) num = num / 100;
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
