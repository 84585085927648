import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { action, observable } from "mobx";

class SettingsStore extends BaseResourceStore<ISettingsMdl> {
    @observable
    filterIsOpened = "";

    constructor() {
        super("settings");
    }

    @action close() {
        this.filterIsOpened = "";
    }

    @action setFilterIsOpened(value: string) {
        this.filterIsOpened = value;
    }
}

const settingsStore = new SettingsStore();
export { settingsStore };
