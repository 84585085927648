import React from "react";
import { Container, Grid } from "@material-ui/core";
import { PageTitle } from "_common/ui/pages/PageTitle";
import { FeaturedArticles } from "blog/FeaturedArticles";
import { ArticleCategories } from "blog/ArticleCategories";
import { ArticlesSearchBar } from "blog/ArticlesSearchBar";
import { useParams } from "react-router";
import { ITEM_MODEL, ItemsListComponent } from "components/base/itemsList/itemsListComponent";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import { Meta } from "_common/_utils/Meta";
import styles from "./_css/blogPage.module.css";
import clsx from "clsx";
import { URLS_ALTERNATES } from "_configs/URLS_ALTERNATES";
import sharedConfig, { TLang } from "_configs/sharedConfig";

export function BlogPage() {
    const { blogType } = useParams();

    const items = [
        {
            itemLabel: "Blog",
            url: URLS.blog(),
        },
    ];
    const alternateUrls = [];
    for (const lang of Object.keys(sharedConfig.languages)) {
        alternateUrls.push({
            url: URLS_ALTERNATES.blog(lang),
            lang: lang as TLang,
        });
    }

    const { t } = useTranslation();
    return (
        <Container>
            <Meta
                title={
                    blogType
                        ? t("blogPage.meta.blogTypes.title", {
                              type: t("blogPage.blogTypes." + blogType.toUpperCase()),
                          })
                        : t("blogPage.meta.blog.title")
                }
                description={
                    blogType
                        ? t("blogPage.meta.blogTypes.description", {
                              type: t("blogPage.blogTypes." + blogType.toUpperCase()),
                          })
                        : t("blogPage.meta.blog.description")
                }
                alternateUrls={alternateUrls}
            />
            <Breadcrumb
                items={blogType ? items : undefined}
                currentItem={
                    blogType
                        ? { itemLabel: blogType.charAt(0).toUpperCase() + blogType.slice(1) }
                        : { itemLabel: "Blog" }
                }
            />
            <PageTitle style={{ display: "none" }}>Blog</PageTitle>
            {(!blogType || blogType === "") && (
                <div className={clsx(styles.section, "flex_row")}>
                    <div className={clsx(styles.featuredArticles, " flex_column flex-1")}>
                        <FeaturedArticles />
                    </div>
                    <div className={clsx(styles.searching, " flex_column flex-1")}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ArticlesSearchBar />
                            </Grid>
                            <Grid item xs={12}>
                                <ArticleCategories />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )}
            <ItemsListComponent className={"mt_40"} item_model={ITEM_MODEL.ARTICLE} pageSize={14} />
        </Container>
    );
}
