import React from "react";
import styles from "./_css/contactUsForm.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Input } from "_common/ui/forms/Input";
import { TextArea } from "_common/ui/forms/TextArea";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { TContactForm } from "components/misc/contactUs/ContactFormComponent";
import { userStore } from "users/_stores/userStore";
import { Subject } from "components/misc/contactUs/Subject";
import i18next from "i18next";
import { ContactFormBtn } from "components/misc/contactUs/ContactFormBtn";
import { AC_TAGS } from "users/_models/UserMdl";
import { InputPhone } from "_common/ui/forms/InputPhone";
import { HiddenFieldsAttributerForm } from "components/form/HiddenFieldsAttributerForm";

export const PHONE = "1-877-515 (4752)";
export const WHATSAPP_PHONE = "+1-514-932-5688";

export function ContactUsForm() {
    const { t } = useTranslation();

    const form = useForm<TContactForm>({
        defaultValues: {
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            text: "",
            subject: "",
        },
        mode: "onBlur",
    });

    return (
        <FormProvider {...form}>
            <div className={clsx(styles.formContainer, "p_20 flex-3 mr_60")}>
                <h2 className={styles.title}>{t("contactPage.title")}</h2>
                <div className={clsx(styles.inputs, "flex_row flex-1")}>
                    <div className={clsx(styles.halfInput, "flex-2 mr_40")}>
                        <Controller
                            as={Input}
                            name="name"
                            control={form.control}
                            className={clsx(styles.input, "p_10")}
                            placeholder={t("contactPage.form.name")}
                        />
                        <Controller
                            as={Input}
                            name="email"
                            control={form.control}
                            className={clsx(styles.input, "p_10")}
                            placeholder={t("contactPage.form.email")}
                            required
                            rules={{
                                required: t<string>("errors.forms.required"),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            }}
                        />
                        <Controller
                            as={InputPhone}
                            name="phone"
                            control={form.control}
                            placeholder={t("contactPage.form.phone")}
                            className={styles.input}
                        />
                        <Controller
                            as={Input}
                            defaultValue={Subject[i18next.language][0]}
                            placeholder={"Subject"}
                            name="subject"
                            control={form.control}
                            className={clsx(styles.input, "p_10")}
                        />
                    </div>
                    <div className={"flex-3"}>
                        <Controller
                            as={TextArea}
                            name="text"
                            control={form.control}
                            rows={5}
                            className={clsx(styles.input, "p_10")}
                            placeholder={"Message"}
                        />
                    </div>
                </div>
                <div className={"flex-1 flex_column_center mt_20"}>
                    <ContactFormBtn acTags={AC_TAGS.L4_CONTACT_US} />
                </div>
            </div>
            <HiddenFieldsAttributerForm />
        </FormProvider>
    );
}
