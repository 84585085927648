import React from "react";
import { BLOG_TYPE, IPageMdl } from "pages/_models/PageMdl";
import styles from "./_css/articlesSimilar.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { pagesStore } from "pages/_stores/pagesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { ArticleSimilar } from "blog/ArticleSimilar";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";

type Props = {
    blogType?: BLOG_TYPE;
    blogId: string;
};

export function ArticlesSimilar(props: Props) {
    const { t } = useTranslation();
    const listStore = pagesStore.getListStore(
        "similarArticles_" + props.blogId,
        3,
        [{ id: "_id", value: props.blogId, type: TFilterType.NUMBER, op: "ne" }],
        undefined,
    );

    const loadingState = listStore.currentLoadingState;
    if (!loadingState) return null;
    return (
        <div className={clsx(styles.container, "p_20")}>
            <div className={clsx(styles.title, "pb_10")}>{t("blogPage.similar.title")}</div>
            <LoadableFromLoading<{ items: IPageMdl[] }>
                loading={loadingState}
                renderer={() => {
                    return listStore.paginatedItems.map((blog: IPageMdl) => {
                        return (
                            <div key={blog._id} className="mt_20">
                                <ArticleSimilar blog={blog} />
                            </div>
                        );
                    });
                }}
            />
        </div>
    );
}
