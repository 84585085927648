import React from "react";

type Props = {
    color?: string;
    size?: number | string;
};

export function IconBath(props: Props) {
    return (
        <svg
            width={props.size ?? "30"}
            height={props.size ?? "30"}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M30.0002 13.6204H17.3525V14.5369H30.0002V13.6204Z" fill={props.color ?? "black"} />
            <path d="M7.0265 13.6204H0V14.5369H7.0265V13.6204Z" fill={props.color ?? "black"} />
            <path
                d="M27.6635 16.8588V15.9425H28.5947V15.0259H17.3523V15.9424H26.7258V16.8587C26.7258 20.7435 23.5653 23.904 19.6805 23.904H10.3273C6.44258 23.904 3.28203 20.7435 3.28203 16.8587V15.9424H7.02641V15.0259H1.40527V15.9424H2.34436V16.8587C2.34436 19.2731 3.42189 21.4405 5.12123 22.9056C4.8623 23.1565 4.70463 23.5084 4.70463 23.904V24.8417C4.70463 25.1061 4.48537 25.3213 4.21584 25.3213C3.94631 25.3213 3.72705 25.1062 3.72705 24.8417V23.4577H2.32174V24.3742H2.81053V24.8417C2.81053 25.6115 3.44094 26.2377 4.21584 26.2377C4.99068 26.2377 5.62115 25.6115 5.62115 24.8417V23.904C5.62115 23.7166 5.71279 23.5582 5.87398 23.4811C7.14693 24.3399 8.67963 24.8417 10.3273 24.8417H19.6805C21.3269 24.8417 22.8583 24.3406 24.1307 23.4832C24.2889 23.5612 24.3788 23.7184 24.3788 23.904V24.8417C24.3788 25.6115 25.0092 26.2377 25.7841 26.2377C26.559 26.2377 27.1894 25.6115 27.1894 24.8417V24.3742H27.6782V23.4577H26.2729V24.8417C26.2729 25.1061 26.0536 25.3213 25.7841 25.3213C25.5146 25.3213 25.2953 25.1062 25.2953 24.8417V23.904C25.2953 23.5103 25.1393 23.1597 24.8825 22.9092C26.5842 21.444 27.6635 19.2751 27.6635 16.8588Z"
                fill={props.color ?? "black"}
            />
            <path d="M8.43166 18.7527H7.51514V19.6692H8.43166V18.7527Z" fill={props.color ?? "black"} />
            <path d="M9.83693 18.7527H8.92041V19.6692H9.83693V18.7527Z" fill={props.color ?? "black"} />
            <path d="M11.2422 18.7527H10.3257V19.6692H11.2422V18.7527Z" fill={props.color ?? "black"} />
            <path d="M12.6475 18.7527H11.731V19.6692H12.6475V18.7527Z" fill={props.color ?? "black"} />
            <path d="M14.0532 18.7527H13.1367V19.6692H14.0532V18.7527Z" fill={props.color ?? "black"} />
            <path d="M15.458 18.7527H14.5415V19.6692H15.458V18.7527Z" fill={props.color ?? "black"} />
            <path d="M16.8638 18.7527H15.9473V19.6692H16.8638V18.7527Z" fill={props.color ?? "black"} />
            <path d="M14.5416 15.9421H9.83691V16.8587H14.5416V15.9421Z" fill={props.color ?? "black"} />
            <path
                d="M16.8794 13.9776C16.8794 12.9876 16.074 12.1821 15.084 12.1821H9.29738C8.30738 12.1821 7.50195 12.9876 7.50195 13.9776V18.2775H16.8794V13.9776ZM15.9416 15.9424H15.0304V16.8589H15.9416V17.3398H8.43963V16.8589H9.34818V15.9424H8.43963V13.9776C8.43963 13.5046 8.82436 13.1199 9.29732 13.1199H15.0839C15.5568 13.1199 15.9416 13.5046 15.9416 13.9776V15.9424Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M21.5593 3.76221C20.2741 3.76221 19.2285 4.82217 19.2285 6.10525H20.145C20.145 5.31096 20.7795 4.67867 21.5593 4.67867C22.3391 4.67867 22.9736 5.30463 22.9736 6.08662V8.42703H23.8901V6.08662C23.8901 4.79926 22.8446 3.76221 21.5593 3.76221Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M20.1631 7.5105V8.42696H20.6519V8.91581H21.5684V8.42696H22.0572V7.5105H20.1631Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M24.8677 7.5105V8.42696H25.3565V8.91581H26.273V8.42696H26.7007V7.5105H24.8677Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M26.273 9.83781V9.34369H25.3565V9.83781H23.8902V8.91602H22.9737V9.83781H21.5683V9.34369H20.6518V9.83781H19.6929V12.1821H27.1948V9.83781H26.273ZM26.2571 11.2445H20.6305V10.7756H26.2571V11.2445Z"
                fill={props.color ?? "black"}
            />
        </svg>
    );
}
