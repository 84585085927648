import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "_common/ui/forms/Input";
import { Helmet } from "react-helmet-async";
import { appConfig } from "_configs/appConfig";

type TFlareTrk = {
    drillData: {
        channel: string;
        drillDown1: string;
        drillDown2: string;
        drillDown3: string;
        drillDown4: string;
    };
    landingURL: string;
    landing_page_group: string;
};

export function HiddenFieldsAttributerForm() {
    const form = useFormContext();
    useEffect(() => {
        const flareTrkData: TFlareTrk = (document as Document & { FlareTrk: { data: TFlareTrk } })?.FlareTrk?.data;
        if (flareTrkData) {
            form.setValue("attributerChannel", flareTrkData.drillData.channel);
            form.setValue("attributerChanneldrilldown1", flareTrkData.drillData.drillDown1);
            form.setValue("attributerChanneldrilldown2", flareTrkData.drillData.drillDown2);
            form.setValue("attributerChanneldrilldown3", flareTrkData.drillData.drillDown3);
            form.setValue("attributerChanneldrilldown4", flareTrkData.drillData.drillDown4);
            form.setValue("attributerLandingpage", flareTrkData.landingURL);
            form.setValue("attributerLandingpagegroup", flareTrkData.landing_page_group);
        }
    }, []);
    return (
        <div>
            <Helmet>
                <script type="text/javascript" src={appConfig.attributer.url}></script>
            </Helmet>
            <Controller as={Input} className={"hidden"} control={form.control} name="attributerChannel" />
            <Controller control={form.control} as={Input} type="hidden" name="attributerChanneldrilldown1" />
            <Controller control={form.control} as={Input} type="hidden" name="attributerChanneldrilldown2" />
            <Controller control={form.control} as={Input} type="hidden" name="attributerChanneldrilldown3" />
            <Controller control={form.control} as={Input} type="hidden" name="attributerChanneldrilldown4" />
            <Controller control={form.control} as={Input} type="hidden" name="attributerLandingpage" />
            <Controller control={form.control} as={Input} type="hidden" name="attributerLandingpagegroup" />
        </div>
    );
}
