import React, { useEffect, useState } from "react";
import zislaLogo from "../assets/images/zisla-logo.png";
import styles from "./_css/maintenancePage.module.css";
import { Button, Container } from "@material-ui/core";
import { AppContent } from "AppContent";
import sharedConfig from "_configs/sharedConfig";
import { Input } from "_common/ui/forms/Input";
import { LazyLoadImage } from "react-lazy-load-image-component";

const TMP_PASSWORD = "i4MnrqD&3FN#&54";

function getPasswordFromLocalStorage() {
    if (!__BROWSER__) return undefined;
    return localStorage.getItem("TMP_PASSWORD");
}

export function MaintenancePage() {
    const [isLock, setIsLock] = useState(sharedConfig.isInMaintenance);
    const [password, setPassword] = useState(getPasswordFromLocalStorage());

    useEffect(() => {
        if (sharedConfig.isInMaintenance) setIsLock(password !== TMP_PASSWORD);
    }, []);

    return (
        <div className={styles.container}>
            {isLock ? (
                <Container className={styles.image}>
                    <LazyLoadImage src={zislaLogo} className={styles.img} alt={"maintenance picture"} />
                    <h2 className="mv_20">We are coming soon !</h2>
                    <div className={"flex_column_center"}>
                        <Input
                            className="mv_20"
                            type="password"
                            placeholder={"password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            type="submit"
                            onClick={() => {
                                localStorage.setItem("TMP_PASSWORD", password ?? "");
                                setIsLock(password !== TMP_PASSWORD);
                            }}
                        >
                            Go
                        </Button>
                    </div>
                </Container>
            ) : (
                <AppContent />
            )}
        </div>
    );
}
