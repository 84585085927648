import React from "react";
import styles from "./_css/propertyOrUnitGalleryOpenBtn.module.css";
import clsx from "clsx";
import { IconOpenGallery } from "properties/propertyPage/icons/IconOpenGallery";
import { useTranslation } from "react-i18next";

export function PropertyOrUnitGalleryOpenBtn() {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "flex_row_center")}>
            <div className={clsx("position_absolute", styles.icon)}>
                <IconOpenGallery />
            </div>
            <div className={clsx(styles.text, "ph_20")}>{t("propertyPage.gallery.openGallery")}</div>
        </div>
    );
}
