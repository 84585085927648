import React, { useEffect, useState } from "react";
import { Input } from "_common/ui/forms/Input";
import styles from "./_css/articlesSearchBar.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { IconComponent } from "components/base/icon/IconComponent";
import { pagesStore } from "pages/_stores/pagesStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import _ from "lodash";
import { IPageMdl } from "pages/_models/PageMdl";
import { observer } from "mobx-react";
import { ArticleSearchCard } from "blog/ArticleSearchCard";

type Props = {
    isArticlePage?: boolean;
};

export const ArticlesSearchBar = observer((props: Props) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");

    const debounceSetSearch = _.debounce((search: string) => setSearch(search), 1000);

    useEffect(() => {
        pagesStore.fetchBySearch(search);
    }, [search]);

    return (
        <div className={clsx({ [styles.container]: !props.isArticlePage }, "position_relative")}>
            <Input
                className={clsx(styles.input, { [styles.isArticlePageInput]: props.isArticlePage })}
                placeholder={t("blogPage.search")}
                endIconClassName={styles.endIcon}
                endIcon={
                    <div className={clsx(styles.searchBtn, "flex_center_center")}>
                        <IconComponent fontSize="large" icon={"search"} />
                    </div>
                }
                onChange={(event) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    debounceSetSearch(event.target.value);
                }}
            />
            {search.length > 2 && (
                <div className={clsx(styles.searchContainer, "position_absolute")}>
                    <LoadableFromLoading<IPageMdl[]>
                        loading={pagesStore.searchArticlesLoadingState}
                        renderer={() => {
                            if (!pagesStore.searchResults || pagesStore.searchResults.length === 0) {
                                return null;
                            }
                            return pagesStore.searchResults.map((blog) => {
                                return <ArticleSearchCard key={blog._id} blog={blog} />;
                            });
                        }}
                    />
                </div>
            )}
        </div>
    );
});
