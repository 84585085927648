import React from "react";
import { IPageMdl } from "pages/_models/PageMdl";
import i18next from "i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./_css/articleSimilar.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
    blog: IPageMdl;
};

export function ArticleSimilar(props: Props) {
    const lang = i18next.language;
    const { t } = useTranslation();
    return (
        // <Link to={"/" + lang + "/" + t("routes.blogPage") + props.blog.localized[lang].url}>
        <Link to={"/" + lang + props.blog.localized[lang].url}>
            <div className="flex_row_center">
                <div className="flex-1 mr_10">
                    <LazyLoadImage
                        className={styles.image}
                        src={props.blog.localized[lang].image}
                        alt={props.blog.localized[lang].title}
                        title={props.blog.localized[lang].title}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "https://via.placeholder.com/60/cce9f3/337286?text=Zisla.com";
                        }}
                    />
                </div>
                <div className={clsx("flex-2 flex_column pv_5", styles.title)}>{props.blog.localized[lang].title}</div>
            </div>
        </Link>
    );
}
