export enum TFilterType {
    STRING = "string",
    DATE = "date",
    BOOLEAN = "boolean",
    NUMBER = "number",
    COORDINATES = "coordinates",
    ZONE = "intoZone",
    NUMBER_BETWEEN = "numberBetween",
    ENUM = "enum",
    ID = "_id",
    IN = "in",
    NE = "not_equal",
    DEVELOPER = "developer",
    DELIVERY_DATE = "deliveryDate",
    STATUS = "status",
}

export type TResourceFilterConfig = {
    path: string;
    label: string;
    type: TFilterType;
    enum?: { label?: string; value: string }[];
};
