import React, { useState } from "react";
import { Link } from "react-router-dom";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";
import { HIDDEN_PROPERTY_TYPE, PROPERTY_PURPOSE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import clsx from "clsx";
import styles from "main/header/menu/_css/menu.module.css";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { useTranslation } from "react-i18next";
import { fetchSearchProperties } from "_common/_utils/searchUtils";
import { useHistory } from "react-router";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";

export function MenuButtonPropertiesPage() {
    const { t } = useTranslation();
    const [isPropertyTypeOpened, setIsPropertyTypeOpened] = useState(false);
    const history = useHistory();
    const { city, neighbourhood, provinceLong, province } = geoZonesStore.address;
    const { isProvince, isCity, isNeighbourhood } = geoZonesStore.isType;
    const propertiesStore = propertiesBuyingStore;
    const listStore =
        propertiesStore.listsStores[PROPERTY_PURPOSE.BUY] ?? propertiesStore.getListStore(PROPERTY_PURPOSE.BUY);
    return (
        <div onMouseEnter={() => setIsPropertyTypeOpened(true)} onMouseLeave={() => setIsPropertyTypeOpened(false)}>
            <Link
                onClick={(e) => {
                    e.preventDefault();
                    listStore.removeFilterByFilterId("type");
                    fetchSearchProperties(propertiesStore, listStore, history, true);
                }}
                to={URLS.buy(
                    i18next.language,
                    reformatStringForUrls(isProvince ? provinceLong : province),
                    !isProvince ? reformatStringForUrls(city) : undefined,
                    isNeighbourhood ? reformatStringForUrls(neighbourhood) : undefined,
                )}
            >
                {t("menu.buy")}
            </Link>
            {isPropertyTypeOpened && (
                <div className={clsx("position_absolute pt_5", styles.subTypeMenuContainer)}>
                    <ul
                        className={clsx(styles.subTypeMenu, {
                            [styles.subMenuOpened]: isPropertyTypeOpened,
                        })}
                    >
                        {Object.keys(PROPERTY_TYPE)
                            .filter((propertyType) => !HIDDEN_PROPERTY_TYPE.includes(propertyType))
                            .map((propertyType) => {
                                const typeUrl = t("property.typesUrl." + propertyType).toLowerCase();
                                const link = !geoZonesStore.geoZone
                                    ? URLS.buy(i18next.language, typeUrl)
                                    : URLS.buy(
                                          i18next.language,
                                          reformatStringForUrls(isProvince ? provinceLong : province),
                                          isProvince ? typeUrl : reformatStringForUrls(city),
                                          isNeighbourhood
                                              ? reformatStringForUrls(neighbourhood)
                                              : isCity
                                              ? typeUrl
                                              : undefined,
                                          isNeighbourhood ? typeUrl : undefined,
                                      );
                                return (
                                    <li key={propertyType} className={"ph_10"}>
                                        <Link
                                            onClick={(e) => {
                                                e.preventDefault();
                                                listStore.removeFilterByFilterId("type");
                                                listStore.updateFilterAndReload({
                                                    id: "type",
                                                    type: TFilterType.IN,
                                                    value: [propertyType],
                                                });
                                                fetchSearchProperties(propertiesStore, listStore, history, true);
                                            }}
                                            to={link}
                                        >
                                            {t("property.types." + propertyType)}
                                        </Link>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            )}
        </div>
    );
}
