import React, { useState } from "react";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { URLS } from "_configs/URLS";
import sharedConfig from "_configs/sharedConfig";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/propertyShare.module.css";
import { IconShare } from "properties/propertyPage/icons/IconShare";
import { IconButton } from "@material-ui/core";

type Props = {
    isItemPage?: boolean;
    label?: string;
    size?: string;
    color?: string;
    url?: string;
    title?: string;
    direction?: "row" | "column";
    weight?: number;
    labelButton?: boolean;
};

export function PropertyShare(props: Props) {
    const [isSharing, setIsSharing] = useState(false);
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    const shareUrl = props.url ?? sharedConfig.appUrl + URLS.property(propertyStore.property.localized.urlAlias);
    const title = t("words.share");
    const iconSize = props.title ?? 24;
    return (
        <div className={clsx("position_relative", styles.container)}>
            {(isSharing || !props.isItemPage) && (
                <div
                    className={clsx("flex_column", {
                        [styles.isItemPage]: props.isItemPage,
                        [styles.isRow]: props.direction === "row",
                    })}
                >
                    <WhatsappShareButton url={shareUrl} title={title} className="mr_5">
                        <WhatsappIcon size={iconSize} round />
                    </WhatsappShareButton>

                    <FacebookShareButton url={shareUrl} quote={title} className="mr_5">
                        <FacebookIcon size={iconSize} round />
                    </FacebookShareButton>

                    <EmailShareButton url={shareUrl} subject={title} body="body" className="mr_5">
                        <EmailIcon size={iconSize} round />
                    </EmailShareButton>

                    <TwitterShareButton url={shareUrl} title={title} className="mr_5">
                        <TwitterIcon size={iconSize} round />
                    </TwitterShareButton>

                    <TelegramShareButton url={shareUrl} title={title} className="mr_5">
                        <TelegramIcon size={iconSize} round />
                    </TelegramShareButton>
                </div>
            )}
            {props.isItemPage && (
                <div className={props.direction ? `flex_${props.direction}_center` : "flex_center_center"}>
                    <IconButton onClick={() => setIsSharing(!isSharing)} className={styles.icon} size={"medium"}>
                        <IconShare color={props.color} size={props.size} />
                        {props.label && props.labelButton && (
                            <div
                                className={clsx(styles.label, "ml_5")}
                                style={{
                                    fontWeight: props.weight,
                                    color: props.color,
                                }}
                            >
                                {props.label}
                            </div>
                        )}
                    </IconButton>
                    {props.label && !props.labelButton && (
                        <div
                            className={styles.label}
                            style={{
                                fontWeight: props.weight,
                                color: props.color,
                            }}
                        >
                            {props.label}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
