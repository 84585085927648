import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet-async";
import { userStore } from "users/_stores/userStore";
import i18next from "i18next";
import { PAGE_REF } from "pages/PageRegular";
import { dialogStore } from "main/header/dialog/_stores/dialogStore";

type Props = {
    isProfilePage?: boolean;
};

export const PropertyModalAppointment = observer((props: Props) => {
    let url = "";

    useEffect(() => {
        if (dialogStore.option?.opened) {
            if (PAGE_REF.current) {
                PAGE_REF.current.style.overflow = "hidden";
            }
        }
        return () => {
            if (PAGE_REF.current) {
                PAGE_REF.current.style.overflow = "auto";
            }
        };
    });

    switch (i18next.language) {
        case "fr":
            if (props.isProfilePage) {
                url = "https://calendly.com/zisla/trouver-la-bonne-propriete?hide_gdpr_banner=1&primary_color=53b6db";
            } else {
                url = userStore.isLogged
                    ? "https://calendly.com/zisla/contact-your-zisla-agent-clone?hide_gdpr_banner=1&primary_color=53b6db"
                    : "https://calendly.com/zisla/tour-virtuel?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=53b6db";
            }
            break;
        default:
            if (props.isProfilePage) {
                url = "https://calendly.com/zisla/found-the-one?hide_gdpr_banner=1&primary_color=53b6db";
            } else {
                url = userStore.isLogged
                    ? "https://calendly.com/zisla/property_inquiry?hide_gdpr_banner=1&primary_color=53b6db"
                    : "https://calendly.com/zisla/found-the-one-clone?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=53b6db";
            }
            break;
    }
    if (!url) return null;

    if (!dialogStore.option) return null;

    return (
        <div>
            <Helmet>
                <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async />
            </Helmet>
            <div
                className="calendly-inline-widget"
                data-url={url}
                style={{ minWidth: "100%", minHeight: "100%", height: "100vh" }}
            />
        </div>
    );
});
