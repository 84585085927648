import React from "react";
import { Price } from "components/propertyOrUnit/Price";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";
import { formatPesosPrice } from "_common/_utils/currencyUtils";
import styles from "./_css/PropertyPrice.module.css";
import clsx from "clsx";
import { getPriceBySquare, isInPesosProperty } from "_common/_utils/propertyUtils";
import discount from "shared/assets/images/icons/discount.svg";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { unitsStore } from "units/_stores/unitsStore";

type Props = {
    units?: (TUnitListingMdl | undefined)[];
};

export function PropertyPrice(props: Props) {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();

    const priceIsInPesos = isInPesosProperty(propertyStore.property);
    const isDiscount = propertyStore.property.discount && !!propertyStore.property.localized.discountText;

    if (!propertyStore.property.price.min) return null;
    return (
        <div className={clsx("flex-1 mt_10 p_10", styles.container)}>
            <Price
                price={propertyStore.property.price.min}
                priceIsInPesos={priceIsInPesos}
                priceOnRequest={propertyStore.property.priceIsNotDisplayed}
            />
            {priceIsInPesos && !propertyStore.property.priceIsNotDisplayed && (
                <div className={styles.text}>({formatPesosPrice(propertyStore.property.priceInPesos?.min)})</div>
            )}
            {!propertyStore.property.priceIsNotDisplayed && (
                <LoadableFromLoading
                    loading={unitsStore.getListStore(propertyStore.property._id).currentLoadingState}
                    contentRender={
                        <div className={clsx(styles.text, "mb_20")}>{t("propertyPage.priceBySquareLoader")}</div>
                    }
                    renderer={() => {
                        const priceBySquare = getPriceBySquare(props.units);
                        if (!priceBySquare) return null;
                        return (
                            <div className={clsx(styles.text, "mb_10")}>
                                {t("propertyPage.priceBySquare", { count: priceBySquare })}
                            </div>
                        );
                    }}
                />
            )}
            {!propertyStore.property.hideInventory && (
                <>
                    {isDiscount && (
                        <p style={{ fontSize: 22 }} className={"mt_10 flex_center_center"}>
                            <img src={discount} alt={"discount icon"} />
                            {propertyStore.property.localized.discountText}
                        </p>
                    )}
                    {propertyStore.property.fees && (
                        <div className={styles.textSmaller}>
                            {t("propertyPage.fees") +
                                t(`propertyPage.feesTypes.${propertyStore.property.fees.type}`, {
                                    count: propertyStore.property.fees.value,
                                })}
                        </div>
                    )}
                    <div className={styles.textSmaller}>{t("propertyPage.taxes")}</div>
                </>
            )}
        </div>
    );
}
