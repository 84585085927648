import React from "react";
import { URLS } from "_configs/URLS";
import { getResourceInitialStateValue, putItemsInInitialState } from "_common/_utils/initialStateUtils";
import { useTranslation } from "react-i18next";
import { TCity } from "_common/regions/regions";
import { FooterLinkList } from "main/footer/FooterLinkList";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { POPULAR_SEARCHES_CITIES } from "main/footer/popularSearches";

const DISPLAYED_CITY = 3;

function pickCitiesForFooter(numberOfCities = DISPLAYED_CITY, cities: TCity[] = []): TCity[] {
    if (cities.length === numberOfCities) return cities;
    const pickedCity = POPULAR_SEARCHES_CITIES[Math.floor(Math.random() * POPULAR_SEARCHES_CITIES.length)];
    if (cities.includes(pickedCity)) return pickCitiesForFooter(numberOfCities, cities);
    cities.push(pickedCity);
    return pickCitiesForFooter(numberOfCities, cities);
}

export const FooterPopularSearches = React.memo(() => {
    const { t } = useTranslation();
    let pickedCities: TCity[] = [];

    if (!__BROWSER__) {
        const pickedCitiesFromPreloadedState = getResourceInitialStateValue("pickedCities") as { items: TCity[] };
        if (!pickedCitiesFromPreloadedState) {
            pickedCities = pickCitiesForFooter();
            putItemsInInitialState<TCity>("pickedCities", pickedCities);
        }
    } else {
        const pickedCitiesFromPreloadedState = getResourceInitialStateValue("pickedCities") as { items: TCity[] };
        pickedCities = pickedCitiesFromPreloadedState?.items;
        if (pickedCities && pickedCities?.length === 0) {
            pickedCities = pickCitiesForFooter();
        }
    }
    if (!pickedCities) return null;

    const popularSearches = pickedCities
        .map((city) => {
            const regionUrl = reformatStringForUrls(t("provinces.short." + city.region));
            const cityUrl = reformatStringForUrls(city.name);
            return [
                {
                    url: URLS.buy(undefined, regionUrl, cityUrl, t("property.typesUrl.house")).toLowerCase(),
                    label: t(`footer.popularSearchesLinks.house`, { city: city.name }),
                },
                {
                    url: URLS.buy(undefined, regionUrl, cityUrl, t("property.typesUrl.condo")).toLowerCase(),
                    label: t(`footer.popularSearchesLinks.condo`, { city: city.name }),
                },
                {
                    url: URLS.buy(undefined, regionUrl, cityUrl, t("property.typesUrl.villa")).toLowerCase(),
                    label: t(`footer.popularSearchesLinks.villa`, { city: city.name }),
                },
                {
                    url: URLS.buy(undefined, regionUrl, cityUrl, t("property.typesUrl.land")).toLowerCase(),
                    label: t(`footer.popularSearchesLinks.land`, { city: city.name }),
                },
            ];
        })
        .flat();

    return <FooterLinkList small titleKeyLabel={t("footer.popularSearches")} list={popularSearches} />;
});
