import React from "react";

type Props = {
    color?: string;
    size?: string;
};

export function IconUnitsLeft(props: Props) {
    return (
        <svg
            width={props.size ?? "30"}
            height={props.size ?? "30"}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5 7.00002H22.5C22.7765 7.00002 23 6.77602 23 6.50004C23 6.22406 22.7765 6 22.5 6H12.5C12.2235 6 12 6.224 12 6.49998C12 6.77596 12.2235 7.00002 12.5 7.00002Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M19 13.5C19 13.776 19.2235 14 19.5 14H24.5C24.7765 14 24.9999 13.776 24.9999 13.5C24.9999 13.224 24.7765 13 24.5 13H19.5C19.2235 13 19 13.224 19 13.5Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M19.5 11H22.5C22.7765 11 23 10.776 23 10.5C23 10.224 22.7765 10 22.5 10H19.5C19.2235 10 19 10.224 19 10.5C19 10.776 19.2235 11 19.5 11Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M23.5 16H19.5C19.2235 16 19 16.224 19 16.5C19 16.776 19.2235 17 19.5 17H23.5C23.7765 17 24 16.776 24 16.5C24 16.224 23.7765 16 23.5 16Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M15.5 29H10.5C10.2235 29 10 29.224 10 29.5C10 29.776 10.2235 30 10.5 30H15.5C15.7765 30 15.9999 29.776 15.9999 29.5C15.9999 29.2241 15.7765 29 15.5 29Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M19.5 29H18.5C18.2235 29 18 29.224 18 29.5C18 29.776 18.2235 30 18.5 30H19.5C19.7765 30 20 29.776 20 29.5C20 29.224 19.7765 29 19.5 29Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M10.8536 13.3537L11.0001 13.2072V16.5002C11.0001 16.7761 11.2236 17.0001 11.5001 17.0001H16.5001C16.7766 17.0001 17.0001 16.7761 17.0001 16.5002V13.2071L17.1466 13.3536C17.2441 13.4511 17.3721 13.5001 17.5001 13.5001C17.6281 13.5001 17.7561 13.4511 17.8536 13.3536C18.0491 13.1581 18.0491 12.8421 17.8536 12.6466L14.3536 9.14665C14.1581 8.95112 13.8421 8.95112 13.6466 9.14665L10.1466 12.6466C9.95112 12.8422 9.95112 13.1581 10.1466 13.3536C10.3421 13.5491 10.6582 13.5491 10.8536 13.3537ZM14.0001 10.2071L16.0632 12.2702C16.0266 12.3402 16.0002 12.4156 16.0002 12.5001V16.0001H14.5002V14.5001C14.5002 14.2241 14.2767 14.0001 14.0002 14.0001C13.7237 14.0001 13.5002 14.2241 13.5002 14.5001V16.0001H12.0002V12.5001C12.0002 12.4156 11.9737 12.3401 11.9372 12.2702L14.0001 10.2071Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M29.5 29H21.5C21.2235 29 21 29.224 21 29.5C21 29.776 21.2235 30 21.5 30H29.5C29.7765 30 29.9999 29.776 29.9999 29.5C29.9999 29.224 29.7765 29 29.5 29Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M9.49998 6C8.1215 6 6.99996 7.12148 6.99996 8.50002V18.5C6.99996 19.8785 8.12145 21.0001 9.49998 21.0001H25.5C26.8785 21.0001 28 19.8786 28 18.5V8.50002C28 7.12154 26.8785 6 25.5 6H25V4.00002H29.5C29.7765 4.00002 30 3.77602 30 3.50004C30 3.22406 29.7765 3.00006 29.5 3.00006H4.99998V1.5C4.99998 0.673008 4.32697 0 3.49998 0C2.67299 0 1.99998 0.673008 1.99998 1.5V29H0.49998C0.223477 29 0 29.224 0 29.5C0 29.7759 0.223477 30 0.49998 30H7.5C7.7765 30 7.99998 29.776 7.99998 29.5C7.99998 29.224 7.7765 29 7.5 29H4.99998V5.50002C4.99998 5.22404 4.7765 5.00004 4.5 5.00004C4.2235 5.00004 4.00002 5.22404 4.00002 5.50002V29H3V1.5C3 1.22449 3.224 1.00002 3.49998 1.00002C3.77596 1.00002 3.99996 1.22449 3.99996 1.5V3.49998C3.99996 3.77596 4.22344 3.99996 4.49994 3.99996H9.99996V6H9.49998ZM10.5 7.00002C10.7765 7.00002 11 6.77602 11 6.50004V4.00002H24V6.50004C24 6.77602 24.2235 7.00002 24.5 7.00002H25.5C26.327 7.00002 27 7.67303 27 8.50002V18.5C27 19.327 26.327 20 25.5 20H9.49998C8.67299 20 7.99998 19.327 7.99998 18.5V8.50002C7.99998 7.67303 8.67299 7.00002 9.49998 7.00002H10.5V7.00002Z"
                fill={props.color ?? "black"}
            />
        </svg>
    );
}
