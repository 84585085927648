import React from "react";
import styles from "pages/_css/pageRenderer.module.css";
import clsx from "clsx";
import dayjs from "dayjs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { IPageMdl, TLocalized } from "pages/_models/PageMdl";
import { BlogHeaderMobile } from "pages/blog/BlogHeaderMobile";

type Props = {
    localizedPage: TLocalized;
    page: IPageMdl;
    isOpen: boolean;
};
export function BlogHeader(props: Props) {
    const { t } = useTranslation();
    return (
        <>
            <div className={clsx("flex_row", styles.container)}>
                <div className={styles.placeholder} style={{ minWidth: props.isOpen ? 330 : 0 }} />
                <div className={clsx(styles.type, "ph_40 mt_20")}>{t("blogPage.blogTypes." + props.page.blogType)}</div>
            </div>
            <div className={clsx("flex_row_center", styles.container)}>
                <div className={styles.placeholder} style={{ minWidth: props.isOpen ? 330 : 0 }} />
                <div className={"flex_column_center mt_20"}>
                    <div className={clsx(styles.blogContainer, "flex_column alignItems_center flex-1")}>
                        <h1 className={clsx("textAlign_center", styles.title)}>{props.localizedPage.title}</h1>
                        <div className={clsx(styles.date, "pv_20 textAlign_center")}>
                            {dayjs(props.localizedPage.publishedAt)?.format("MMMM D, YYYY")}
                        </div>
                        {props.localizedPage.image && (
                            <div className={clsx("mb_40", styles.imgContainer)}>
                                <LazyLoadImage
                                    className={clsx(styles.image, "flex-1")}
                                    src={props.localizedPage.image}
                                    alt={props.localizedPage.title}
                                    title={props.localizedPage.title}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <BlogHeaderMobile localizedPage={props.localizedPage} page={props.page} />
        </>
    );
}
