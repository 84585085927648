import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { ListStore } from "_common/list/ListStore";
import { PROPERTY_STATUS, PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import sharedConfig from "_configs/sharedConfig";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { INVENTORY_STATUS } from "units/_models/UnitMdl";

export const DEFAULT_INITIAL_FILTERS: TFilter[] = [
    { id: "status", type: TFilterType.ENUM, value: INVENTORY_STATUS.AVAILABLE },
    { id: "published", type: TFilterType.BOOLEAN, value: true },
];

export enum FILTER_TYPE {
    propertyType = "propertyType",
    default = "default",
    inventoryStatus = "inventoryStatus",
}

export enum PROPERTY_FILTERS {
    SQUARE_SURFACE_MAX = "squareSurface.max",
    SQUARE_SURFACE_MIN = "squareSurface.min",
    BATHROOMS_MAX = "bathrooms.max",
    BATHROOMS_MIN = "bathrooms.min",
    BEDROOMS_MAX = "bedrooms.max",
    BEDROOMS_MIN = "bedrooms.min",
    PRICE_MAX = "price.max",
    PRICE_MIN = "price.min",
    TYPE = "type",
    LOCATION = "location",
    PURPOSE = "purpose",
    AMENITIES = "amenities",
    FEATURES = "features",
    DELIVERY_TYPE_START = "inventory.startDate",
    DELIVERY_TYPE_END = "inventory.endDate",
    STATUS = "inventory.status",
    UNIT_TYPE = "unitType",
    PUBLISHED = "published",
}

export enum FEATURES_OR_AMENITIES_PROPERTY_FOR_UNIT_FILTER {
    lockOffUnit = "features.lockOffUnit",
    privateEntrance = "features.privateEntrance",
    privateGarden = "features.privateGarden",
    privateJacuzzi = "features.privateJacuzzi",
    privatePool = "features.privatePool",
    privateTerrace = "features.privateTerrace",
    swimUp = "features.swimUp",
    unitOn3Floors = "features.unitOn3Floors",
    unitOn4Floors = "features.unitOn4Floors",
    unitOnTwoFloors = "features.unitOnTwoFloors",
    washerDryerInSuite = "features.washerDryerInSuite",
    furnished = "features.furnished",
}

export const UNIT_FILTERS: string[] = [
    PROPERTY_FILTERS.BATHROOMS_MAX,
    PROPERTY_FILTERS.BATHROOMS_MIN,
    PROPERTY_FILTERS.BEDROOMS_MAX,
    PROPERTY_FILTERS.BEDROOMS_MIN,
    PROPERTY_FILTERS.PRICE_MAX,
    PROPERTY_FILTERS.PRICE_MIN,
    PROPERTY_FILTERS.UNIT_TYPE,
    PROPERTY_FILTERS.SQUARE_SURFACE_MAX,
    PROPERTY_FILTERS.SQUARE_SURFACE_MIN,
    PROPERTY_FILTERS.PUBLISHED,
    "status",
    PROPERTY_FILTERS.DELIVERY_TYPE_START,
    PROPERTY_FILTERS.DELIVERY_TYPE_END,
].concat(Object.values(FEATURES_OR_AMENITIES_PROPERTY_FOR_UNIT_FILTER));

export function getAllTypesInAllLang() {
    const allLang = Object.keys(sharedConfig.languages);
    const allTypes = Object.values(PROPERTY_TYPE);
    const allPropertiesInAllLang: string[] = [];
    allLang.map((lang) =>
        allTypes.map((type) =>
            allPropertiesInAllLang.push(reformatStringForUrls(getI18nExpByLang(lang, `property.types.${type}`))),
        ),
    );
    return allPropertiesInAllLang;
}

export function isPropertyType(search: string) {
    const allPropertiesInAllLang: string[] = getAllTypesInAllLang();
    return allPropertiesInAllLang.includes(search);
}

export function hasPropertyFilterInUrl(
    url: string,
    pathname: string,
    propertyFilterNames: PROPERTY_FILTERS[],
    filterKey: string,
) {
    // This part is for property types filter without query string
    const params = pathname.split("/");
    const isPropertyTypeInUrl = !!Object.values(params).find((param) => isPropertyType(param));
    if (isPropertyTypeInUrl && filterKey === "type") {
        return true;
    }

    const searchParams = new URLSearchParams(url);
    const entries = searchParams.entries();
    let searchParam = entries.next();
    while (!searchParam.done) {
        if (
            propertyFilterNames.find((propertyFilterName) => {
                return (
                    searchParam.value[0].startsWith(propertyFilterName) ||
                    propertyFilterName.startsWith(searchParam.value[0]) ||
                    propertyFilterName.startsWith(searchParam.value[0].split(".")[0])
                );
            })
        ) {
            return true;
        }
        searchParam = entries.next();
    }
    return false;
}

export const updateSearchFilter = (
    listStore: ListStore<TPropertyListingMdl>,
    filters: TFilter[],
    reload = true,
    isDifferentGeoZone?: boolean,
): Promise<ListStore<TPropertyListingMdl>> => {
    return new Promise((resolve) => {
        const locationIndex = filters.findIndex((filter) => filter.id === "location");
        const newFiltersIds = filters.map((filter) => filter.id);
        listStore.updateFilters([
            ...listStore.filters
                .filter((filter) => {
                    if (locationIndex > -1) {
                        return filter.id !== "location";
                    }
                    return true;
                })
                .filter((filter) => !newFiltersIds.includes(filter.id)),
            ...filters.filter(
                (filter) =>
                    ((filter.id.startsWith("amenities") || filter.id.startsWith("features")) && filter.value) ||
                    !(filter.id.startsWith("amenities") || filter.id.startsWith("features")),
            ),
        ]);
        if (reload) listStore.reload(isDifferentGeoZone);
        resolve(listStore);
    });
};

export function getFiltersFromLatLng(lat: number, lng: number) {
    return [
        {
            id: "lat",
            type: TFilterType.NUMBER,
            value: lat,
        },
        {
            id: "lng",
            type: TFilterType.NUMBER,
            value: lng,
        },
    ];
}

export function getUrlSearchFromFilter(filterType = FILTER_TYPE.default, search: string, filter?: TFilter) {
    const searchParams = new URLSearchParams(search);
    switch (filterType) {
        case FILTER_TYPE.propertyType:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                }
                searchParams.set(filter.id, filter.value.join(","));
            }
            return searchParams.toString();
        case FILTER_TYPE.default:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                }
                if (filter.value === PROPERTY_STATUS.NONE) {
                    searchParams.delete("inventory.status");
                    searchParams.delete("inventory.startDate");
                    searchParams.delete("inventory.endDate");
                    return searchParams.toString();
                }
                searchParams.set(filter.id, filter.value.toString());
            }
            return searchParams.toString();
        default:
            return searchParams.toString();
    }
}

export function getUrlSearchFromFilters(filters: TFilter[]) {
    let search = "";
    filters.forEach((filter) => {
        switch (filter.id) {
            case PROPERTY_FILTERS.PURPOSE:
            case "status":
            case PROPERTY_FILTERS.PUBLISHED:
            case PROPERTY_FILTERS.STATUS:
            case PROPERTY_FILTERS.LOCATION:
                break;
            case PROPERTY_FILTERS.TYPE:
                search = getUrlSearchFromFilter(FILTER_TYPE.propertyType, search, filter);
                break;
            default:
                search = getUrlSearchFromFilter(FILTER_TYPE.default, search, filter);
        }
    });
    return search;
}

export function applyFilterFromFilters(_items: any[], filters: TFilter[]) {
    const queryFilter = [];
    filters.forEach((filter) => {
        switch (filter.type) {
            case TFilterType.NUMBER:
                queryFilter.push("");
        }
    });
}
