import React from "react";
import clsx from "clsx";
import styles from "./_css/propertyAddress.module.css";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";

export function PropertyAddress() {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();

    if (!propertyStore.property?.address) return null;
    return (
        <div className={clsx(styles.address, "flex-1 flex_column p_20")}>
            {propertyStore.property.localized.title && (
                <div className={clsx(styles.title, "mb_20")}>{propertyStore.property.localized.title}</div>
            )}
            <div className={styles.textAddress}>
                {propertyStore.property.address && propertyStore.property.address.neighbourhood && (
                    <div>{t("propertyPage.address.neighbourhood") + propertyStore.property.address.neighbourhood}</div>
                )}
                <div>{t("propertyPage.address.city") + propertyStore.property.address?.city}</div>
                <div>
                    {t("propertyPage.address.province")}
                    <span className={styles.state}>
                        {propertyStore.property.address?.province?.charAt(0).toUpperCase() +
                            propertyStore.property.address?.province?.slice(1).toLowerCase().split("_").join(" ")}
                    </span>
                </div>
                <div>
                    {t("propertyPage.address.country") +
                        t(`propertyPage.country.${propertyStore.property.address?.country ?? "MEX"}`)}
                </div>
            </div>
        </div>
    );
}
