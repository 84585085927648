import dayjs from "dayjs";
import _ from "lodash";
import sharedConfig from "_configs/sharedConfig";
import { TFilter } from "admin/_common/filters/TFilter";
import { IListProvider } from "_common/list/IListProvider";
import { ListStore } from "_common/list/ListStore";
import { getResourceInitialStateValue, putPromiseResourceResultInInitialState } from "_common/_utils/initialStateUtils";
import { fetchUtils, TFilesData } from "_common/_utils/fetchUtils";
import eventsStore from "main/events/eventsStore";
import { TObjWithId } from "_common/types/GenericTypes";
import { extendObservable, observable } from "mobx";

export const DEFAULT_PAGE_SIZE = 15;

export enum MAIN_RESOURCE {
    UNITS = "units",
    PROPERTIES = "properties",
}

export type TInitialState<TResource extends TObjWithId> = {
    items: TResource[];
    list: {
        [listName: string]: {
            pages: { [offset: number]: string[] };
            count: number;
            initialFilters?: TFilter[];
        };
    };
};

export abstract class BaseResourceStore<TResource extends TObjWithId> implements IListProvider<TResource> {
    listsStores: { [key: string]: ListStore<TResource> } = {};
    protected readonly name: string;
    protected readonly apiPath: string;
    @observable protected cache: { [key: string]: TResource | undefined } = {};

    protected constructor(name: string) {
        this.name = name;
        this.apiPath = sharedConfig.apiUrl + "/" + name;

        if (!__BROWSER__) {
            eventsStore.on("START_RENDER", () => {
                this.onReset();
            });
        } else {
            this.onInit(true);
        }
    }

    getListStore(
        listId = "default",
        pageSize = DEFAULT_PAGE_SIZE,
        initialFilters?: TFilter[],
        noInitialLoading?: boolean,
        forceReload = false,
        initialSort?: { [key: string]: number },
    ) {
        if (forceReload || !this.listsStores[listId]) {
            this.listsStores[listId] = new ListStore(
                listId,
                this,
                undefined,
                pageSize,
                noInitialLoading,
                initialFilters,
                initialSort,
            );
        }
        return this.listsStores[listId];
    }

    getSync(itemId: string) {
        return this.cache[itemId];
    }

    getAsync(itemId: string, force?: boolean): Promise<TResource | undefined> {
        const item = this.getSync(itemId);
        if (item && !force) {
            return new Promise<TResource>((resolve) => resolve(item));
        }
        return fetchUtils.get<TResource>(`${this.apiPath}/${itemId}`).then(({ data }) => this.addItem(data));
    }

    get(itemId: string) {
        return this.getSync(itemId) ?? this.getAsync(itemId);
    }

    findOneSync(key: string, value: any) {
        return Object.values(this.cache).find((item) => {
            return _.get(item, key) === value;
        });
    }

    findOneAsync(key: string, value: any) {
        const item = this.findOneSync(key, value);
        if (item) {
            return new Promise<TResource>((resolve) => resolve(item));
        }

        const promise = fetchUtils
            .post<TResource>(`${this.apiPath}/findOne`, { key, value })
            .then(({ data }) => this.addItem(data));
        putPromiseResourceResultInInitialState(this.name, promise);
        return promise;
    }

    findOne(key: string, value: any) {
        return this.findOneSync(key, value) ?? this.findOneAsync(key, value);
    }

    list(offset = 0, limit?: number, _listId?: string, sort?: { [key: string]: number }, filters?: TFilter[]) {
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        let filtersParam = "";
        if (filters) filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}?offset=${offset}&limit=${limit}${sortParam}${filtersParam}`;
        const promise = fetchUtils
            .get<{ count: number; items: TResource[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map((item) => {
                    const reformattedItem = this.reformatItem(item);
                    this.putItemInCache(reformattedItem);
                    return reformattedItem;
                }),
            }));
        return promise;
    }

    putItemInCache(item: TResource) {
        extendObservable(this.cache, { [item._id]: item });
    }

    getOne() {
        return Object.values(this.cache)[0];
    }

    create<TResource>(item: Omit<Partial<TResource>, "_id">, files?: TFilesData) {
        const body = files ? fetchUtils.createBodyWithFiles(item, files) : item;
        return fetchUtils.post<TResource>(this.apiPath, body, !!files).then(({ data }) => {
            const createdItem = this.addItem(data);
            if (!createdItem) return;
            for (const listId in this.listsStores) {
                this.listsStores[listId].onCreate(createdItem);
            }
            return createdItem;
        });
    }

    patch(item: Partial<TResource>, files?: TFilesData) {
        const body = files ? fetchUtils.createBodyWithFiles(item, files) : item;
        return fetchUtils
            .patch<TResource>(this.apiPath + "/" + item._id, body, !!files)
            .then(({ data }) => this.handleItemUpdated(data));
    }

    update(item: TResource, files?: TFilesData) {
        const body = files ? fetchUtils.createBodyWithFiles(item, files) : item;
        return fetchUtils
            .post<TResource>(this.apiPath + "/" + item._id, body, !!files)
            .then(({ data }) => this.handleItemUpdated(data));
    }

    delete(itemId: string) {
        return fetchUtils.delete(this.apiPath + "/" + itemId).then(() => {
            for (const listId in this.listsStores) {
                this.listsStores[listId].onDelete(itemId);
            }
            delete this.cache[itemId];
        });
    }

    async duplicate(itemId: string, _photosItemId?: string) {
        let item = await this.getAsync(itemId);
        if (!item) throw { key: "ITEM_NOT_FOUND" };
        item = { ...item };
        delete item._id;
        return this.duplicateItem(item);
    }

    protected duplicateItem(item: Omit<TResource, "_id">, files?: TFilesData) {
        if ((item as any)?.createdAt) delete (item as any).createdAt;
        if ((item as any)?.updatedAt) delete (item as any).updatedAt;
        return this.create(item, files);
    }

    protected handleItemUpdated(itemFromApi: TResource) {
        const updatedItem = this.addItem(itemFromApi);
        if (!updatedItem) return;
        for (const listId in this.listsStores) {
            this.listsStores[listId].onUpdate(updatedItem);
        }
        return updatedItem;
    }

    protected addItem(itemFromApi: TResource) {
        if (!itemFromApi || _.isEmpty(itemFromApi)) return;
        const reformattedItem = observable(this.reformatItem(itemFromApi));
        this.putItemInCache(reformattedItem);
        return reformattedItem;
    }

    protected reformatItem<TResource>(item: TResource) {
        const formattedItem = { ...item };
        if ((item as any).createdAt) (formattedItem as any).createdAt = dayjs((formattedItem as any).createdAt);
        if ((item as any).updatedAt) (formattedItem as any).updatedAt = dayjs((formattedItem as any).updatedAt);
        return formattedItem;
    }

    protected onReset() {
        this.cache = {};
        this.listsStores = {};
        this.onInit();
    }

    protected onInit(_fromRootCtor?: boolean) {
        const initialState = getResourceInitialStateValue(this.name) as TInitialState<TResource> | undefined;
        if (initialState) {
            initialState.items.map((item) => this.putItemInCache(this.reformatItem(item)));
            for (const listId in initialState.list ?? {}) {
                const pages: { [offset: number]: TResource[] } = {};
                for (const offset in initialState.list[listId].pages) {
                    pages[Number(offset)] = initialState.list[listId].pages[offset];
                }
                this.listsStores = {
                    ...this.listsStores,
                    [listId]: new ListStore<TResource>(
                        listId,
                        this,
                        {
                            count: initialState.list[listId].count,
                            pages,
                        },
                        undefined,
                        undefined,
                        initialState?.list[listId]?.initialFilters,
                    ),
                };
            }
        }
    }
}
