import React from "react";
import styles from "pages/_css/blogHeaderMobile.module.css";
import clsx from "clsx";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { IPageMdl, TLocalized } from "pages/_models/PageMdl";
import { ArticlesSearchBar } from "blog/ArticlesSearchBar";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    localizedPage: TLocalized;
    page: IPageMdl;
};
export function BlogHeaderMobile(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={"flex_row_center justifyContent_flexEnd  mt_20 mr_20"}>
                    <div className={clsx(styles.type, "ph_40")}>{t("blogPage.blogTypes." + props.page.blogType)}</div>
                </div>
                <div className={clsx("flex_column mt_20", styles.textContent)}>
                    <h2 className={clsx(styles.title, "ml_40")}>{props.localizedPage.title}</h2>
                    <div className={clsx(styles.date, "pb_40 ml_40")}>
                        {t("blogPage.published") + " " + dayjs(props.localizedPage.publishedAt)?.format("MMMM D, YYYY")}
                    </div>
                </div>
                <div className={"mh_30"}>
                    <ArticlesSearchBar />
                </div>
            </div>
            <LazyLoadImage
                className={clsx(styles.image, "flex-1")}
                src={props.localizedPage.image}
                alt={props.localizedPage.title}
                title={props.localizedPage.title}
            />
        </div>
    );
}
