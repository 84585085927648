import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { HomeCardList } from "home/HomeCardList";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";
import { PROPERTY_TYPES } from "_common/propertyTypes/propertyTypes";

export function HomeCardTypes() {
    const p = PROPERTY_TYPES; // no need
    propertiesBuyingStore.fetchSortedTypes();
    return (
        <LoadableFromLoading
            loading={propertiesBuyingStore.sortTypesState}
            renderer={(_status, error) => {
                if (error) return null;
                return <HomeCardList type={"propertyType"} items={propertiesBuyingStore.sortTypes} />;
            }}
        />
    );
}
