import React from "react";
import { VideoComponent } from "components/base/video/VideoComponent";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

export function VideosGalleryProperty() {
    const propertyStore = usePropertyStore();
    if (!propertyStore.property.previousProjectsVideos || !propertyStore.property.previousProjectsVideos.length) {
        return null;
    }
    return (
        <div>
            {propertyStore.property.previousProjectsVideos.map((video, idx) => {
                if (!video.url) return null;
                return <VideoComponent url={video.url} size={"100%"} key={video.url + idx} />;
            })}
        </div>
    );
}
