import React, { CSSProperties, useRef } from "react";
import clsx from "clsx";
import styles from "./_css/newsCardComponent.module.css";
import { TComponent } from "pages/_models/PageMdl";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { Link } from "react-router-dom";

export type NewsCardComponentProps = {
    date?: Dayjs | string;
    title?: string;
    text?: string;
    image?: string;
    featured?: boolean;
    url?: string;
    style?: CSSProperties;
    $component?: TComponent;
    blogType?: string;
};

export function NewsCardComponent(props: NewsCardComponentProps) {
    const mouseDownStart = useRef(0);
    const { t } = useTranslation();
    const lang = i18next.language;
    const date = typeof props.date === "string" ? dayjs(props.date) : props.date;
    return (
        <Link
            style={{ ...props.style, backgroundImage: `url(${props.image})` }}
            className={clsx(styles.container, "flex_column position_relative m_10", {
                [styles.featuredContainer]: props.featured,
            })}
            onMouseDown={() => (mouseDownStart.current = Date.now())}
            to={"/" + lang + props.url}
            // to={"/" + lang + "/" + t("routes.blogPage") + props.url}
        >
            {props.blogType && (
                <div className={clsx(styles.label, "position_absolute")}>
                    {" "}
                    {t("blogPage.blogTypes." + props.blogType)}
                </div>
            )}
            <div className={clsx(styles.innerContent, "position_absolute flex_column")}>
                <h2 className={clsx(styles.title, "ph_20 pt_20 fontWeight_bold")}>{props.title}</h2>
                {props.featured && (
                    <div className="ph_20">
                        <div className={styles.textContainer}>{props.text}</div>
                    </div>
                )}
                <div className="flex_row_center justifyContent_spaceBetween p_20">
                    <div>{date?.format("MMMM D, YYYY")}</div>
                    <div>
                        <UiButton className={styles.readMoreBtn}>{t("blogPage.readArticle")}</UiButton>
                    </div>
                </div>
            </div>
        </Link>
    );
}
