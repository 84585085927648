import React from "react";

type Props = {
    color?: string;
    size?: string;
};

export function IconBulletPoint(props: Props) {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_105_2)">
                <rect x="4" y="2" width="7" height="7" rx="3.5" fill="white" />
            </g>
            <defs>
                <filter
                    id="filter0_d_105_2"
                    x="0"
                    y="0"
                    width="15"
                    height="15"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_105_2" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_105_2" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
