import React, { lazy } from "react";
import { PropertiesView } from "properties/PropertiesView";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { PropertiesMeta } from "properties/PropertiesMeta";
import { getPageFromUrl } from "_common/_utils/pageUtils";
import Suspenser from "_common/loaders/Suspenser";
import { useHistory } from "react-router-dom";

const LazyPropertiesSearchBar = lazy(() => import("properties/searchBar/PropertiesSearchBar"));

export const PropertiesPage = () => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose, undefined, undefined, true);
    const history = useHistory();
    listStore.setSelectedPage(getPageFromUrl(history.location.pathname) ?? 1);
    if (!propertiesStore.purpose) return null;
    return (
        history.location && (
            <div style={{ marginTop: -30 }}>
                <PropertiesMeta />
                <div>
                    <Suspenser fallback={<div style={{ height: 67 }} />}>
                        <LazyPropertiesSearchBar listStore={listStore} />
                    </Suspenser>
                </div>
                <PropertiesView listStore={listStore} />
            </div>
        )
    );
};
