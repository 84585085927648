import { fetchUtils } from "_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import i18next from "i18next";
import { Recaptcha } from "components/misc/notifications/recaptcha";

class MarketingStore extends Recaptcha {
    private apiPath = sharedConfig.apiUrl + "/marketing";
    constructor() {
        super();
    }
    async subscribe(guest: { name: string; phone: string; email: string }) {
        const recaptcha = await this.getReCaptchaToken();
        return fetchUtils.post(this.apiPath + "/subscribe", { ...guest, lang: i18next.language, recaptcha });
    }
}

const marketingStore = new MarketingStore();
export { marketingStore };
