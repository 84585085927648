import React, { lazy } from "react";
import clsx from "clsx";
import styles from "./_css/propertiesView.module.css";
import { useTranslation } from "react-i18next";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";
import { useHistory, useLocation } from "react-router-dom";
import { settingsStore } from "settings/_stores/settingsStore";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { PropertiesViewList } from "properties/PropertiesViewList";
import i18next from "i18next";
import { URLS } from "_configs/URLS";
import { fetchSearchProperties, getNewsCoordinatesFromMapCoordinates } from "_common/_utils/searchUtils";
import Suspenser from "_common/loaders/Suspenser";
import { Loader } from "_common/loaders/Loader";

const LazyMapRenderLeaflet = lazy(() => import("../maps/MapRenderLeaflet"));

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export const PropertiesView = (props: Props) => {
    const propertiesStore = usePropertiesStore();
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    return (
        <div
            className={clsx("flex_row flex-1", styles.maps)}
            onClick={() => settingsStore.close()}
            onMouseLeave={() => propertiesStore.setMapPropertiesSelected(undefined)}
        >
            <div className={"flex_row flex-1 position_relative desktopDisplay"}>
                <Suspenser
                    fallback={
                        <div className={styles.map}>
                            <Loader className={styles.map} />
                        </div>
                    }
                >
                    <LazyMapRenderLeaflet listStore={props.listStore} />
                    {geoZonesStore.geoZone && (
                        <div className={clsx("position_absolute", styles.btnContainer)}>
                            <UiButton
                                onClick={async () => {
                                    geoZonesStore.resetGeoZone();
                                    propertiesStore.addressParams.city = "";
                                    propertiesStore.addressParams.address = "";
                                    propertiesStore.addressParams.region = "";
                                    history.push(
                                        URLS.buy(
                                            i18next.language,
                                            getNewsCoordinatesFromMapCoordinates(
                                                propertiesStore.searchParams.mapCoordinates,
                                            ),
                                        ) + location.search,
                                    );
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                    // @ts-ignore
                                    fetchSearchProperties(propertiesStore, props.listStore, undefined, true);
                                }}
                                variant={"contained"}
                                color={"primary"}
                            >
                                {t("zone.reset")}
                            </UiButton>
                        </div>
                    )}
                </Suspenser>
            </div>
            <div className={clsx("flex_column", styles.results)}>
                <PropertiesViewList listStore={props.listStore} />
            </div>
        </div>
    );
};
