import React, { lazy } from "react";
import { useTranslation } from "react-i18next";
import styles from "./_css/headerBlock.module.css";
import clsx from "clsx";
import { PREMIUM } from "properties/_models/PropertyMdl";
import Suspenser from "_common/loaders/Suspenser";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PremiumProperties } from "./PremiumProperties";

const LazySearchBar = lazy(() => import("components/sections/searchBlock/SearchBar"));

export function HeaderBlock() {
    const { t } = useTranslation();
    propertiesStore.fetchPremiumProperties(0, undefined, [
        {
            id: "premiumPlan",
            type: TFilterType.ENUM,
            value: PREMIUM.PROMO,
        },
    ]);

    return (
        <div className={"position_relative"}>
            <div className={styles.premiumProperties}>
                <LoadableFromLoading
                    loading={propertiesStore.featuredPropertiesState}
                    noSpinner
                    noText
                    renderer={(_status, _error, properties) => {
                        if (!properties) return null;
                        return <PremiumProperties properties={properties} />;
                    }}
                />
            </div>
            <div className={styles.container}>
                <div className={clsx(styles.content, "flex_column_center")}>
                    <div className={styles.titleBlock}>
                        <h1 className={clsx(styles.title)}>
                            {t("homePage.headerBlock.title1")} <br />{" "}
                            <span className={styles.mexico}>{t("homePage.headerBlock.title2")}</span>
                        </h1>
                    </div>
                    <Suspenser fallback={<div className={styles.searchBarPlaceholder} />}>
                        <LazySearchBar />
                    </Suspenser>
                </div>
            </div>
        </div>
    );
}
