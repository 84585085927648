import React, { lazy, Suspense } from "react";
import clsx from "clsx";
import styles from "./_css/propertyMapRender.module.css";
import { Loader } from "_common/loaders/Loader";

const LazyMapRenderLeaflet = lazy(() => import("./PropertyMap"));

export function PropertyMapRender() {
    return (
        <div>
            <div className={clsx(styles.mapContainer, "pt_40 textAlign_center")}>
                {__BROWSER__ ? (
                    <Suspense fallback={<Loader />}>
                        <LazyMapRenderLeaflet />
                    </Suspense>
                ) : (
                    <div>
                        <Loader />
                    </div>
                )}
            </div>
            <div className={clsx(styles.blueSection, "mt_40")} />
        </div>
    );
}
