import React from "react";
import styles from "./_css/labelType.module.css";
import clsx from "clsx";

type Props = {
    text: string;
};

export function LabelType(props: Props) {
    return <div className={clsx(styles.container, "flex_center_center")}>{props.text}</div>;
}
