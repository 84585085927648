import React from "react";

type Props = {
    color?: string;
    size?: string;
    backgroundColorFirst?: string;
    backgroundColorSecond?: string;
};

export function IconCompare(props: Props) {
    return (
        <svg
            width={props.size ?? "44"}
            height={props.size ?? "44"}
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M15.5998 6.5L10.7998 1.8999V6.5H15.5998Z" fill={props.backgroundColorFirst ?? "none"} />
            <path d="M25.4001 11.0999L20.6001 6.5V11.0999H25.4001Z" fill={props.backgroundColorSecond ?? "none"} />
            <path
                d="M16.1 20.5999V7H10.2V1.5H1.5V20.5H10.7H11.3H16.1V20.5999ZM9.59998 16.5L9.19995 16.0999L10.8 14.2998H5.89996V13.7H10.8L9.19995 11.8999L9.59998 11.5L11.8 14L9.59998 16.5Z"
                fill={props.backgroundColorFirst ?? "none"}
            />
            <path
                d="M19.9998 6.19995H16.0998L16.6998 6.69995V7.09985V17.3999L17.8998 16.0999L18.2998 16.3999L16.6998 18.2998H21.5998V18.7998H16.6998L18.2998 20.7L17.8998 21L16.6998 19.7V21.2H11.2998V25.2H25.8998V11.5999H19.9998V6.19995Z"
                fill={props.backgroundColorSecond ?? "none"}
            />
            <path
                d="M20.5 5.59985H20H15.5L10.7 1H10.2H1V21.2H10.8V25.7998H26.5V11.7V11.3999L20.5 5.59985ZM20.5 6.5L25.3 11.0999H20.5V6.5ZM10.7 1.8999L15.5 6.5H10.7V1.8999ZM1.5 20.5999V1.59985H10.2V7.09985H16.1V20.7H11.3H10.7H1.5V20.5999ZM25.9 25.2H11.3V21.2H16.6V19.7L17.8 21.0999L18.2 20.7L16.6 18.8999H21.5V18.2998H16.6L18.2 16.5L17.8 16.0999L16.6 17.5V7.09985V6.7998L16 6.19995H19.9V11.7H25.8V25.2H25.9Z"
                fill={props.color ?? "#52B5DB"}
                stroke={props.color ?? "#52B5DB"}
                strokeMiterlimit="10"
            />
            <path
                d="M9.49982 11.5L9.09979 11.7998L10.6998 13.7H5.7998V14.2H10.6998L9.09979 16.0999L9.49982 16.3999L11.7998 13.8999L9.49982 11.5Z"
                fill={props.color ?? "#52B5DB"}
                stroke={props.color ?? "#52B5DB"}
                strokeMiterlimit="10"
            />
        </svg>
    );
}
