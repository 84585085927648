import React, { useState } from "react";
import styles from "./_css/footer.module.css";
import { Container } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import canada from "../../assets/images/flags/canada.svg";
import mexico from "../../assets/images/flags/mexico.svg";
import { Input } from "_common/ui/forms/Input";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import phoneWhite from "../../assets/images/icons/phoneWhite.svg";
import email from "../../assets/images/icons/email.svg";
import facebook from "../../assets/images/networks/facebook.svg";
import whatsapp from "../../assets/images/networks/whatsapp.svg";
import instagram from "../../assets/images/networks/instagram.svg";
import linkedIn from "../../assets/images/networks/linkedIn.svg";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { settingsStore } from "settings/_stores/settingsStore";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { marketingStore } from "marketing/_stores/marketingStore";
import { isEmail } from "_common/_utils/alphaNumUtils";
import { FooterPopularSearches } from "main/footer/FooterPopularSearches";
import { RecaptchaDisclaimer } from "components/misc/notifications/RecaptchaDisclamer";
import i18next from "i18next";

export const FOOTER_REF: { current: HTMLDivElement | null } = { current: null };

export function Footer() {
    const { t } = useTranslation();
    const settings = settingsStore.getOne() as ISettingsMdl;

    const [formData, setFormData] = useState({ name: "", email: "", phone: "" });
    const lang = i18next.language;
    return (
        <div ref={(ref) => (FOOTER_REF.current = ref)} className={clsx(styles.container, "mt_20")}>
            <Container>
                <div className={clsx("flex_row", styles.innerContainer)}>
                    <div className={"flex-4 flex_column"}>
                        <div className={clsx("flex_row", styles.leftInner)}>
                            <div className={clsx("flex-3", styles.newsletter)}>
                                <div
                                    className={styles.newsletterTitle}
                                    dangerouslySetInnerHTML={{ __html: t("footer.newsletterTitle") }}
                                />
                                <div
                                    className={clsx("mt_20", styles.newsletterContent)}
                                    dangerouslySetInnerHTML={{
                                        __html: t("footer.newsletterContent"),
                                    }}
                                />
                            </div>
                            <div className={clsx("flex-2 flex_column ph_60", styles.form)}>
                                <Input
                                    name="firstName"
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    placeholder={t("footer.firstName")}
                                    className={styles.input}
                                />
                                <Input
                                    name="phone"
                                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                                    placeholder={t("footer.phone")}
                                    className={clsx(styles.input, "mt_20")}
                                />
                                <Input
                                    name="email"
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                    placeholder={t("footer.email")}
                                    className={clsx(styles.input, "mt_20")}
                                />
                                <UiButton
                                    onClick={() => {
                                        if (
                                            formData.email &&
                                            formData.name &&
                                            formData.phone &&
                                            isEmail(formData.email)
                                        ) {
                                            marketingStore.subscribe(formData).then(
                                                () => toast.success(t("footer.youVeBeenSubscribed")),
                                                () => toast.error(t("footer.error")),
                                            );
                                        } else {
                                            toast.error(t("footer.checkACFields"));
                                        }
                                    }}
                                    className={clsx(styles.button, "mt_20 mb_10")}
                                    variant={"contained"}
                                >
                                    {t("words.submit")}
                                </UiButton>
                                <RecaptchaDisclaimer color={"white"} small />
                                {settings?.contacts?.phone && (
                                    <div className={clsx("flex_row mt_30", styles.iconBlock)}>
                                        <LazyLoadImage src={phoneWhite} className={"mr_20"} />
                                        <a href={"tel:" + settings?.contacts?.phone}>{settings.contacts.phone}</a>
                                    </div>
                                )}
                                {settings?.contacts?.emailContact && (
                                    <div className={clsx("flex_row mt_20", styles.iconBlock)}>
                                        <LazyLoadImage src={email} className={"mr_20"} />
                                        <a href={"mailto:" + settings.contacts.emailContact}>
                                            {settings.contacts.emailContact}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={clsx("flex_row mt_40", styles.addresses)}>
                            {settings?.contacts?.address && (
                                <div className={"flex-3 flex_row"}>
                                    <div className={"mr_10"}>
                                        <LazyLoadImage src={canada} />
                                    </div>
                                    <div
                                        className={styles.address}
                                        dangerouslySetInnerHTML={{ __html: settings.contacts.address }}
                                    />
                                </div>
                            )}
                            {settings?.contacts?.mexicanAddress && (
                                <div className={"flex-2"}>
                                    <div className={"flex-1 flex_row"}>
                                        <div className={"mr_10"}>
                                            <LazyLoadImage src={mexico} />
                                        </div>
                                        <div
                                            className={clsx(styles.address, "pr_10")}
                                            dangerouslySetInnerHTML={{ __html: settings.contacts.mexicanAddress }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={clsx("flex-3 flex_column pl_60", styles.rightFooter)}>
                        <div className={clsx("flex_row flex-1", styles.rightInner)}>
                            <FooterPopularSearches />
                            <div className={"flex-2"}>
                                <div className={styles.rightTitle}>{t("footer.quickLinks")}</div>
                                <div className={"mt_40"}>
                                    <div className={clsx(styles.link, "mt_10")}>
                                        <Link to={URLS.policy(lang === "zh" ? "en" : lang)}>
                                            {t("footer.links.policy")}
                                        </Link>
                                    </div>
                                    <div className={clsx(styles.link, "mt_10")}>
                                        <Link to={URLS.terms(lang === "zh" ? "en" : lang)}>
                                            {t("footer.links.terms")}
                                        </Link>
                                    </div>
                                    <div className={clsx(styles.link, "mt_10")}>
                                        <Link to={URLS.faq(lang === "zh" ? "en" : lang)}>{t("footer.links.faq")}</Link>
                                    </div>
                                    <div className={clsx(styles.link, "mt_10")}>
                                        <Link to={URLS.contact()}>{t("footer.links.contactUs")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {settings?.links && (
                            <div className={"flex_column"}>
                                <div className={styles.networksTitle}>{t("footer.socialNetworks.title")}</div>
                                <div className={"flex_row mt_20"}>
                                    {settings.links.fb && (
                                        <div className="mr_40">
                                            <a href={settings.links.fb}>
                                                <LazyLoadImage src={facebook} />
                                            </a>
                                        </div>
                                    )}
                                    {settings.links.wa && (
                                        <div className="mr_40">
                                            <a href={settings.links.wa}>
                                                <LazyLoadImage src={whatsapp} />
                                            </a>
                                        </div>
                                    )}
                                    {settings.links.ig && (
                                        <div className="mr_40">
                                            <a href={settings.links.ig}>
                                                <LazyLoadImage src={instagram} />
                                            </a>
                                        </div>
                                    )}
                                    {settings.links.li && (
                                        <div className="mr_40">
                                            <a href={settings.links.li}>
                                                <LazyLoadImage src={linkedIn} />
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
}
