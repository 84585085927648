import { fetchUtils } from "shared/_common/_utils/fetchUtils";
import sharedConfig from "_configs/sharedConfig";
import { tokenStore } from "users/_stores/tokenStore";
import { GoogleLoginResponse } from "react-google-login";
import { ReactFacebookLoginInfo } from "react-facebook-login";

class AuthStore {
    signIn(email: string, password: string) {
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/signIn", {
                email,
                password,
            })
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    signUp(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        notification: { newsletter: boolean },
        phone: string,
    ) {
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/signUp", {
                email,
                password,
                firstName,
                lastName,
                notification,
                phone,
            })
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    signUpWithoutToken(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        notification: { newsletter: boolean },
        phone: string,
    ) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/signUp", {
            email,
            password,
            firstName,
            lastName,
            notification,
            phone,
        });
    }

    askResetPassword(email: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/askResetPassword", { email });
    }

    resetPassword(token: string, password: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/resetPassword", { token, password });
    }

    resetPasswordAsConnected(oldPassword: string, password: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/resetPasswordAsConnected", {
            password,
            oldPassword,
        });
    }

    resetPasswordAsAdmin(password: string, userId: string) {
        return fetchUtils.post<{ token: string }>(sharedConfig.apiUrl + "/auth/resetPasswordAsAdmin", {
            password,
            userId,
        });
    }

    signOut() {
        tokenStore.setToken(undefined);
        window.location.reload();
    }

    verify(search: string) {
        return fetchUtils.get<{ token: string }>(sharedConfig.apiUrl + "/auth/verify" + search);
    }

    googleAuth(user: GoogleLoginResponse) {
        const userData = {
            email: user.profileObj.email,
            firstName: user.profileObj.givenName,
            lastName: user.profileObj.familyName,
        };
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/googleAuth", userData)
            .then(({ data }) => tokenStore.setToken(data.token));
    }

    facebookAuth(user: ReactFacebookLoginInfo & { first_name: string; last_name: string }) {
        const userData = {
            email: user.email,
            firstName: user.first_name,
            lastName: user.last_name,
        };
        return fetchUtils
            .post<{ token: string }>(sharedConfig.apiUrl + "/auth/facebookAuth", userData)
            .then(({ data }) => tokenStore.setToken(data.token));
    }
}

const authStore = new AuthStore();
export { authStore };
