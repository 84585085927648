export type TSubject = {
    [lang in string]: string[];
};
export const Subject: TSubject = {
    fr: [
        "Problèmes avec la liste de ventes",
        "Problèmes avec la liste des locations",
        "Problèmes avec mes annonces de ventes",
        "Problèmes avec mes annonces de locations",
        "Problèmes avec mon compte Zisla",
        "Escroquerie ou fraude",
        "Se désabonner des e-mails",
        "Autres questions concernant mon profil",
    ],
    en: [
        "Problems with the sales list",
        "Problems with the rental list",
        "Problems with my sales listings",
        "Problems with my rental listings",
        "Problems with my Zisla account",
        "Scams or fraud",
        "Unsubscribing from emails",
        "Other questions about my profile",
    ],
    es: [
        "Problemas con la lista de ventas",
        "Problemas con la lista de alquiler",
        "Problemas con mis listados de ventas",
        "Problemas con mis anuncios de alquiler",
        "Problemas con mi cuenta de Zisla",
        "Estafas o fraudes",
        "Darse de baja de los correos electrónicos",
        "Otras preguntas sobre mi perfil",
    ],
    zh: [
        "Problems with the sales list",
        "Problems with the rental list",
        "Problems with my sales listings",
        "Problems with my rental listings",
        "Problems with my Zisla account",
        "Scams or fraud",
        "Unsubscribing from emails",
        "Other questions about my profile",
    ],
};
