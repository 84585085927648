import React, { lazy, useState } from "react";
import { observer } from "mobx-react";
import { Meta } from "_common/_utils/Meta";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { PropertiesSort } from "properties/PropertiesSort";
import clsx from "clsx";
import { usePropertiesPageMeta } from "properties/usePropertiesPageMeta";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { useTranslation } from "react-i18next";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { ListStorePaginator } from "admin/_common/list/ListStorePaginator";
import { URLS } from "_configs/URLS";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PropertiesListLoader } from "properties/PropertiesListLoader";
import { PropertyCard } from "./PropertyCard";
import styles from "./_css/propertiesViewList.module.css";
import { PropertiesListBreadcrumb } from "properties/PropertiesListBreadcrumb";
import { PropertiesLinks } from "properties/PropertiesLinks";
import { GEO_ZONE_TYPE } from "geoZones/_models/GeoZoneMdl";
import { removeInlineCssForMceText } from "_common/_utils/alphaNumUtils";
import { getUrlWithPagination } from "_common/_utils/pageUtils";
import { useHistory } from "react-router-dom";
import Suspenser from "_common/loaders/Suspenser";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

const LazyPropertiesChildGeoZones = lazy(() => import("properties/components/PropertiesChildGeoZones"));

export const PropertiesViewList = observer(({ listStore }: Props) => {
    const propertiesStore = usePropertiesStore();
    const history = useHistory();
    const [hidden, setHidden] = useState(true);
    const { t } = useTranslation();
    const { isGeoZonePage, isCityFromCitiesList, propertyType, cityForMeta } = useSearchPageParams();
    const { title, metaTitle, seoText, metaDescription } = usePropertiesPageMeta(propertiesStore, listStore);

    if (!listStore.isCurrentPageExisting) {
        listStore.setSelectedPage(1);
        history.push(getUrlWithPagination(history.location.pathname, 1) + history.location.search);
    }
    const noIndex = isGeoZonePage && !isCityFromCitiesList && !geoZonesStore.geoZone;
    const propertyTypeFilter = listStore.filters.find((filter) => filter.id === "type")?.value[0];
    return (
        <div className="ph_20">
            <Meta title={metaTitle} description={metaDescription} url={noIndex ? URLS.home() : undefined} />
            {!noIndex && (
                <h1
                    className={styles.title}
                    onClick={() => {
                        if (isGeoZonePage || propertyType) {
                            setHidden(!hidden);
                        }
                    }}
                >
                    {title}
                </h1>
            )}
            <PropertiesLinks propertyType={propertyTypeFilter} />
            <div
                className="mv_20"
                hidden={hidden}
                dangerouslySetInnerHTML={{ __html: removeInlineCssForMceText(seoText) }}
            />
            <div className="mt_10">
                <div className="ml_10 flex_row">
                    <div className="flex-1">
                        {listStore.count} {t("words.results")}
                        {(geoZonesStore.geoZone?.address.city ?? cityForMeta ?? propertiesStore.addressParams.city) && (
                            <span>
                                {" "}
                                {(geoZonesStore.geoZone?.address.city || cityForMeta) && (
                                    <span>
                                        {t("words.for")}{" "}
                                        {(geoZonesStore.geoZone?.type === GEO_ZONE_TYPE.NEIGHBORHOOD
                                            ? geoZonesStore.geoZone?.address.neighbourhood + ", "
                                            : "") + geoZonesStore.geoZone?.address.city ?? cityForMeta}
                                    </span>
                                )}
                            </span>
                        )}
                    </div>
                    <PropertiesSort listStore={listStore} />
                </div>
                <div>
                    <div className={clsx("flex_row flexWrap_wrap overflow_hidden")}>
                        {listStore.currentLoadingState ? (
                            <LoadableFromLoading
                                loading={listStore.currentLoadingState}
                                contentRender={<PropertiesListLoader />}
                                renderer={() => {
                                    return (
                                        <>
                                            {listStore.paginatedItems.map((property) => {
                                                if (!property) return null;
                                                return (
                                                    <PropertyCard
                                                        key={property._id}
                                                        property={property}
                                                        isPropertiesMap
                                                        onMouseOver={() => {
                                                            propertiesStore.setMapPropertiesSelected(property);
                                                        }}
                                                        onMouseLeave={() => {
                                                            propertiesStore.setMapPropertiesSelected.cancel();
                                                            propertiesStore.setMapPropertiesSelected(undefined);
                                                        }}
                                                    />
                                                );
                                            })}
                                        </>
                                    );
                                }}
                            />
                        ) : (
                            <>
                                {listStore.paginatedItems.map((property) => {
                                    if (!property) return null;
                                    return (
                                        <PropertyCard
                                            key={property._id}
                                            property={property}
                                            isPropertiesMap
                                            onMouseOver={() => {
                                                propertiesStore.setMapPropertiesSelected(property);
                                            }}
                                            onMouseLeave={() => {
                                                propertiesStore.setMapPropertiesSelected.cancel();
                                                propertiesStore.setMapPropertiesSelected(undefined);
                                            }}
                                        />
                                    );
                                })}
                            </>
                        )}
                    </div>
                    <div id={"PropertiesViewListPaginator"} className={"flex_center_center pv_10"}>
                        <ListStorePaginator
                            listStore={listStore}
                            shape={"rounded"}
                            variant={"outlined"}
                            color={"secondary"}
                        />
                    </div>
                    <div className={"flex_center_center pv_10"}>
                        <PropertiesListBreadcrumb />
                    </div>
                </div>
                {geoZonesStore.geoZone && geoZonesStore.geoZone.type !== GEO_ZONE_TYPE.NEIGHBORHOOD && (
                    <Suspenser noText noSpinner>
                        <LazyPropertiesChildGeoZones type={propertyTypeFilter} />
                    </Suspenser>
                )}
            </div>
        </div>
    );
});
