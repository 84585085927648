import { Button, ButtonProps } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import styles from "./_css/uiButton.module.css";

type Props = ButtonProps & {
    isDeleteButton?: boolean;
};

export const UiButton = ({ isDeleteButton, variant, ...props }: Props) => {
    return (
        <Button
            {...props}
            variant={variant}
            className={clsx(
                { [styles.containerForDelete]: isDeleteButton, [styles.container]: variant === "contained" },
                props.className,
            )}
            classes={{
                root: styles.root,
                label: styles.label,
                outlined: styles.outlined,
                sizeSmall: styles.sizeSmall,
                contained: styles.contained,
                disabled: styles.disabled,
            }}
        />
    );
};
