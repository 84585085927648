import { TDef } from "admin/pages/builder/props/values/ValueEditor";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";

export type TOptionConfigDef = TDef & {
    key: string;
    required: boolean;
    type: string;
    types?: PROPERTY_TYPE[];
    filter?: boolean;
    parking?: boolean;
    particularity?: boolean;
    max?: number;
};

export enum PROPERTY_OPTION {
    amphitheater = "amphitheater",
    artGallery = "artGallery",
    artWorkshop = "artWorkshop",
    bagageRoom = "bagageRoom",
    bar = "bar",
    bbqArea = "bbqArea",
    beachclub = "beachclub",
    bicycleParking = "bicycleParking",
    bicycles = "bicycles",
    businessCenter = "businessCenter",
    cabanas = "cabanas",
    cenote = "cenote",
    cinema = "cinema",
    climbingWall = "climbingWall",
    coffeeShop = "coffeeShop",
    commonLaundry = "commonLaundry",
    concierge = "concierge",
    dock = "dock",
    dogPark = "dogPark",
    electricVehicleChargingStation = "electricVehicleChargingStation",
    eventRoom = "eventRoom",
    firepit = "firepit",
    floatingRoom = "floatingRoom",
    garbageChute = "garbageChute",
    garden = "garden",
    gatedCommunity = "gatedCommunity",
    guestApartments = "guestApartments",
    gym = "gym",
    hammock = "hammock",
    hotelServices = "hotelServices",
    icePit = "icePit",
    intercom = "intercom",
    jacuzzi = "jacuzzi",
    joggingTrack = "joggingTrack",
    juiceOrganicBar = "juiceOrganicBar",
    kidsClub = "kidsClub",
    library = "library",
    lookoutTower = "lookoutTower",
    loungeArea = "loungeArea",
    marina = "marina",
    marketplace = "marketplace",
    massageRoom = "massageRoom",
    meditationRoom = "meditationRoom",
    musicRoom = "musicRoom",
    observationDeck = "observationDeck",
    outdoorGym = "outdoorGym",
    ownersLounge = "ownersLounge",
    paddleCourt = "paddleCourt",
    playgroundArea = "playgroundArea",
    pool = "pool",
    privateBeach = "privateBeach",
    readingArea = "readingArea",
    receptionDeskLobby = "receptionDeskLobby",
    receptionHall = "receptionHall",
    restaurant = "restaurant",
    sauna = "sauna",
    security = "security",
    shoppingCenter = "shoppingCenter",
    snackBar = "snackBar",
    soccerField = "soccerField",
    spa = "spa",
    storage = "storage",
    sundeck = "sundeck",
    temazcal = "temazcal",
    terrace = "terrace",
    walkingTrails = "walkingTrails",
    wetBar = "wetBar",
    yachtClub = "yachtClub",
    yogaLounge = "yogaLounge",
    zenDeck = "zenDeck",
    balcony = "balcony",
    bikePathAccess = "bikePathAccess",
    centralAirConditioner = "centralAirConditioner",
    composting = "composting",
    elevator = "elevator",
    leedCertification = "leedCertification",
    petFriendly = "petFriendly",
    securityCameras = "securityCameras",
    wallMountAirConditioner = "wallMountAirConditioner",
    mobilityAccess = "mobilityAccess",
    internet = "internet",
    appliances = "appliances",
    automatedBlinds = "automatedBlinds",
    blinds = "blinds",
    centralAirConditioning = "centralAirConditioning",
    controlledAccess = "controlledAccess",
    dehumidificationSystem = "dehumidificationSystem",
    furnishedEquipped = "furnishedEquipped",
    furniturePackagesAvailable = "furniturePackagesAvailable",
    ledLightning = "ledLightning",
    lockOffUnitsAvailable = "lockOffUnitsAvailable",
    muralAirConditioning = "muralAirConditioning",
    pluvialWaterSystem = "pluvialWaterSystem",
    recycling = "recycling",
    smartHome = "smartHome",
    solarPanels = "solarPanels",
    washerDryerInSuit = "washerDryerInSuit",
    wasteWaterTreatmentPlant = "wasteWaterTreatmentPlant",
    waterSoftenerSystem = "waterSoftenerSystem",
    covered = "covered",
    garage = "garage",
    outdoor = "outdoor",
    street = "street",
    underground = "underground",
    beachfront = "beachfront",
    closingInPesos = "closingInPesos",
    closingInMultipleCurrencies = "closingInMultipleCurrencies",
    rentalProgram = "rentalProgram",
    financingAvailable = "financingAvailable",
    mortgageCredit = "mortgageCredit",
    fiberInternet = "fiberInternet",
    commonAreaWifi = "commonAreaWifi",
    bitcoinAccepted = "bitcoinAccepted",
    longTermFinancing = "longTermFinancing",
    lowDown = "lowDown",
}

export enum UNIT_OPTION {
    lockOffUnit = "lockOffUnit",
    privateEntrance = "privateEntrance",
    privateGarden = "privateGarden",
    privateJacuzzi = "privateJacuzzi",
    privatePool = "privatePool",
    privateTerrace = "privateTerrace",
    swimUp = "swimUp",
    unitOn3Floors = "unitOn3Floors",
    unitOn4Floors = "unitOn4Floors",
    unitOnTwoFloors = "unitOnTwoFloors",
    washerDryerInSuite = "washerDryerInSuite",
    furnished = "furnished",
}

export const PROPERTY_AMENITIES: TOptionConfigDef[] = [
    { key: PROPERTY_OPTION.amphitheater, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.artGallery, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.artWorkshop, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.bagageRoom, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.bar, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.bbqArea, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.beachclub, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.bicycleParking, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.bicycles, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.businessCenter, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.cabanas, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.cenote, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.cinema, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.climbingWall, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.coffeeShop, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.commonLaundry, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.concierge, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.dock, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.dogPark, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.electricVehicleChargingStation, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.eventRoom, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.firepit, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.floatingRoom, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.garbageChute, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.garden, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.guestApartments, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.gym, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.hammock, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.hotelServices, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.icePit, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.jacuzzi, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.joggingTrack, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.juiceOrganicBar, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.kidsClub, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.library, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.lookoutTower, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.loungeArea, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.marina, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.marketplace, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.massageRoom, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.meditationRoom, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.musicRoom, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.observationDeck, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.outdoorGym, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.ownersLounge, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.paddleCourt, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.playgroundArea, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.pool, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.privateBeach, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.readingArea, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.receptionDeskLobby, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.receptionHall, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.restaurant, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.sauna, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.shoppingCenter, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.snackBar, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.soccerField, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.spa, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.storage, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.sundeck, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.temazcal, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.terrace, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.walkingTrails, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.wetBar, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.yachtClub, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.yogaLounge, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.zenDeck, required: false, type: "boolean" },
];

export const PROPERTY_FEATURES: TOptionConfigDef[] = [
    { key: PROPERTY_OPTION.balcony, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.composting, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.electricVehicleChargingStation, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.garbageChute, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.intercom, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.leedCertification, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.mobilityAccess, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.internet, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.automatedBlinds, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.blinds, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.controlledAccess, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.dehumidificationSystem, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.ledLightning, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.muralAirConditioning, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.pluvialWaterSystem, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.recycling, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.smartHome, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.solarPanels, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.wasteWaterTreatmentPlant, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.waterSoftenerSystem, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.security, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.fiberInternet, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.commonAreaWifi, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.washerDryerInSuit, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.lockOffUnitsAvailable, required: false, type: "boolean" },
    { key: PROPERTY_OPTION.furnishedEquipped, required: false, type: "boolean" },

    { key: PROPERTY_OPTION.elevator, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.gatedCommunity, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.petFriendly, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.securityCameras, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.appliances, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.centralAirConditioning, required: false, type: "boolean", filter: true },
    { key: PROPERTY_OPTION.furniturePackagesAvailable, required: false, type: "boolean", filter: true },

    { key: PROPERTY_OPTION.beachfront, required: false, type: "boolean", particularity: true },
    { key: PROPERTY_OPTION.closingInPesos, required: false, type: "boolean", particularity: true },
    { key: PROPERTY_OPTION.closingInMultipleCurrencies, required: false, type: "boolean", particularity: true },
    { key: PROPERTY_OPTION.rentalProgram, required: false, type: "boolean", particularity: true },
    { key: PROPERTY_OPTION.financingAvailable, required: false, type: "boolean", particularity: true },
    { key: PROPERTY_OPTION.mortgageCredit, required: false, type: "boolean", particularity: true },
    { key: PROPERTY_OPTION.bitcoinAccepted, required: false, type: "boolean", particularity: true },
    { key: PROPERTY_OPTION.longTermFinancing, required: false, type: "boolean", particularity: true },
    { key: PROPERTY_OPTION.lowDown, required: false, type: "boolean", particularity: true },

    { key: PROPERTY_OPTION.covered, required: false, type: "boolean", parking: true },
    { key: PROPERTY_OPTION.garage, required: false, type: "boolean", parking: true },
    { key: PROPERTY_OPTION.outdoor, required: false, type: "boolean", parking: true },
    { key: PROPERTY_OPTION.street, required: false, type: "boolean", parking: true },
    { key: PROPERTY_OPTION.underground, required: false, type: "boolean", parking: true },
];

export const UNIT_FEATURES: TOptionConfigDef[] = [
    { key: UNIT_OPTION.lockOffUnit, required: false, type: "boolean", filter: true },
    { key: UNIT_OPTION.privateEntrance, required: false, type: "boolean" },
    { key: UNIT_OPTION.privateGarden, required: false, type: "boolean" },
    { key: UNIT_OPTION.privateJacuzzi, required: false, type: "boolean" },
    { key: UNIT_OPTION.privatePool, required: false, type: "number", max: 10, filter: true },
    {
        key: UNIT_OPTION.privateTerrace,
        required: false,
        type: "number",
        max: 10,
        filter: true,
    },
    { key: UNIT_OPTION.unitOn3Floors, required: false, type: "boolean" },
    { key: UNIT_OPTION.swimUp, required: false, type: "boolean" },
    { key: UNIT_OPTION.unitOn4Floors, required: false, type: "boolean" },
    { key: UNIT_OPTION.unitOnTwoFloors, required: false, type: "boolean" },
    { key: UNIT_OPTION.washerDryerInSuite, required: false, type: "boolean", filter: true },
    { key: UNIT_OPTION.furnished, required: false, type: "boolean", filter: true },
];
