import React from "react";
import { TPropertyConstructProgress } from "properties/_models/PropertyConstructProgressMdl";
import styles from "./_css/PropertyGalleyConstructProgress.module.css";
import { VideoComponent } from "components/base/video/VideoComponent";
import dayjs from "dayjs";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    constructProgress: TPropertyConstructProgress[];
};

export function PropertyGalleyConstructProgress(props: Props) {
    return (
        <div className={styles.container}>
            {props.constructProgress
                .sort((a, b) => (a.date && b.date && dayjs(a.date).diff(dayjs(b.date)) > 0 ? -1 : 1))
                .map((progress) => {
                    return (
                        <div key={progress._id}>
                            <div className={clsx(styles.date, "pv_10 mh_10")}>
                                {dayjs(progress.date).format("MMMM YYYY")}
                            </div>
                            {progress?.videos?.length > 0 && (
                                <div>
                                    {progress.videos.map((video) => {
                                        return (
                                            <div key={video.url} className={"p_10"}>
                                                <VideoComponent url={video.url} />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {progress?.images?.length > 0 && (
                                <div className={"flex_row flexWrap_wrap"}>
                                    {progress.images.map((image) => {
                                        return (
                                            <div key={image._id} className={clsx(styles.images, "p_10")}>
                                                <LazyLoadImage src={image.url} alt={image?.alt} />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
        </div>
    );
}
