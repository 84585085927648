import React from "react";
import styles from "./_css/aboutUsBookConsultation.module.css";
import clsx from "clsx";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import phone from "../assets/images/aboutUs/phone.svg";
import whatsApp from "../assets/images/aboutUs/whatsApp.svg";
import email from "../assets/images/aboutUs/email.svg";
import getInTouch from "../assets/images/aboutUs/getInTouch.jpg";
import { settingsStore } from "settings/_stores/settingsStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { PHONE, WHATSAPP_PHONE } from "contact/ContactUsForm";
import { userStore } from "users/_stores/userStore";
import { AC_TAGS } from "users/_models/UserMdl";

export function AboutUsBookConsultation() {
    const { t } = useTranslation();
    const settings = settingsStore.getOne() as ISettingsMdl;
    if (!settings) return null;
    return (
        <div className={clsx("flex_row p_40 mt_100", styles.container)}>
            <Container>
                <div className={clsx(styles.content, "flex_row mt_40")}>
                    <div className={clsx("flex-3 pr_40", styles.leftSide)}>
                        <div className={styles.title}>{t("aboutUsPage.consultation.title")}</div>
                        <div
                            className={clsx(styles.text, "mt_40")}
                            dangerouslySetInnerHTML={{ __html: t("aboutUsPage.consultation.text") }}
                        />
                        <div className={clsx(styles.links, "flex_row mt_40")}>
                            <div className={"flex_row_center flex-1"}>
                                <LazyLoadImage src={phone} alt={"Phone"} title={"Phone"} />
                                <div className={"ml_20"}>
                                    <div>{t("aboutUsPage.consultation.phone")}</div>
                                    <a href={"tel:" + settings?.contacts?.phone ?? PHONE}>
                                        {settings?.contacts?.phone ?? PHONE}
                                    </a>
                                </div>
                            </div>
                            <div className={"flex_row_center flex-1"}>
                                <LazyLoadImage src={whatsApp} alt={"WhatsApp"} title={"WhatsApp"} />
                                <div className={"ml_20"}>
                                    <div>{t("aboutUsPage.consultation.whatsApp")}</div>
                                    <a href={"https://wa.me/5149325688"} target="_blank" rel="noreferrer">
                                        {WHATSAPP_PHONE}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={"flex_row mt_40"}>
                            <LazyLoadImage src={email} alt={"Email"} title={"Email"} />
                            <div className={"ml_20"}>
                                <a href={"mailto:" + settings.contacts.emailContact}>
                                    {settings.contacts.emailContact}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={clsx("flex-2 ml_40", styles.rightSide)}>
                        <div>
                            <LazyLoadImage className={styles.image} src={getInTouch} alt={"team"} title={"team"} />
                        </div>
                        <div className={"flex_row mt_40"}>
                            <UiButton
                                className={clsx("flex-1", styles.btn, styles.firstBtn)}
                                onClick={() => userStore.openAppointmentModalDisplayed()}
                            >
                                {t("aboutUsPage.consultation.schedule")}
                            </UiButton>
                        </div>
                        <div className={"flex_row mt_40"}>
                            <UiButton
                                className={clsx("flex-1", styles.btn, styles.secondBtn)}
                                onClick={() => {
                                    userStore.openContactModalDisplayed(AC_TAGS.L8_CONTACT_US_ABOUT_US);
                                }}
                            >
                                {t("aboutUsPage.consultation.contact")}
                            </UiButton>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
