import React from "react";
import { Container } from "@material-ui/core";
import { AboutUsHeader } from "aboutUs/AboutUsHeader";
import { AboutUsFounders } from "aboutUs/AboutUsFounders";
import { AboutUsOurValues } from "aboutUs/AboutUsOurValues";
import { AboutUsBookConsultation } from "aboutUs/AboutUsBookConsultation";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { Meta } from "_common/_utils/Meta";
import { URLS_ALTERNATES } from "_configs/URLS_ALTERNATES";

type Tmeta = {
    title: string;
    description: string;
    url: string;
    alternateUrls: { url: string; lang: TLang }[];
};

export function AboutUsPage() {
    const { t } = useTranslation();
    const history = useHistory();
    const meta: Tmeta = {
        title: i18next.t("aboutUsPage.meta.title"),
        description: i18next.t("aboutUsPage.meta.description"),
        url: sharedConfig.appUrl + history.location.pathname,
        alternateUrls: [],
    };
    for (const lang of Object.keys(sharedConfig.languages)) {
        meta.alternateUrls.push({ url: URLS_ALTERNATES.about(lang), lang: lang as TLang });
    }
    return (
        <>
            <Meta title={meta.title} url={meta.url} alternateUrls={meta.alternateUrls} description={meta.description} />
            <Container>
                <h1 className="mb_25" hidden>
                    {t("aboutUsPage.h1Title")}
                </h1>
                <AboutUsHeader />
                <AboutUsFounders />
                <AboutUsOurValues />
            </Container>
            <AboutUsBookConsultation />
        </>
    );
}
