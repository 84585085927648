import React, { useState } from "react";
import styles from "./_css/aboutUsFounders.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import shaheen from "../assets/images/founders/shaheen.jpg";
import yann from "../assets/images/founders/yann.jpg";
import david from "../assets/images/founders/david.jpg";
import { useHistory } from "react-router-dom";

export function AboutUsFounders() {
    const { t } = useTranslation();
    const [activeFounder, setActiveFounder] = useState<"shaheen" | "yann" | "david">("shaheen");
    const history = useHistory();
    return (
        <div className={"flex_column alignItems_center mt_40"}>
            <div className={styles.title}>{t("aboutUsPage.founders.title")}</div>
            <div className={clsx(styles.innerContainer, "mt_40 position_relative")}>
                <div className={clsx(styles.images, "position_absolute flex_column")}>
                    <LazyLoadImage
                        src={shaheen}
                        onClick={() => setActiveFounder("shaheen")}
                        className={clsx(styles.img, styles.imgShaheen, "cursor_pointer", {
                            [styles.active]: activeFounder === "shaheen",
                        })}
                    />
                    <LazyLoadImage
                        src={yann}
                        onClick={() => setActiveFounder("yann")}
                        className={clsx(styles.img, "cursor_pointer", {
                            [styles.active]: activeFounder === "yann",
                        })}
                    />
                    <LazyLoadImage
                        src={david}
                        onClick={() => setActiveFounder("david")}
                        className={clsx(styles.img, "cursor_pointer", {
                            [styles.active]: activeFounder === "david",
                        })}
                    />
                </div>
                <div className={styles.content}>
                    <div className={clsx(styles.shaheen, { [styles.textActive]: activeFounder === "shaheen" })}>
                        <div className={styles.founderTitle}>{t("aboutUsPage.founders.shaheen.title")}</div>
                        <div className={styles.founderSubTitle}>{t("aboutUsPage.founders.shaheen.subTitle")}</div>
                        <div
                            className={clsx(styles.founderText, "mt_20")}
                            dangerouslySetInnerHTML={{ __html: t("aboutUsPage.founders.shaheen.text") }}
                        />
                    </div>
                    <div className={clsx(styles.yann, { [styles.textActive]: activeFounder === "yann" })}>
                        <div className={styles.founderTitle}>{t("aboutUsPage.founders.yann.title")}</div>
                        <div className={styles.founderSubTitle}>{t("aboutUsPage.founders.yann.subTitle")}</div>
                        <div
                            className={clsx(styles.founderText, "mt_20")}
                            dangerouslySetInnerHTML={{ __html: t("aboutUsPage.founders.yann.text") }}
                        />
                    </div>
                    <div className={clsx(styles.david, { [styles.textActive]: activeFounder === "david" })}>
                        <div className={styles.founderTitle}>{t("aboutUsPage.founders.david.title")}</div>
                        <div className={styles.founderSubTitle}>{t("aboutUsPage.founders.david.subTitle")}</div>
                        <div
                            className={clsx(styles.founderText, "mt_20")}
                            dangerouslySetInnerHTML={{ __html: t("aboutUsPage.founders.david.text") }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
