import { action, computed, observable } from "mobx";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { unitsStore } from "units/_stores/unitsStore";
import { userStore } from "users/_stores/userStore";

class ComparisonStore {
    @observable properties: string[] = [];
    @observable units: string[] = [];
    @observable allPropertiesLoadedLoadingState: LoadingStateMdl<boolean> = new LoadingStateMdl<boolean>();
    @observable allUnitsLoadedLoadingState: LoadingStateMdl<boolean> = new LoadingStateMdl<boolean>();
    @observable propertyIdsLoadingState: { [key: string]: LoadingStateMdl<TPropertyListingMdl> } = {};
    @observable unitIdsLoadingState: { [key: string]: LoadingStateMdl<TUnitListingMdl> } = {};
    @observable overlayType: RESOURCE = RESOURCE.PROPERTIES;
    @observable overlayIsDisplayed = false;

    constructor() {
        if (__BROWSER__) {
            this.init();
        }
    }

    @action
    async fetchProperties() {
        if (!this.allPropertiesLoadedLoadingState.isLoading) {
            this.allPropertiesLoadedLoadingState.startLoading();
            let allPropertiesLoaded = true;
            for (const propertyId of this.properties) {
                if (!this.propertyIdsLoadingState?.[propertyId]) {
                    this.propertyIdsLoadingState[propertyId] = new LoadingStateMdl<TPropertyListingMdl>();
                }
                if (
                    !this.propertyIdsLoadingState[propertyId].isSucceeded &&
                    !this.propertyIdsLoadingState[propertyId].isLoading
                ) {
                    this.propertyIdsLoadingState[propertyId].startLoading();
                    const property = await propertiesStore.getAsync(propertyId);
                    if (!property) {
                        this.propertyIdsLoadingState?.[propertyId].setError({ key: "no results" });
                        allPropertiesLoaded = false;
                        break;
                    }
                    this.propertyIdsLoadingState?.[propertyId].setSuccess(property);
                }
            }
            if (allPropertiesLoaded) this.allPropertiesLoadedLoadingState.setSuccess(true);
            else this.allPropertiesLoadedLoadingState.setError({ key: "no results" });
        }
        return this.allPropertiesLoadedLoadingState;
    }

    @action
    async fetchUnits() {
        if (!this.allUnitsLoadedLoadingState.isLoading) {
            this.allUnitsLoadedLoadingState.startLoading();
            let allUnitsLoaded = true;
            for (const unitId of this.units) {
                if (!this.unitIdsLoadingState?.[unitId]) {
                    this.unitIdsLoadingState[unitId] = new LoadingStateMdl<TUnitListingMdl>();
                }
                if (!this.unitIdsLoadingState[unitId].isSucceeded && !this.unitIdsLoadingState[unitId].isLoading) {
                    this.unitIdsLoadingState[unitId].startLoading();
                    const unit = await unitsStore.getAsync(unitId);
                    if (!unit) {
                        this.unitIdsLoadingState?.[unitId].setError({ key: "no results" });
                        allUnitsLoaded = false;
                        break;
                    }
                    this.unitIdsLoadingState?.[unitId].setSuccess(unit);
                }
            }
            if (allUnitsLoaded) this.allUnitsLoadedLoadingState.setSuccess(true);
            else this.allUnitsLoadedLoadingState.setError({ key: "no results" });
        }
        return this.allUnitsLoadedLoadingState;
    }

    @computed get displayedPropertyAmenities() {
        const propertyAmenities: string[] = [];
        this.properties.map((propertyId) => {
            const property = propertiesStore.getSync(propertyId);
            if (property?.amenities) {
                Object.keys(property.amenities).forEach((amenity) =>
                    !propertyAmenities.includes(amenity) ? propertyAmenities.push(amenity) : undefined,
                );
            }
        });
        return propertyAmenities;
    }

    @computed get displayedPropertyFeatures() {
        const propertyFeatures: string[] = [];
        this.properties.map((propertyId) => {
            const item = propertiesStore.getSync(propertyId);
            if (item?.features) {
                Object.keys(item.features).forEach((feature) =>
                    !propertyFeatures.includes(feature) ? propertyFeatures.push(feature) : undefined,
                );
            }
        });
        return propertyFeatures;
    }

    @computed get displayedUnitFeatures() {
        const unitFeatures: string[] = [];
        this.properties.map((unitId) => {
            const item = propertiesStore.getSync(unitId);
            if (item?.features) {
                Object.keys(item.features).forEach((feature) =>
                    !unitFeatures.includes(feature) ? unitFeatures.push(feature) : undefined,
                );
            }
        });
        return unitFeatures;
    }

    @action addItem(type: RESOURCE, itemId: string) {
        switch (type) {
            case RESOURCE.UNITS:
                if (this.units.includes(itemId)) return;
                this.units.push(itemId);
                this.overlayType = RESOURCE.UNITS;
                break;
            case RESOURCE.PROPERTIES:
                if (this.properties.includes(itemId)) return;
                this.properties.push(itemId);
                this.overlayType = RESOURCE.PROPERTIES;
                break;
        }
        this.save();
    }

    @action deleteItem(type: RESOURCE, itemId: string) {
        switch (type) {
            case RESOURCE.UNITS:
                if (!this.units.includes(itemId)) return;
                this.units.splice(this.units.indexOf(itemId), 1);
                this.units.length === 0
                    ? (this.overlayType = RESOURCE.PROPERTIES)
                    : (this.overlayType = RESOURCE.UNITS);
                break;
            case RESOURCE.PROPERTIES:
                if (!this.properties.includes(itemId)) return;
                this.properties.splice(this.properties.indexOf(itemId), 1);
                this.properties.length === 0
                    ? (this.overlayType = RESOURCE.UNITS)
                    : (this.overlayType = RESOURCE.PROPERTIES);
                break;
        }
        this.save();
    }

    @action deleteItems(type: RESOURCE) {
        switch (type) {
            case RESOURCE.UNITS:
                this.units = [];
                break;
            case RESOURCE.PROPERTIES:
                this.properties = [];
                break;
        }
        this.save();
    }

    @action setItemTypeOverlay(type: RESOURCE) {
        return (this.overlayType = type);
    }

    @action setIsDisplayedOverlay(display: boolean) {
        this.overlayIsDisplayed = display;
    }

    @action
    compareAllFavorites() {
        if (userStore.favoritesPropertiesStates.isSucceeded) {
            userStore.favoritesProperties.forEach((property) => this.addItem(RESOURCE.PROPERTIES, property._id));
        }
        if (userStore.favoritesUnitsStates.isSucceeded) {
            userStore.favoritesUnits.forEach((unit) => this.addItem(RESOURCE.UNITS, unit._id));
        }
    }

    private save() {
        if (localStorage.getItem("compareItems")) localStorage.removeItem("compareItems");
        localStorage.setItem("compareItems", JSON.stringify({ properties: this.properties, units: this.units }));
    }

    private init() {
        const itemsString = localStorage.getItem("compareItems");
        if (itemsString) {
            const items = JSON.parse(itemsString);
            this.properties = items.properties;
            this.units = items.units;
        }
    }
}

const comparisonStore = new ComparisonStore();
export { comparisonStore };
