import React from "react";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./_css/dialog.module.css";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import { Dialog as MuiDialog } from "@material-ui/core";

type Props = Omit<DialogProps, "onClose"> & {
    onClose: () => void;
    closeCross?: boolean;
    closeSize?: "inherit" | "default" | "small" | "large";
    withoutPlaceholder?: boolean;
    isTransparentBg?: boolean;
};

export function Dialog({ closeCross, closeSize, withoutPlaceholder, ...props }: Props) {
    return (
        <MuiDialog {...props}>
            {closeCross && (
                <div
                    onClick={() => props.onClose?.()}
                    className={clsx(styles.cross, "flex_row p_10 justifyContent_flexEnd cursor_pointer", {
                        [styles.p_25]: closeSize === "large",
                    })}
                >
                    <CloseIcon
                        fontSize={closeSize ? closeSize : "default"}
                        color={props.isTransparentBg ? "secondary" : "inherit"}
                    />
                </div>
            )}
            {closeCross && !withoutPlaceholder && (
                <div
                    className={clsx(styles.crossPlaceHolderSmall, {
                        [styles.crossPlaceHolderLarge]: closeSize === "large",
                        [styles.transparentBg]: props.isTransparentBg,
                    })}
                />
            )}
            <div>{props.children}</div>
        </MuiDialog>
    );
}
