import React from "react";

type Props = {
    color?: string;
    size?: string;
};

export function IconTerraceArea(props: Props) {
    return (
        <svg
            width={props.size ?? "60"}
            height={props.size ?? "60"}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.015 43.0328H4.50301V24.6204C4.50301 24.0223 4.01809 23.5374 3.41996 23.5374C2.82184 23.5374 2.33691 24.0223 2.33691 24.6204V44.1158C2.33691 44.714 2.82184 45.1989 3.41996 45.1989H3.74492V58.1944C3.74492 58.7925 4.22984 59.2774 4.82797 59.2774C5.42609 59.2774 5.91101 58.7925 5.91101 58.1944V50.0713H16.5237V58.1944C16.5237 58.7925 17.0087 59.2774 17.6068 59.2774C18.2049 59.2774 18.6898 58.7925 18.6898 58.1944V45.199H19.0148C19.6129 45.199 20.0978 44.7141 20.0978 44.1159C20.098 43.5177 19.6132 43.0328 19.015 43.0328ZM16.524 47.9054H5.91113V45.1991H16.5239V47.9054H16.524Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M56.5809 23.5374C55.9828 23.5374 55.4979 24.0223 55.4979 24.6204V43.0328H40.9844C40.3863 43.0328 39.9014 43.5177 39.9014 44.1158C39.9014 44.714 40.3863 45.1989 40.9844 45.1989H41.3094V58.1944C41.3094 58.7925 41.7943 59.2774 42.3924 59.2774C42.9905 59.2774 43.4755 58.7925 43.4755 58.1944V50.0713H54.0897V58.1944C54.0897 58.7925 54.5747 59.2774 55.1728 59.2774C55.7709 59.2774 56.2558 58.7925 56.2558 58.1944V45.199H56.5808C57.1789 45.199 57.6638 44.7141 57.6638 44.1159V24.6204C57.6639 24.0223 57.1791 23.5374 56.5809 23.5374ZM54.0897 47.9054H43.4756V45.1991H54.0898V47.9054H54.0897Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M46.226 32.7078H31.0836V18.0516C31.0836 17.4534 30.5988 16.9685 30.0006 16.9685C29.4025 16.9685 28.9175 17.4534 28.9175 18.0516V32.7078H13.7745C13.1763 32.7078 12.6914 33.1928 12.6914 33.7909C12.6914 34.389 13.1763 34.8739 13.7745 34.8739H14.837V37.5484C14.837 38.1465 15.3219 38.6314 15.92 38.6314H44.0802C44.6783 38.6314 45.1632 38.1465 45.1632 37.5484V34.8739H46.226C46.8241 34.8739 47.3091 34.389 47.3091 33.7909C47.3092 33.1928 46.8241 32.7078 46.226 32.7078ZM42.9971 36.4652H17.0032V34.8738H42.9971V36.4652Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M34.9627 57.1118H31.0839V41.1585C31.0839 40.5604 30.599 40.0754 30.0009 40.0754C29.4028 40.0754 28.9179 40.5604 28.9179 41.1585V57.1117H25.0381C24.44 57.1117 23.9551 57.5966 23.9551 58.1947C23.9551 58.7929 24.44 59.2778 25.0381 59.2778H34.9627C35.5609 59.2778 36.0458 58.7929 36.0458 58.1947C36.0458 57.5966 35.5609 57.1118 34.9627 57.1118Z"
                fill={props.color ?? "black"}
            />
            <path
                d="M59.601 13.6008C51.5565 7.04701 41.4502 3.38678 31.083 3.25529V1.80521C31.083 1.20709 30.5981 0.722168 30 0.722168C29.4019 0.722168 28.9169 1.20709 28.9169 1.80521V3.25541C18.5498 3.38689 8.44346 7.04724 0.399008 13.6011C0.0438123 13.8905 -0.0908362 14.372 0.0626795 14.8039C0.216195 15.2355 0.624828 15.5239 1.08315 15.5239H43.7532C43.7539 15.5239 43.7546 15.524 43.7553 15.524C43.7561 15.524 43.7568 15.5239 43.7575 15.5239H58.9167C59.3749 15.5239 59.7834 15.2355 59.9371 14.8039C60.0908 14.3719 59.9563 13.8903 59.601 13.6008ZM4.31248 13.3576C9.58932 9.6665 15.6275 7.18166 21.9467 6.07365C19.6662 7.77873 17.5289 10.2299 15.6308 13.3576H4.31248ZM18.1949 13.3576C21.6053 8.21935 25.6657 5.41529 29.7596 5.41529H30.2403C34.3343 5.41529 38.3945 8.21935 41.8051 13.3576H18.1949ZM44.3692 13.3576C42.4711 10.2299 40.3337 7.77861 38.0532 6.07365C44.3725 7.18166 50.4108 9.66662 55.6875 13.3576H44.3692Z"
                fill={props.color ?? "black"}
            />
        </svg>
    );
}
