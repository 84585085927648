import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "@material-ui/core";
import clsx from "clsx";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import styles from "./_css/propertyOrUnitDescription.module.css";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";

type Props = {
    description?: string;
    resource: RESOURCE;
};
export function PropertyOrUnitDescription(props: Props) {
    const { t } = useTranslation();
    let height = 0;
    const ref = useRef<HTMLDivElement>(null);
    const [isCollapsed, setIsCollapsed] = useState(height > 130);

    useEffect(() => {
        if (ref.current) {
            height = ref.current.offsetHeight;
        }
        setIsCollapsed(height > 130);
    }, [ref?.current]);
    if (!props.description) return null;
    return (
        <div className={"mt_40 ph_20 flex_column"}>
            <h3 className={styles.descTitle}>
                {props.resource === RESOURCE.PROPERTIES
                    ? t("propertyPage.header.propertyDescription")
                    : t("unitPage.header.unitDescription")}
            </h3>
            <Collapse in={!isCollapsed} collapsedHeight={130}>
                <div
                    dangerouslySetInnerHTML={{ __html: props.description }}
                    className={clsx("pr_20", styles.desc)}
                    ref={ref}
                />
            </Collapse>
            <div
                className={"textAlign_center flex_center_center cursor_pointer mt_10"}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                {height < 130 && isCollapsed && (
                    <>
                        {t("propertyPage.seeMore")}
                        <KeyboardArrowDown />
                    </>
                )}
            </div>
        </div>
    );
}
