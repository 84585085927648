import React, { CSSProperties, useState } from "react";
import clsx from "clsx";
import { ZoomIn } from "@material-ui/icons";
import styles from "./_css/cardComponent.module.css";
import { TComponent } from "pages/_models/PageMdl";
import { CardTitle } from "components/base/card/CardTitle";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ExternalOrNavLink } from "_common/ui/links/ExternalOrNavLink";
import { useForceRefreshPage } from "_common/_utils/hookUtils";

type Props = {
    title?: string;
    text?: string;
    titleHeader?: "h2" | "h3" | "h4";
    image?: string;
    url?: string;
    style?: CSSProperties;
    $component: TComponent;
};

export function CardComponent(props: Props) {
    const [isOverCard, setIsOverCard] = useState(false);
    const { isBuyingOrIsRentingUrl, refreshPage } = useForceRefreshPage(props.url ? props.url : "#");
    return (
        <ExternalOrNavLink
            onClick={(e) => {
                e.preventDefault();
                if (props.url && isBuyingOrIsRentingUrl) {
                    refreshPage();
                }
            }}
            url={props.url}
        >
            <div
                className={clsx(styles.container, "mb_40")}
                onMouseEnter={() => setIsOverCard(true)}
                onMouseLeave={() => setIsOverCard(false)}
            >
                <div className={clsx(styles.bgImage, "flex_center_center")}>
                    <LazyLoadImage
                        className={styles.image}
                        src={props.image}
                        style={{ opacity: isOverCard ? 0.5 : 1 }}
                        alt=""
                    />
                    {isOverCard && (
                        <div className={styles.icon}>
                            <ZoomIn style={{ width: 100, height: 100, opacity: 1 }} color={"primary"} />
                        </div>
                    )}
                </div>
                {props.title && (
                    <div className={"flex_row pt_20 ph_20"}>
                        <CardTitle title={props.title} titleHeader={props.titleHeader} />
                    </div>
                )}
                {props.text && <div className={clsx(styles.resume, "flex_row p_20")}>{props.text}</div>}
            </div>
        </ExternalOrNavLink>
    );
}
