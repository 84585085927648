import React from "react";

type Props = {
    color?: string;
    size?: string;
    isFavorite?: boolean;
};

export function IconFavorite(props: Props) {
    return (
        <svg
            width={props.size ?? "44"}
            height={props.size ?? "44"}
            viewBox="0 0 44 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.9999 38.6998C41.9999 27.8998 42.0999 14.6998 41.9999 13.3998V13.2998C41.9999 7.2998 37.6999 1.7998 31.1999 1.7998C25.9999 1.7998 22.9999 5.49982 21.9999 8.99982C20.9999 5.49982 17.9999 1.7998 12.7999 1.7998C6.29988 1.7998 1.99988 7.2998 1.99988 13.2998V13.3998C1.89988 14.6998 1.99988 27.8998 21.9999 38.6998Z"
                fill={props.isFavorite ? props.color ?? "black" : "none"}
                stroke={props.color ?? "black"}
                strokeWidth="2.5"
                strokeMiterlimit="10"
            />
        </svg>
    );
}
