import React from "react";
import styles from "./_css/propertyGalleryCta.module.css";
import { Input } from "_common/ui/forms/Input";
import { TextArea } from "_common/ui/forms/TextArea";
import clsx from "clsx";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { PropertyFormBtn } from "properties/propertyPage/PropertyFormBtn";
import { AC_TAGS } from "users/_models/UserMdl";
import { BLOG_TYPE } from "pages/_models/PageMdl";
import { InputPhone } from "_common/ui/forms/InputPhone";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { HiddenFieldsAttributerForm } from "components/form/HiddenFieldsAttributerForm";

export type TAskForVisitOrInfoForm = {
    name: string;
    phone: string;
    email: string;
    subject: string;
    visitedDate: Dayjs;
    message?: string;
    propertyId?: string;
    inquiry?: string;
    unitId?: string;
    acTags?: AC_TAGS;
    blogType?: BLOG_TYPE;
    lang?: string;
    url?: string;
    unitNumber?: string;
    attributerChannel: string;
    attributerChanneldrilldown1: string;
    attributerChanneldrilldown2: string;
    attributerChanneldrilldown3: string;
    attributerChanneldrilldown4: string;
    attributerLandingpage: string;
    attributerLandingpagegroup: string;
};

export function PropertyGalleryCta() {
    const form = useForm<TAskForVisitOrInfoForm>({
        mode: "onBlur",
        defaultValues: {
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            visitedDate: dayjs(),
        },
    });
    const { t } = useTranslation();

    return (
        <FormProvider {...form}>
            <div className={clsx(styles.container, "flex_column_center ml_10 p_10 mt_0")}>
                <h2 className={clsx(styles.title, " textAlign_center mt_10 mb_20")}>{t("propertyPage.cta.learn")}</h2>
                <div className={clsx("mt_10", styles.inputContainer)}>
                    <Controller
                        as={Input}
                        control={form.control}
                        placeholder={t("words.names")}
                        name={"name"}
                        className={styles.input}
                    />
                    <Controller
                        as={Input}
                        control={form.control}
                        placeholder={t("words.email")}
                        name={"email"}
                        rules={{
                            required: t<string>("errors.forms.required"),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: t("errors.forms.emailInvalid"),
                            },
                        }}
                        className={styles.input}
                    />
                    <Controller
                        as={InputPhone}
                        control={form.control}
                        placeholder={t("words.phone")}
                        name={"phone"}
                        className={styles.input}
                    />
                    <div className={styles.inputContainer}>
                        <Controller
                            name={"message"}
                            rows={10}
                            as={TextArea}
                            placeholder={"Message"}
                            control={form.control}
                            className={clsx(styles.input)}
                        />
                    </div>
                </div>
                <div className={clsx("mt_20 flex_column_center", styles.btnContainer)}>
                    <PropertyFormBtn resource={RESOURCE.PROPERTIES} />
                </div>
                <div className={clsx(styles.text, " textAlign_center mt_20")}>{t("propertyPage.cta.rules")}</div>
            </div>
            <HiddenFieldsAttributerForm />
        </FormProvider>
    );
}
