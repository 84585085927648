import React, { ReactNode } from "react";
import styles from "./_css/input.module.css";
import clsx from "clsx";
import { FieldError } from "react-hook-form";

export type TInputProps<T = string> = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    error?: FieldError;
    onValueChange?: (value: T, event?: React.ChangeEvent<HTMLInputElement>) => void;
    endIcon?: ReactNode;
    theme?: "white" | "gray" | "label";
    style?: any;
    hidden?: boolean;
    endIconClassName?: string;
    fullWidth?: boolean;
};

export const Input = React.forwardRef<HTMLInputElement, TInputProps>(
    ({ hidden, error, className, endIcon, endIconClassName, onValueChange, onChange, fullWidth, ...props }, ref) => {
        return (
            <>
                <div
                    style={props.style}
                    className={clsx(styles.container, {
                        ["hidden"]: hidden,
                        [styles.fullWith]: fullWidth,
                    })}
                >
                    <input
                        {...props}
                        className={clsx(
                            styles.input,
                            styles.input_default,
                            {
                                [styles.inputWithEndIcon]: !!endIcon,
                                [styles.input_label]: props.theme === "label",
                                [styles.input_white]: props.theme === "white",
                                [styles.input_gray]: props.theme === "gray" || props.disabled,
                                [styles.input_error]: error,
                            },
                            className,
                        )}
                        ref={ref}
                        onChange={(event) => {
                            if (onChange) onChange(event);
                            if (onValueChange) onValueChange(event.target.value);
                        }}
                        onWheel={(e) => e.target.blur()}
                    />
                    {endIcon && <div className={clsx(styles.endIcon, endIconClassName)}>{endIcon}</div>}
                </div>
                {error && <div className={styles.error}>{error.message}</div>}
            </>
        );
    },
);
