import React from "react";
import { Meta } from "_common/_utils/Meta";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { formatPrice } from "_common/_utils/currencyUtils";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { URLS_ALTERNATES } from "_configs/URLS_ALTERNATES";

type Props = {
    property: TPropertyListingMdl;
    unit: TUnitListingMdl;
};

export function UnitMeta(props: Props) {
    const { t } = useTranslation();

    const metaTitle = t("unitPage.meta.title", {
        title: props.unit.localized.title,
        purpose: t(`unitPage.meta.purpose.${props.property.purpose}`),
        city: props.property.address.city,
        province: props.property.address.province,
    });

    const description = t("unitPage.meta.description", {
        purpose: t(`unitPage.meta.purpose.${props.property.purpose}`),
        address: `${props.property.address.city} ${props.property.address.neighbourhood ?? ""} ${
            props.property.address.postalCode
        }
                ${props.property.address.province}`,
        price: formatPrice(props.property.price.min),
        surface: t(`propertiesPage.surface`, { surface: props.unit.squareSurface?.min }) + ".",
        roomsNumber: props.unit.bedrooms > 0 ? t(`unit.bedroom`, { count: props.unit.bedrooms }) + "." : "",
        bathsNumber: props.unit.bathrooms > 0 ? t(`unit.bathroom`, { count: props.unit.bathrooms }) : "",
        propertyType: t(`property.types.${props.property.type}`),
        metaDescription:
            props.property.localized.meta?.title && props.property.localized.meta.title !== ""
                ? props.property.localized.meta?.description
                : t("meta.description"),
    });

    const title =
        props.property.localized.meta?.title && props.property.localized.meta.title !== ""
            ? props.property.localized.meta.title
            : metaTitle;
    const image = props.property.localized.meta?.image ?? props.property?.photos?.[0]?.url;
    const url = URLS.property(props.property.localized.urlAlias);
    const alternateUrls = [];

    for (const lang of Object.keys(sharedConfig.languages)) {
        alternateUrls.push({
            url: URLS_ALTERNATES.unit(
                props.property?.urlAlias?.[lang] ?? props.property.localized.urlAlias,
                props.unit.urlAlias?.[lang],
                lang,
            ),
            lang: lang as TLang,
        });
    }

    return (
        <Meta title={title} description={description} image={image} url={url} alternateUrls={alternateUrls} noIndex />
    );
}
