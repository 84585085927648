import React from "react";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContactUsForm } from "contact/ContactUsForm";
import { ContactUsInfo } from "contact/ContactUsInfo";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { Meta } from "_common/_utils/Meta";
import { URLS } from "_configs/URLS";

export default function ContactPage() {
    const { t } = useTranslation();
    const history = useHistory();
    const meta = {
        title: i18next.t("contactPage.meta.title"),
        description: i18next.t("contactPage.meta.description"),
        url: sharedConfig.appUrl + history.location.pathname,
        alternateUrls: [],
    };
    for (const lang of Object.keys(sharedConfig.languages)) {
        meta.alternateUrls.push({ url: sharedConfig.appUrl + URLS.contact(lang), lang: lang as TLang });
    }
    return (
        <div className={"mt_20 mb_60"}>
            <Meta title={meta.title} url={meta.url} alternateUrls={meta.alternateUrls} description={meta.description} />
            <Container>
                <h1 hidden className="mb_25">
                    {t("contactPage.h1Title")}
                </h1>
                <div className={"flex_row flexWrap_wrap"}>
                    <ContactUsForm />
                    <ContactUsInfo />
                </div>
            </Container>
        </div>
    );
}
