import React from "react";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { useUnitStore } from "units/UnitContext";
import { useTranslation } from "react-i18next";
import styles from "./_css/unitDetails.module.css";
import { IconTerraceArea } from "units/Icons/IconTerraceArea";
import clsx from "clsx";
import { IconSquare } from "properties/propertyPage/icons/IconSquare";
import { IconParkingSpot } from "units/Icons/IconParkingSpot";
import { IconLandSquare } from "units/Icons/IconLandSquare";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

export function UnitDetails() {
    const unitStore = useUnitStore();
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    const displayTerrace = !!unitStore.unit.terraceSurface.min;
    const isParkingDisplay = !!unitStore.unit.parkings.length;
    const displayLand = propertyStore.property.type !== PROPERTY_TYPE.condo && unitStore.unit.landSurface?.min !== 0;
    const isMultipleIcons = displayTerrace || isParkingDisplay || displayLand;
    return (
        <div className={clsx(styles.container, "flex_row")}>
            <div
                className={clsx("flex_row flex-1", styles.innerContainer, {
                    [styles.borderContainer]: isMultipleIcons,
                })}
            >
                <div className={clsx(styles.icon, " mr_20")}>
                    <IconSquare size={"60"} />
                </div>
                <div className={clsx("flex_column", styles.textContainer, { "flex-1": isMultipleIcons })}>
                    <h3>{t("unitPage.unitArea")}</h3>
                    <p>
                        {unitStore.unit.squareSurface?.max &&
                        unitStore.unit.squareSurface?.max !== unitStore.unit.squareSurface?.min
                            ? "Min : "
                            : ""}{" "}
                        {unitStore.unit.squareSurface?.min} {t("words.m2")} (
                        {convert(unitStore.unit.squareSurface.min, UNIT_CONVERSION.M2_TO_FT2)} {t("words.ft2")})
                    </p>
                    {!!unitStore.unit.squareSurface?.max &&
                        unitStore.unit.squareSurface?.max !== unitStore.unit.squareSurface?.min && (
                            <p>
                                Max: {unitStore.unit.squareSurface.max} {t("words.m2")} (
                                {convert(unitStore.unit.squareSurface.max, UNIT_CONVERSION.M2_TO_FT2)} {t("words.ft2")})
                            </p>
                        )}
                </div>
            </div>
            {displayTerrace && (
                <div className={clsx("flex_row flex-1", styles.innerContainer, styles.borderContainer)}>
                    <div className={clsx(styles.icon, " mr_20")}>
                        <IconTerraceArea />
                    </div>
                    <div className={clsx("flex_column", styles.textContainer)}>
                        <h3> {t("unitPage.terraceArea")}</h3>
                        <p>
                            {unitStore.unit.terraceSurface?.max &&
                            unitStore.unit.terraceSurface?.max !== unitStore.unit.terraceSurface?.min
                                ? "Min : "
                                : ""}{" "}
                            {unitStore.unit.terraceSurface?.min} {t("words.m2")} (
                            {convert(unitStore.unit.terraceSurface.min, UNIT_CONVERSION.M2_TO_FT2)} {t("words.ft2")})
                        </p>
                        {!!unitStore.unit.terraceSurface?.max &&
                            unitStore.unit.terraceSurface?.max !== unitStore.unit.terraceSurface?.min && (
                                <p>
                                    Max: {unitStore.unit.terraceSurface.max} {t("words.m2")} (
                                    {convert(unitStore.unit.terraceSurface.max, UNIT_CONVERSION.M2_TO_FT2)}{" "}
                                    {t("words.ft2")})
                                </p>
                            )}
                    </div>
                </div>
            )}
            {unitStore.unit.parkings.map((parking) => {
                return (
                    <div className={clsx("flex_row", styles.innerContainer, styles.borderContainer)} key={parking.type}>
                        <div className={clsx(styles.icon, " mr_20")}>
                            <IconParkingSpot />
                        </div>
                        <div className={clsx("flex_column flex-1", styles.textContainer)}>
                            <h3> {t("unitPage.parkingSpots")}</h3>
                            <p>{t(`unit.parkingLabel.${parking.type}`, { count: parking.number })}</p>
                        </div>
                    </div>
                );
            })}
            {displayLand && (
                <div className={clsx("flex_row", styles.innerContainer, styles.borderContainer)}>
                    <div className={clsx(styles.icon, " mr_20")}>
                        <IconLandSquare />
                    </div>
                    <div className={clsx("flex_column", styles.textContainer)}>
                        <h3> {t("unitPage.landArea")}</h3>
                        <p>
                            {unitStore.unit.landSurface?.max &&
                            unitStore.unit.landSurface?.max !== unitStore.unit.landSurface?.min
                                ? "Min : "
                                : ""}{" "}
                            {unitStore.unit.landSurface?.min} {t("words.m2")} (
                            {convert(unitStore.unit.landSurface.min, UNIT_CONVERSION.M2_TO_FT2)} {t("words.ft2")})
                        </p>
                        {!!unitStore.unit.landSurface?.max &&
                            unitStore.unit.landSurface?.max !== unitStore.unit.landSurface?.min && (
                                <p>
                                    Max: {unitStore.unit.landSurface.max} {t("words.m2")} (
                                    {convert(unitStore.unit.landSurface.max, UNIT_CONVERSION.M2_TO_FT2)}{" "}
                                    {t("words.ft2")})
                                </p>
                            )}
                    </div>
                </div>
            )}
        </div>
    );
}
