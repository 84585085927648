import React from "react";
import logo from "../../assets/images/zisla-logo.png";
import whiteLogo from "../../assets/images/zisla-logo-white.png";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";

type Props = {
    width?: number | string;
    height?: number | string;
    className?: string;
    noLink?: boolean;
    white?: boolean;
};

export function Logo(props: Props) {
    const style = {
        width: props.width ? props.width : undefined,
        height: props.height ? props.height : undefined,
    };

    return props.noLink ? (
        <img alt="Zisla logo" style={style} className={props.className} src={props.white ? whiteLogo : logo} />
    ) : (
        <Link to={URLS.home()}>
            <img alt="Zisla logo" style={style} className={props.className} src={props.white ? whiteLogo : logo} />
        </Link>
    );
}
