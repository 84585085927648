import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import houses from "./../../../shared/assets/images/propertyTypes/houses.jpg";
import condos from "./../../../shared/assets/images/propertyTypes/condos.jpg";
import villas from "./../../../shared/assets/images/propertyTypes/villas.jpg";
import lands from "./../../../shared/assets/images/propertyTypes/lands.jpg";

export type TPropertyTypeCard = {
    name: PROPERTY_TYPE;
    img: string;
    nbOfProperties?: number;
};

export const PROPERTY_TYPES: TPropertyTypeCard[] = [
    {
        name: PROPERTY_TYPE.house,
        img: houses,
    },
    {
        name: PROPERTY_TYPE.condo,
        img: condos,
    },
    {
        name: PROPERTY_TYPE.villa,
        img: villas,
    },
    {
        name: PROPERTY_TYPE.land,
        img: lands,
    },
];
