import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import styles from "../_css/blogResources.module.css";
import i18next from "i18next";

export function BlogResources() {
    const { t } = useTranslation();
    const lang = i18next.language;
    return (
        <ul className={styles.list}>
            <li>
                <Link to={URLS.about()}>{t("blogPage.resources.books")}</Link>
            </li>
            <li>
                <Link to={URLS.user.profile()}>{t("blogPage.resources.profile")}</Link>
            </li>
            <li>
                <Link to={URLS.faq(lang === "zh" ? "en" : lang)}>{t("blogPage.resources.faq")}</Link>
            </li>
            <li>
                <Link to={URLS.buy()}>{t("blogPage.resources.browse")}</Link>
            </li>
        </ul>
    );
}
