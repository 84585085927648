import React from "react";
import styles from "aboutUs/_css/aboutUsOurValues.module.css";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

export function AboutUsOurValues() {
    const { t } = useTranslation();
    return (
        <div className={"mt_80"}>
            <div className={"flex_row justifyContent_center"}>
                <div className={styles.title}>{t("aboutUsPage.ourValues.title")}</div>
            </div>
            <div className={clsx("mt_40 flex_row", styles.values)}>
                <div className={clsx(styles.value, "flex-1 mh_20 p_20")}>
                    <div className={clsx(styles.valueTitle, "textAlign_center")}>
                        {t("aboutUsPage.ourValues.value1Title")}
                    </div>
                    <div className={styles.valueText}>{t("aboutUsPage.ourValues.value1Text")}</div>
                </div>
                <div className={clsx(styles.value, "flex-1 mh_20 p_20")}>
                    <div className={clsx(styles.valueTitle, "textAlign_center")}>
                        {t("aboutUsPage.ourValues.value2Title")}
                    </div>
                    <div className={styles.valueText}>{t("aboutUsPage.ourValues.value2Text")}</div>
                </div>
                <div className={clsx(styles.value, "flex-1 mh_20 p_20")}>
                    <div className={clsx(styles.valueTitle, "textAlign_center")}>
                        {t("aboutUsPage.ourValues.value3Title")}
                    </div>
                    <div className={styles.valueText}>{t("aboutUsPage.ourValues.value3Text")}</div>
                </div>
            </div>
        </div>
    );
}
