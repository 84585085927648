import { ReactNode } from "react";
import { action, observable } from "mobx";

export type TDialogOption = {
    children: ReactNode;
    closeable?: boolean;
    onClose?: () => void;
    withSpinner?: boolean;
    opened: boolean;
};

class DialogStore {
    @observable option: TDialogOption | undefined;

    @action open(option: TDialogOption) {
        this.option = option;
    }

    @action close() {
        this.option = undefined;
    }
}

const dialogStore = new DialogStore();
export { dialogStore };
