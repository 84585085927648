import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import playa from "../assets/images/contactUs/playa.svg";
import phone from "../assets/images/aboutUs/phone.svg";
import email from "../assets/images/aboutUs/email.svg";
import whatsApp from "../assets/images/aboutUs/whatsApp.svg";
import { PHONE, WHATSAPP_PHONE } from "contact/ContactUsForm";
import { settingsStore } from "settings/_stores/settingsStore";
import { ISettingsMdl } from "settings/_models/SettingsMdl";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/contactUsInfo.module.css";

export function ContactUsInfo() {
    const settings = settingsStore.getOne() as ISettingsMdl;
    const { t } = useTranslation();
    if (!settings?.contacts) return null;
    return (
        <div className={clsx(styles.container, "flex-1")}>
            <LazyLoadImage src={playa} alt={"Playa"} title={"Playa"} className={styles.img} />
            <div className={clsx(styles.content, "ml_20")}>
                <div className={"flex_row_center mt_40 flex-1"}>
                    <LazyLoadImage src={phone} alt={"Phone"} title={"Phone"} />
                    <div className={"ml_20"}>
                        <div>{t("aboutUsPage.consultation.phone")}</div>
                        <div>
                            {t("aboutUsPage.consultation.phoneLabel")}{" "}
                            <a href={"tel:" + settings?.contacts?.phone ?? PHONE}>
                                {settings?.contacts?.phone ?? PHONE}
                            </a>
                        </div>
                        <div>
                            {t("aboutUsPage.consultation.mexicanPhoneLabel")}{" "}
                            <a href={"tel:" + settings?.contacts?.mexicanPhone ?? PHONE}>
                                {settings?.contacts?.mexicanPhone ?? PHONE}
                            </a>
                        </div>
                    </div>
                </div>
                <div className={"flex_row mt_40"}>
                    <LazyLoadImage src={email} alt={"Email"} title={"Email"} />
                    <div className={"ml_20"}>
                        <a href={"mailto:" + settings.contacts.emailContact}>{settings.contacts.emailContact}</a>
                    </div>
                </div>
                <div className={"flex_row_center mt_40 flex-1"}>
                    <LazyLoadImage src={whatsApp} alt={"WhatsApp"} title={"WhatsApp"} />
                    <div className={"ml_20"}>
                        <div>{t("aboutUsPage.consultation.whatsApp")}</div>
                        <a href={"https://wa.me/5149325688"} target="_blank" rel="noreferrer">
                            {WHATSAPP_PHONE}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
