import React from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getUrlWithPagination, isBuyOrRentPage } from "_common/_utils/pageUtils";

type Props = {
    count?: number;
    selectedPage: number;
    pageSize: number;
    onPageClick: (page: number) => void;
    variant?: "text" | "outlined";
    shape?: "round" | "rounded";
    color?: "primary" | "secondary" | "standard";
};

export const Paginator = (props: Props) => {
    const location = useLocation();
    const history = useHistory();
    const isBuyRentPage = isBuyOrRentPage(location.pathname);
    if (props.count === undefined) return null;
    const nbPages = Math.ceil(props.count / props.pageSize);
    return (
        <Pagination
            id={"pagination"}
            count={nbPages}
            page={props.selectedPage}
            onChange={(event, page) => {
                event.preventDefault();
                if (isBuyRentPage) history.push(getUrlWithPagination(location.pathname, page) + location.search);
                props.onPageClick(page);
            }}
            variant={props.variant}
            shape={props.shape}
            color={props.color}
            renderItem={(item) => {
                if (item?.page === 0 || item?.page > nbPages) return null;
                const link = item.page
                    ? getUrlWithPagination(location.pathname, item.page) + location.search
                    : undefined;
                return (
                    <>
                        {isBuyRentPage && link ? (
                            <Link to={link} onClick={() => props.onPageClick(item.page)}>
                                <PaginationItem rel={item.type.substring(0, 4)} {...item} />
                                {/*<PaginationItem type={"start-ellipsis"} />*/}
                                {/*<PaginationItem type={"end-ellipsis"} />*/}
                            </Link>
                        ) : (
                            <PaginationItem rel={item.type.substring(0, 4)} {...item} />
                        )}
                    </>
                );
            }}
        />
    );
};
