import React from "react";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { pagesStore } from "pages/_stores/pagesStore";
import { useTranslation } from "react-i18next";
import styles from "./_css/homeNews.module.css";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { BlogCard } from "home/BlogCard";

export function HomeNews() {
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.container, "flex_column_center")}>
            <h2 className={clsx(styles.title, "mt_80")}>{t("homePage.blog.title")}</h2>
            <LoadableFromLoading
                loading={pagesStore.getHomeArticles()}
                renderer={(_status, _error, homeArticles) => {
                    if (!homeArticles || homeArticles.length === 0) return null;
                    return (
                        <div id="blogCard" className={styles.blogContainer}>
                            <div className={clsx("flex_row_center justifyContent_spaceBetween mt_40", styles.blog)}>
                                {homeArticles.map((blog) => {
                                    return <BlogCard key={blog._id} blog={blog} />;
                                })}
                            </div>
                            <div className={clsx("position_relative", styles.swiperContainer)}>
                                <Swiper
                                    centeredSlides
                                    loop
                                    slidesPerView={1.5}
                                    zoom={{
                                        maxRatio: 5,
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1.5,
                                        },
                                        680: {
                                            slidesPerView: 2,
                                        },
                                    }}
                                    className={styles.swiper}
                                    initialSlide={0}
                                    spaceBetween={20}
                                    pagination={{
                                        clickable: true,
                                    }}
                                >
                                    {homeArticles.map((blog) => {
                                        return (
                                            <SwiperSlide key={blog._id}>
                                                <BlogCard blog={blog} />
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    );
                }}
            />
        </div>
    );
}
