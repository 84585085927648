import { PROPERTY_PURPOSE, TInventory, TPhase, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { action, computed, observable } from "mobx";
import { kindOfUnitBedroomsForProperty, reformatImagesForGallery } from "_common/_utils/propertyUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";
import { PROPERTY_SECTION_TABS } from "properties/propertyPage/PropertySections";
import sharedConfig from "_configs/sharedConfig";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { RESOURCE_KEY } from "admin/_common/photos/AdminPhotosStore";
import { getIndexOfFirstAvailableUnitInventory } from "_common/_utils/inventoryUtils";
import { TUnitAvailableMdl } from "unitAvailable/_models/UnitAvailableMdl";
import eventsStore from "main/events/eventsStore";

export class PropertyStore {
    @observable property: TPropertyListingMdl;
    @observable isGalleryOpened = -1;
    @observable selectedSection = PROPERTY_SECTION_TABS.INFO;
    @observable openedGalleryTab = GALLERY_TABS.GALLERY;
    @observable appointmentModalIsOpen = false;
    @observable infosModalIsOpen = false;
    @observable imageModalIsOpen = -1;
    @observable historyModalIsOpen = false;
    @observable typeOfImage = RESOURCE_KEY.PHOTOS;
    @observable selectedPhase: TPhase;
    @observable selectedInventory: TInventory | undefined;
    @observable isSquareSurfaceInSquareMeter = false;

    @observable similarPropertiesState = new LoadingStateMdl<TPropertyListingMdl[]>();
    @observable similarPremiumPropertiesState = new LoadingStateMdl<TPropertyListingMdl[]>();
    @observable similarProperties: TPropertyListingMdl[] = [];
    @observable similarPremiumProperties: TPropertyListingMdl[] = [];
    @observable logHistory: TUnitAvailableMdl[] = [];
    @observable logHistoryState = new LoadingStateMdl<TUnitAvailableMdl[]>();

    constructor(property: TPropertyListingMdl) {
        this.property = property;
        this.selectedInventory = this.property.inventory[
            getIndexOfFirstAvailableUnitInventory(this.property.inventory)
        ];
        this.selectedPhase = this.selectedInventory?.phase;
        if (!__BROWSER__) {
            eventsStore.on("START_RENDER", () => {
                this.onReset();
            });
        } else {
            this.onInit();
        }
    }

    @computed get isForBuyingPurpose() {
        return this.property.purpose === PROPERTY_PURPOSE.BUY;
    }

    @computed get kindOfBedrooms() {
        return kindOfUnitBedroomsForProperty(this.property);
    }

    @action setIsSquareSurfaceInSquareMeter(isSquareSurfaceIsInMeter: boolean) {
        this.isSquareSurfaceInSquareMeter = isSquareSurfaceIsInMeter;
    }

    @action setSelectedPhase(phase: TPhase) {
        if (!phase) return null;
        this.selectedPhase = phase;
        this.selectedInventory = this.property.inventory.find(
            (inventory) => JSON.stringify(inventory.phase) === JSON.stringify(this.selectedPhase),
        );
    }

    @action setAppointmentModalClose() {
        this.appointmentModalIsOpen = false;
    }

    @action setAppointmentModalOpen() {
        this.appointmentModalIsOpen = true;
    }

    @action setHistoryModalIsClose() {
        this.historyModalIsOpen = false;
    }

    @action setHistoryModalIsOpen() {
        this.historyModalIsOpen = true;
    }

    @action setInfosModalClose() {
        this.infosModalIsOpen = false;
    }

    @action setInfosModalOpen() {
        this.infosModalIsOpen = true;
    }

    @action openGallery(imageIdx = 0, tabName = GALLERY_TABS.GALLERY) {
        this.setOpenedGalleryTab(tabName);
        this.isGalleryOpened = imageIdx;
    }

    @action setOpenedGalleryTab(tabName = GALLERY_TABS.GALLERY) {
        this.openedGalleryTab = tabName;
    }

    @action setSectionTab(tabName?: PROPERTY_SECTION_TABS) {
        this.selectedSection = tabName ?? PROPERTY_SECTION_TABS.INFO;
    }

    @action setOpenedImageModal(typeOfImage: RESOURCE_KEY, imageIndex?: number) {
        this.typeOfImage = typeOfImage;
        this.imageModalIsOpen = imageIndex ?? 0;
    }

    @action closeImageModal() {
        this.imageModalIsOpen = -1;
    }

    @action closeGallery() {
        this.isGalleryOpened = -1;
    }

    fetchSimilarPremiumProperties() {
        return this.fetchSimilarProperties(this.similarPremiumPropertiesState, true);
    }
    fetchSimilarRegularProperties() {
        return this.fetchSimilarProperties(this.similarPropertiesState);
    }

    private reformatImagesForGallery() {
        reformatImagesForGallery(this.property);
    }

    fetchHistoryLogs(limit?: number, sort?: { [key: string]: number }) {
        if (!this.logHistoryState.isLoading && !this.logHistoryState.isSucceeded) {
            this.logHistoryState.startLoading();
            const filtersParam = `filters=${JSON.stringify([
                {
                    id: "propertyId",
                    type: TFilterType.STRING,
                    value: this.property._id,
                },
            ])}`;
            const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
            const limitParam = limit ? `&limit=${JSON.stringify(limit)}` : "";
            const urlUnitAvailables = `${sharedConfig.apiUrl}/unitAvailables/listing?${limitParam}${sortParam}${filtersParam}`;
            const promise = fetchUtils.get<{ items: TUnitAvailableMdl[] }>(urlUnitAvailables);
            promise.then(
                action(({ data }) => {
                    this.logHistory = data.items;
                    this.logHistoryState.setSuccess(data.items);
                }),
                action((error) => {
                    this.logHistoryState.setError(error);
                }),
            );
        }
    }

    private fetchSimilarProperties(state: LoadingStateMdl<TPropertyListingMdl[]>, premium = false) {
        if (!state.isLoading && !state.isSucceeded) {
            state.startLoading();
            const filters = [
                {
                    id: "type",
                    value: this.property.type,
                    type: TFilterType.STRING,
                },
                {
                    id: "premiumPlan",
                    value: 0,
                    type: TFilterType.NUMBER,
                    op: premium ? "gt" : "eq",
                },
                {
                    id: "purpose",
                    value: this.property.purpose,
                    type: TFilterType.STRING,
                },
            ];
            const sort = {
                "address.neighbourhood": 1,
                "address.city": 1,
                premiumPlan: -1,
                position: 1,
                sold: 1,
            };
            const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
            const sortParam = filters.length > 0 ? `&sort=${JSON.stringify(sort)}` : "";
            const limit = 5;
            const url = `${sharedConfig.apiUrl}/properties/listing?offset=0&limit=${limit}${sortParam}${filtersParam}`;
            const promise = fetchUtils.get<{ items: TPropertyListingMdl[] }>(url);
            promise.then(
                action(({ data }) => {
                    if (premium) {
                        this.similarPremiumProperties = data.items;
                        state.setSuccess(data.items);
                    } else {
                        this.similarProperties = data.items;
                        state.setSuccess(data.items);
                    }
                }),
                action((error) => {
                    state.setError(error);
                }),
            );
        }
    }

    reformatPhotosForModal() {
        this.property.photos = this.property.photos.filter((photo) => !photo.title);
    }

    onInit() {
        this.reformatImagesForGallery();
    }

    protected onReset() {
        this.similarPropertiesState = new LoadingStateMdl();
        this.similarPremiumPropertiesState = new LoadingStateMdl();
        this.logHistoryState = new LoadingStateMdl();
        this.similarProperties = [];
        this.similarPremiumProperties = [];
        this.logHistory = [];
    }
}
