import React from "react";
import styles from "./_css/header.module.css";
import { Container } from "@material-ui/core";
import clsx from "clsx";
import { useScrollPosition } from "_common/_utils/hookUtils";
import { PAGE_REF } from "pages/PageRegular";
import { LangSelector } from "main/header/LangSelector";
import { Logo } from "_common/icons/Logo";
import { Menu } from "main/header/menu/Menu";
import { settingsStore } from "settings/_stores/settingsStore";

type Props = {
    isMapPage?: boolean;
};

function Header(props: Props) {
    const scrollPosition = useScrollPosition(PAGE_REF);
    return (
        <header onClick={() => settingsStore.close()}>
            <div className={clsx("mui-fixed", styles.wrapper)}>
                <Container className={clsx("m_auto", { [styles.fullContainer]: props.isMapPage })}>
                    <div className={clsx("flex_row_center", styles.container)}>
                        <div className={styles.logoMobile}>
                            <Logo className="mt_10" width={75} height={25} />
                        </div>
                        <div className={styles.logoDesktop}>
                            <Logo className="mt_10" width={210} height={65} />
                        </div>
                        <div className="flex-1" />
                        <Menu className="mr_20" />
                        <LangSelector />
                    </div>
                </Container>
            </div>
            <div
                className={clsx(styles.placeholder, {
                    [styles.containerScrolling]: scrollPosition > 100,
                })}
            />
        </header>
    );
}

export default Header;
