import React, { CSSProperties } from "react";
import { TComponent } from "pages/_models/PageMdl";
import clsx from "clsx";
import styles from "./_css/styles.module.css";

export type TextComponentProps = {
    style?: CSSProperties;
    value: string;
    $component?: TComponent;
};

export function TextComponent({ value, $component, ...props }: TextComponentProps) {
    return (
        <div
            {...props}
            style={{ margin: 5, ...props.style }}
            className={clsx("text_normal", styles.container)}
            dangerouslySetInnerHTML={{ __html: value }}
        />
    );
}
