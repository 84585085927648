import React from "react";
import { useLocation } from "react-router";
import { pagesStore } from "pages/_stores/pagesStore";
import { IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { PageRenderer } from "pages/PageRenderer";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { PageNotFound } from "pages/PageNotFound";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { HomePage } from "home/HomePage";
import i18next from "i18next";
import { useLoadingFromPromiseForPageRouter } from "_common/loaders/useLoadingFromPromiseForPageRouter";
import _PageNotFound from "pages/_PageNotFound";
import { getI18nExpByLang } from "_common/_utils/pageUtils";

export function PageRouter() {
    const location = useLocation();
    const syncPage = pagesStore.getByUrlSync(location.pathname, i18nextInstance.language as TLang);
    const page = syncPage ?? pagesStore.getByUrl(location.pathname, i18nextInstance.language as TLang);
    const loading = useLoadingFromPromiseForPageRouter("then" in page ? (page as Promise<IPageMdl>) : undefined);

    if (location.pathname === "/" || location.pathname === sharedConfig.languages[i18next.language as TLang].basePath) {
        return <HomePage />;
    }

    if (!__BROWSER__ && syncPage) {
        const isBlogPage = syncPage.type === PAGE_TYPE.ARTICLE;
        const isPathNameBlogPage = location.pathname.includes(
            getI18nExpByLang(i18nextInstance.language, "routes.blogPage"),
        );

        // TODO
        // if (isBlogPage && !isPathNameBlogPage) {
        //     return (
        //         <>
        //             <_PageNotFound />
        //             <div id={"SSR_page404NotFound"} />
        //         </>
        //     );
        // }
    }

    if (!__BROWSER__ && !syncPage) {
        return (
            <>
                <_PageNotFound />
                <div id={"SSR_page404NotFound"} />
            </>
        );
    }

    if ("then" in page) {
        if (!loading) return null;
        return (
            <LoadableFromLoading
                loading={loading}
                renderer={(_status, error) => {
                    const fetchedPage = pagesStore.getByUrlSync(location.pathname, i18nextInstance.language as TLang);

                    if (error) {
                        return <ErrorBlock error={error} />;
                    }
                    if (!fetchedPage) {
                        return <PageNotFound />;
                    }
                    return <PageRenderer page={fetchedPage} />;
                }}
            />
        );
    }
    return <PageRenderer page={page} />;
}
