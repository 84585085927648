import React from "react";
import { userStore } from "users/_stores/userStore";
import styles from "./_css/userSelectedOverlay.module.css";
import { observer } from "mobx-react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { URLS } from "_configs/URLS";

export const UserSelectedOverlay = observer(() => {
    const history = useHistory();
    return (
        <div className={clsx(styles.container, "flex_center_center")}>
            {userStore.userSelected && (
                <h2 className={"mv_10"}>
                    ADDING FAVORITE AS : {userStore.userSelected.name} - {userStore.userSelected.email}
                    <span
                        className={"cursor_pointer ml_20"}
                        onClick={() => {
                            userStore.setUserSelected(undefined);
                            history.push(URLS.user.profile());
                        }}
                    >
                        X
                    </span>
                </h2>
            )}
        </div>
    );
});
