import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { observer } from "mobx-react";
import { PropertyOrUnitInfosForm } from "properties/propertyPage/_forms/PropertyOrUnitInfosForm";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";

export const PropertyModalInfos = observer(() => {
    const propertyStore = usePropertyStore();
    return (
        <Dialog
            open={propertyStore.infosModalIsOpen}
            onClose={() => propertyStore.setInfosModalClose()}
            withoutPlaceholder
        >
            <div className={"flex_column_center"}>
                <PropertyOrUnitInfosForm resource={RESOURCE.PROPERTIES} IsInModal />
            </div>
        </Dialog>
    );
});
