import React from "react";
import styles from "./_css/recaptchaDisclaimer.module.css";
import clsx from "clsx";

type Props = {
    color?: "white" | "black";
    small?: boolean;
};
export function RecaptchaDisclaimer({ color, small }: Props) {
    return (
        <div className={clsx(styles.container, { [styles.white]: color === "white", [styles.small]: !!small })}>
            Google's terms of use and privacy policy apply. This site is protected by reCAPTCHA Enterprise and the
            Google
            <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
            <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
        </div>
    );
}
