import React from "react";
import CookieConsent from "react-cookie-consent";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export function Cookie() {
    const { t } = useTranslation();
    return (
        <CookieConsent
            location="bottom"
            buttonText={t("words.accept")}
            cookieName="cookieAccepted"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px", marginRight: "100px" }}
            expires={150}
        >
            {i18next.t("cookies.text")}
        </CookieConsent>
    );
}
