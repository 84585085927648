import { TLang } from "_configs/sharedConfig";
import { TImage } from "_common/types/baseTypes";

export enum USER_STATUS {
    INACTIVE = "INACTIVE",
    ACTIVE = "ACTIVE",
}

export type IHeardAboutUs = { key: string; other?: string };

export enum AC_TAGS {
    L1_UNIT = "L1unit",
    L2_PROPERTY = "L2property",
    L3_HELP_ME_HOME = "L3helpmehome",
    L4_CONTACT_US = "L4contactus",
    L7_BLOG = "L7Blog",
    L8_CONTACT_US_ABOUT_US = "L8contactusaboutus",
    L9_ADVISER = "L9adviser",
    L10_PROFILE = "L10profile",
    LEAD = "Lead",
    SUBSCRIBE = "Subscribed - Newsletter",
}

export enum USER_ROLES {
    ADMIN = "ADMIN",
    DEVELOPER = "DEVELOPER",
    MASTER = "MASTER",
}

export enum EMPLOYEE_RIGHTS {
    END_USERS_NOTIFICATION = "END_USERS_NOTIFICATION",
    WHEN_PUBLISHED_PROPERTY = "WHEN_PUBLISHED_PROPERTY",
    ALL = "ALL",
}

export enum USER_SAVED_SEARCH_FREQUENCY {
    published = "published",
    weekly = "weekly",
    biWeekly = "biWeekly",
    biMonthly = "biMonthly",
}

export type IAddressMdl = {
    street: string;
    extra: string;
    city: string;
    postalCode: string;
    country: string;
    province: string;
};

export type TUserLocalized = {
    description: string;
};

export interface IFavoritesSearchMdl {
    _id?: string;
    filters: string;
    search: string;
    region?: string;
    city?: string;
    lastSeenDate: Date;
    notification: boolean;
}

export type IUserMdl = {
    _id: string;
    status: USER_STATUS;
    email: string;
    secondEmail?: string;
    phone?: string;
    firstName: string;
    lastName: string;
    companyName: string;
    website: string;
    notification: {
        newsletter: boolean;
        marketUpdates: boolean;
        blogPosts: boolean;
        savedSearchFrequency: USER_SAVED_SEARCH_FREQUENCY;
    };
    address?: IAddressMdl;
    lang: TLang;
    favorites: string[];
    favoritesSearches: IFavoritesSearchMdl[];
    roles: USER_ROLES[];
    dashboardToken: string;
    photo: TImage;
    color?: string;
    localized: {
        en: TUserLocalized;
        fr: TUserLocalized;
        es: TUserLocalized;
        zh: TUserLocalized;
    };
};

export type TUserSummaryMdl = {
    _id: string;
    phone?: string;
    photo?: string;
    color?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    email?: string;
    lang: TLang;
    website?: string;
    localized: TUserLocalized;
};
