import React, { lazy } from "react";
import { Route, Switch } from "react-router";
import { PageRouter } from "pages/PageRouter";
import { PAGE_REF, PageRegular } from "pages/PageRegular";
import { URLS } from "_configs/URLS";
import { BuyPage } from "properties/BuyPage";
import { PropertyPage } from "properties/PropertyPage";
import { BlogPage } from "blog/BlogPage";
import { AuthenticatedRoute } from "_common/_utils/AuthenticatedRoute";
import { UnitPage } from "units/UnitPage";
import { ScrollTop } from "_common/_utils/ScrollTop";
import { NoSsr } from "@material-ui/core";
import { AboutUsPage } from "aboutUs/AboutUsPage";
import Suspenser from "_common/loaders/Suspenser";
import ContactPage from "contact/ContactPage";
import { deleteItemInitialSate, getInitialStateValue } from "_common/_utils/initialStateUtils";
import { PageNotFound } from "pages/PageNotFound";

const LazyComparePageComparePage = lazy(() => import("compare/ComparePage"));
const LazyTestimonialsPage = lazy(() => import("reviews/TestimonialsPage"));
const LazyProfilePage = lazy(() => import("users/profile/ProfilePage"));

export function AppContent() {
    if (getInitialStateValue("is404")) {
        deleteItemInitialSate("is404");
        return (
            <PageRegular>
                <PageNotFound />
            </PageRegular>
        );
    }
    return (
        <PageRegular>
            <Switch>
                <AuthenticatedRoute
                    path={URLS.user.profile(":lang")}
                    component={() => {
                        return (
                            <Suspenser>
                                <LazyProfilePage />
                            </Suspenser>
                        );
                    }}
                />
                <Route path={URLS.blog(":lang", ":blogType?")} component={BlogPage} />
                <Route path={URLS.about(":lang")} component={AboutUsPage} />

                <Route path={URLS.buy(":lang", ":p1", ":p2", ":p3", ":p4", ":p5")} component={BuyPage} />
                <Route path={URLS.buy(":lang", ":p1", ":p2", ":p3", ":p4")} component={BuyPage} />
                <Route path={URLS.buy(":lang", ":p1", ":p2", ":p3")} component={BuyPage} />
                <Route path={URLS.buy(":lang", ":p1", ":p2")} component={BuyPage} />
                <Route path={URLS.buy(":lang", ":p1")} component={BuyPage} />
                <Route path={URLS.buy(":lang")} component={BuyPage} />
                <Route path={URLS.unit(":propertyAlias", ":unitAlias", ":lang")} component={UnitPage} />
                <Route path={URLS.property(":propertyAlias", ":lang")} component={PropertyPage} />
                <Route path={URLS.contact(":lang")} component={ContactPage} />

                <Route
                    path={URLS.compare(":type", ":lang")}
                    component={() => {
                        return (
                            <Suspenser>
                                <LazyComparePageComparePage />
                            </Suspenser>
                        );
                    }}
                />

                <Route
                    path={URLS.testimonials(":lang")}
                    component={() => {
                        return (
                            <Suspenser>
                                <LazyTestimonialsPage />
                            </Suspenser>
                        );
                    }}
                />

                <Route path="/" component={PageRouter} />
            </Switch>
            <NoSsr>
                <ScrollTop pageRef={PAGE_REF} />
            </NoSsr>
        </PageRegular>
    );
}
