import React from "react";
import { Container } from "@material-ui/core";
import styles from "./_css/homeContactUs.module.css";
import clsx from "clsx";
import { Input } from "_common/ui/forms/Input";
import { TextArea } from "_common/ui/forms/TextArea";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { TContactForm } from "components/misc/contactUs/ContactFormComponent";
import { userStore } from "users/_stores/userStore";
import { ContactFormBtn } from "components/misc/contactUs/ContactFormBtn";
import { AC_TAGS } from "users/_models/UserMdl";
import { BLOG_TYPE } from "pages/_models/PageMdl";
import { InputPhone } from "_common/ui/forms/InputPhone";
import { HiddenFieldsAttributerForm } from "components/form/HiddenFieldsAttributerForm";

type Props = {
    className?: string;
    acTags?: AC_TAGS;
    blogType?: BLOG_TYPE;
    blogTitle?: string;
};

export function HomeContactUs(props: Props) {
    const form = useForm<TContactForm>({
        defaultValues: {
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            text: "",
            subject: "",
        },
        mode: "onBlur",
    });
    const { t } = useTranslation();
    return (
        <div className={clsx("mt_60", props.className)}>
            <FormProvider {...form}>
                <Container>
                    <div className={clsx(styles.container, "p_20")}>
                        <div className={"textAlign_center"}>
                            <h2 className={styles.title}>{t("homePage.contactUs.title")}</h2>
                        </div>
                        <div className={clsx(styles.content, "flex_row")}>
                            <div
                                className={clsx(
                                    styles.infos,
                                    "flex_column flexWrap_wrap justifyContent_spaceBetween mr_20",
                                )}
                            >
                                <div className={styles.halfInput}>
                                    <Controller
                                        as={Input}
                                        name="name"
                                        control={form.control}
                                        className={styles.input}
                                        placeholder={t("contactPage.form.name")}
                                    />
                                </div>
                                <div className={styles.halfInput}>
                                    <Controller
                                        as={Input}
                                        name="email"
                                        control={form.control}
                                        className={styles.input}
                                        placeholder={t("contactPage.form.email")}
                                    />
                                </div>
                                <Controller
                                    as={InputPhone}
                                    name="phone"
                                    control={form.control}
                                    className={styles.input}
                                    placeholder={t("contactPage.form.phone")}
                                />
                            </div>
                            <Controller
                                as={TextArea}
                                name="text"
                                control={form.control}
                                rows={8}
                                className={styles.input}
                                placeholder={"Message"}
                            />
                        </div>
                        <div className="pb_0 flex_center_center">
                            <ContactFormBtn
                                acTags={props.acTags ?? AC_TAGS.L3_HELP_ME_HOME}
                                blogType={props.blogType}
                                blogTitle={props.blogTitle}
                            />
                        </div>
                    </div>
                </Container>
                <HiddenFieldsAttributerForm />
            </FormProvider>
        </div>
    );
}
