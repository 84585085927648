import React from "react";
import clsx from "clsx";
import styles from "./_css/horizontalUnitCardLoader.module.css";

export function HorizontalUnitCardLoader() {
    return (
        <div className={clsx(styles.container, "flex_column")}>
            <div className={clsx(styles.card, "flex_row")}>
                <div className={styles.img} />
                <div className={styles.content}>
                    <h2 />
                    <p />
                    <p />
                </div>
            </div>
            <div className={clsx(styles.card, "flex_row")}>
                <div className={styles.img} />
                <div className={styles.content}>
                    <h2 />
                    <p />
                    <p />
                </div>
            </div>
            <div className={clsx(styles.card, "flex_row")}>
                <div className={styles.img} />
                <div className={styles.content}>
                    <h2 />
                    <p />
                    <p />
                </div>
            </div>
        </div>
    );
}
