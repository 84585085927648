import React from "react";
import { PropertiesPage } from "properties/PropertiesPage";
import { PropertiesPageContext } from "properties/PropertiesPageContext";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";

export function BuyPage() {
    return (
        <PropertiesPageContext store={propertiesBuyingStore}>
            <PropertiesPage />
        </PropertiesPageContext>
    );
}
