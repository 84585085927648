import React from "react";
import clsx from "clsx";
import styles from "./_css/price.module.css";
import { useTranslation } from "react-i18next";
import { formatPrice } from "_common/_utils/currencyUtils";

type Props = {
    price: number;
    className?: string;
    priceIsInPesos?: boolean;
    priceOnRequest?: boolean;
};

export function Price(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            {props.priceOnRequest ? (
                <div className={styles.from}>{t("propertiesPage.card.priceOnRequest")}</div>
            ) : (
                <>
                    <div className={clsx(styles.from, "mr_10")}>{t("words.from")}</div>
                    <div className={styles.price}>{formatPrice(props.price, false, props.priceIsInPesos)}</div>
                </>
            )}
        </div>
    );
}
