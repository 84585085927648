import React, { lazy, useMemo } from "react";
import { PropertyContext } from "properties/propertyPage/PropertyContext";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { URLS } from "_configs/URLS";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import i18next from "i18next";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { UnitStore } from "units/_stores/UnitStore";
import { UnitContext } from "units/UnitContext";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { UnitMeta } from "units/UnitMeta";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { UnitSections } from "units/UnitSections";
import styles from "properties/propertyPage/_css/propertyView.module.css";
import clsx from "clsx";
import { PropertyModalAppointment } from "properties/propertyPage/PropertyModalAppointment";
import { reformatStringForUrls, removeAccentFromString } from "_common/_utils/alphaNumUtils";
import { getAllAvailableUnitsOfInventory, getUnitsByStatusFromInventory } from "_common/_utils/inventoryUtils";
import Suspenser from "_common/loaders/Suspenser";
import { Helmet } from "react-helmet-async";
import { PropertyOrUnitGallery } from "properties/gallery/PropertyOrUnitGallery";
import { PropertyOrUnitGalleryMobile } from "properties/gallery/PropertyOrUnitGalleryMobile";
import { PROPERTY_SUBTYPE, TUnitOfInventory } from "properties/_models/PropertyMdl";

dayjs.locale(i18next.language);

export const UNIT_VIEW_REF: { current: HTMLDivElement | null } = {
    current: null,
};

const LazyPropertyOrUnitSideBar = lazy(() => import("properties/propertyPage/PropertyOrUnitSideBar"));
const LazyPropertyOrUnitGalleryPage = lazy(() => import("properties/gallery/PropertyOrUnitGalleryPage"));
const LazyPropertyOrUnitGalleryImageModal = lazy(() => import("properties/gallery/PropertyOrUnitGalleryImageModal"));
const LazyUnitModalInfos = lazy(() => import("units/UnitModalInfos"));

type Props = {
    unit: TUnitListingMdl;
};

export function UnitView(props: Props) {
    const { t } = useTranslation();
    const parentGeoZoneUrl = URLS.buy(
        undefined,
        props.unit.property.address?.provinceShort
            ? reformatStringForUrls(props.unit.property.address?.provinceShort)
            : t(
                  "provinces.short." + removeAccentFromString(props.unit.property.address.province).replace(" ", ""),
              ).toLowerCase(),
        reformatStringForUrls(props.unit.property.address.city),
        props.unit.property.address.neighbourhood
            ? reformatStringForUrls(props.unit.property.address.neighbourhood)
            : undefined,
    );

    const items = [
        {
            itemLabel: t("propertyPage.research"),
            url: URLS.buy(),
        },
        {
            itemLabel: props.unit.property.address.city,
            url: URLS.buy(
                undefined,
                t(
                    "provinces.short." + removeAccentFromString(props.unit.property.address.province).replace(" ", ""),
                ).toLowerCase(),
                removeAccentFromString(props.unit.property.address.city).replace(" ", "").toLowerCase(),
            ),
        },
    ];

    if (props.unit.property.address.neighbourhood) {
        items.push({
            itemLabel: props.unit.property.address.neighbourhood,
            url: parentGeoZoneUrl,
        });
    }

    items.push({
        itemLabel: props.unit.property.localized.title,
        url: URLS.property(props.unit.property.localized.urlAlias),
    });
    const propertyStore = useMemo(() => new PropertyStore(props.unit.property), [props.unit.property]);
    const unitStore = useMemo(() => new UnitStore(props.unit), [props.unit]);
    const availableUnitOfInventory: TUnitOfInventory[] = [];
    if (propertyStore.property.subType === PROPERTY_SUBTYPE.buildableHouse) {
        if (unitStore.unit?.placeholdersUnitsIds?.length) {
            unitStore.unit.placeholdersUnitsIds.forEach((unitsPlaceholder) =>
                availableUnitOfInventory.push(
                    ...getUnitsByStatusFromInventory(propertyStore.property.inventory, unitsPlaceholder),
                ),
            );
        } else {
            availableUnitOfInventory.push(...getAllAvailableUnitsOfInventory(propertyStore.property.inventory));
        }
    } else {
        availableUnitOfInventory.push(
            ...getUnitsByStatusFromInventory(propertyStore.property.inventory, unitStore.unit._id),
        );
    }
    unitStore.fetchHistoryLogs();

    return (
        <PropertyContext propertyStore={propertyStore}>
            <UnitContext unitStore={unitStore}>
                <Helmet>
                    <link rel="preload" as="image" href={props.unit?.photos?.[0]?.url} />
                    <link rel="preload" as="image" href={props.unit?.photos?.[0]?.thumbnail} />
                </Helmet>
                <div className={styles.mobileGallery}>
                    <PropertyOrUnitGalleryMobile
                        resource={RESOURCE.UNITS}
                        store={unitStore}
                        numberOfUnitLeft={availableUnitOfInventory.length}
                    />
                </div>
                <Suspenser noSpinner noText>
                    <LazyPropertyOrUnitSideBar resource={RESOURCE.UNITS} propertyOrUnit={unitStore.unit} />
                </Suspenser>
                <div className={styles.container}>
                    <UnitMeta property={props.unit.property} unit={props.unit} />
                    <div className={"flex_row"}>
                        <div className={clsx(styles.sectionsColumn, "flex-1")}>
                            <div className={styles.breadcrumb}>
                                <Breadcrumb items={items} currentItem={{ forceReload: false }} />
                            </div>
                            <UnitSections />
                        </div>
                        <div ref={UNIT_VIEW_REF} className={clsx(styles.gallery, "flex-1")}>
                            <PropertyOrUnitGallery
                                resource={RESOURCE.UNITS}
                                store={unitStore}
                                numberOfUnitLeft={availableUnitOfInventory.length}
                            />
                        </div>
                    </div>
                </div>
                <Suspenser>
                    <LazyPropertyOrUnitGalleryPage resource={RESOURCE.UNITS} />
                </Suspenser>
                <Suspenser>
                    <LazyPropertyOrUnitGalleryImageModal resource={RESOURCE.UNITS} store={unitStore} />
                </Suspenser>
                <PropertyModalAppointment />
                <Suspenser>
                    <LazyUnitModalInfos />
                </Suspenser>
            </UnitContext>
        </PropertyContext>
    );
}
