import React, { lazy } from "react";
import Suspenser from "_common/loaders/Suspenser";

const LazyPageNotFound = lazy(() => import("shared/pages/_PageNotFound"));

export function PageNotFound() {
    return (
        <Suspenser noSpinner noText>
            <LazyPageNotFound />
        </Suspenser>
    );
}
