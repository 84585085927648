import { INVENTORY_STATUS, TUnitListingMdl, TUnitLocalized, UNIT_TYPE } from "units/_models/UnitMdl";
import {
    PROPERTY_TYPE,
    TAddressMdl,
    TPropertyBaseMdl,
    TPropertyListingMdl,
    TPropertyMdl,
} from "properties/_models/PropertyMdl";
import _ from "lodash";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";
import { PROPERTY_OPTION } from "_configs/propertyConfig";
import { IPropertyModel } from "api/properties/_models/PropertyModel";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls, removeAccentFromString } from "_common/_utils/alphaNumUtils";
import i18next from "i18next";

export function getParentGeoZoneByProperty(property: { address: TAddressMdl }, lang?: string) {
    return URLS.buy(
        lang,
        property.address?.provinceShort
            ? reformatStringForUrls(property.address?.provinceShort)
            : i18next
                  .t("provinces.short." + removeAccentFromString(property.address.province).replace(" ", ""))
                  .toLowerCase(),
        reformatStringForUrls(property.address.city),
        property.address.neighbourhood ? reformatStringForUrls(property.address.neighbourhood) : undefined,
    );
}

export function getPriceBySquare(units?: (TUnitListingMdl | undefined)[]) {
    if (units && units.length) {
        const ratios = units
            .filter(
                (unit) =>
                    unit &&
                    unit.type !== UNIT_TYPE.buildableModel &&
                    unit.published &&
                    unit.status === INVENTORY_STATUS.AVAILABLE,
            )
            .map((unit) => {
                if (unit) {
                    return Math.round(unit.price.min / unit.squareSurface?.min ?? 1) / 100;
                }
            });
        const reformattedRatio = _.compact(ratios);
        if (ratios.length) {
            return Math.min(...reformattedRatio);
        }
    }
}
export function kindOfUnitBedroomsForProperty(property: TPropertyListingMdl) {
    let kindOfBedrooms: { [key: number]: TUnitLocalized } = {};
    if (!property.units) return undefined;
    for (const bedroom of property.units) {
        if (kindOfBedrooms && bedroom.bedrooms in kindOfBedrooms) continue;
        kindOfBedrooms = { ...kindOfBedrooms, [bedroom.bedrooms]: bedroom.localized };
    }
    return kindOfBedrooms;
}

export function flattenObject(
    baseObject?: any,
    path = "",
    output: {
        [key: string]: string | number | undefined | null | boolean;
    } = {},
) {
    if (
        baseObject === undefined ||
        baseObject === null ||
        typeof baseObject !== "object" ||
        _.isArray(baseObject) ||
        _.isDate(baseObject)
    ) {
        output[path] = baseObject;
    } else {
        for (const key in baseObject) {
            flattenObject(baseObject[key], path !== "" ? path + "." + key : key, output);
        }
    }
    return output;
}

export function reformatImagesForGallery(item: TPropertyListingMdl | TUnitListingMdl) {
    if (item.photos?.length > 3 && item.photos[0].url !== item.photos[2].url) {
        const title = item.video ? GALLERY_TABS.VIDEO : GALLERY_TABS.GALLERY;
        item.photos.splice(2, 0, { ...item.photos[0], title: title });
        if (item.video || item.floorPlans.length > 0) {
            const title = item.video ? GALLERY_TABS.GALLERY : GALLERY_TABS.LEVEL_PLAN;
            item.photos.splice(4, 0, { ...item.photos[0], title: title });
        }
        if (item.video && item.floorPlans.length > 0) {
            const title = GALLERY_TABS.LEVEL_PLAN;
            item.photos.splice(6, 0, { ...item.photos[0], title: title });
        }
    }
    return item;
}

export function isLandOrCommercial(property?: TPropertyBaseMdl) {
    if (!property || !property?.type) return false;
    return property.type === PROPERTY_TYPE.land || property.type === PROPERTY_TYPE.commercial;
}

export function isInPesosProperty(property?: TPropertyBaseMdl | TPropertyMdl | IPropertyModel) {
    if (!property || !property?.priceInPesos || !property.priceInPesos?.min) return false;
    return property?.priceInPesos?.min !== 0 && !!property?.features?.[PROPERTY_OPTION.closingInPesos];
}
