import React from "react";
import clsx from "clsx";
import styles from "./_css/articleCategoryCard.module.css";
import { useTranslation } from "react-i18next";
import chevronLeft from "../assets/images/icons/chevronLeft.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import i18next from "i18next";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { pagesStore } from "pages/_stores/pagesStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";

type Props = {
    blogType: string;
    odd?: boolean;
};

export function ArticleCategoryCard(props: Props) {
    const { t } = useTranslation();
    const promise = pagesStore.countArticlesByType(props.blogType);
    const { loading } = useLoadingFromPromise(promise);
    return (
        <LoadableFromLoading
            loading={loading}
            renderer={(_status, _error, results) => {
                if (!results) return null;
                const { data } = results;
                if (!data) return null;
                const { count } = data;
                if (!count) return null;
                return (
                    <div
                        className={clsx(styles.container, "p_20 pv_10 mt_20", {
                            [styles.odd]: props.odd,
                        })}
                    >
                        <Link
                            to={URLS.blog(i18next.language, props.blogType.toLowerCase())}
                            className={"flex_row_center"}
                        >
                            <div className={"flex-1"}>
                                <span className={styles.categoryTitle}>
                                    {t("blogPage.blogTypes." + props.blogType)}
                                </span>
                                <div className="ml_40">{t("blogPage.resultsByBlogType", { count })}</div>
                            </div>
                            <LazyLoadImage className={styles.chevron} src={chevronLeft} />
                        </Link>
                    </div>
                );
            }}
        />
    );
}
