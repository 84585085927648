import React from "react";

export function IconOpenGallery() {
    return (
        <svg width="62" height="60" viewBox="0 0 62 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 2C0 0.895431 0.895431 0 2 0H59.1579C60.2625 0 61.1579 0.895431 61.1579 2V57.5897C61.1579 58.6943 60.2625 59.5897 59.1579 59.5897H2C0.895432 59.5897 0 58.6943 0 57.5897V2Z"
                fill="#1A3A45"
            />
            <path
                d="M52.1058 16.8145H9.05129C8.29112 16.8145 7.56208 17.1164 7.02456 17.654C6.48704 18.1915 6.18506 18.9205 6.18506 19.6807V47.4892C6.18506 48.2494 6.48704 48.9784 7.02456 49.516C7.56208 50.0535 8.29112 50.3555 9.05129 50.3555H52.1058C52.8659 50.3555 53.595 50.0535 54.1325 49.516C54.67 48.9784 54.972 48.2494 54.972 47.4892V19.6807C54.972 18.9205 54.67 18.1915 54.1325 17.654C53.595 17.1164 52.8659 16.8145 52.1058 16.8145ZM51.9228 47.3063H9.23424V19.8636H51.9228V47.3063Z"
                fill="white"
            />
            <path
                d="M16.1871 31.222C17.0917 31.222 17.976 30.9538 18.7281 30.4512C19.4803 29.9486 20.0665 29.2343 20.4127 28.3985C20.7589 27.5628 20.8494 26.6432 20.6729 25.7559C20.4965 24.8687 20.0609 24.0537 19.4212 23.4141C18.7815 22.7744 17.9666 22.3388 17.0794 22.1623C16.1921 21.9859 15.2725 22.0764 14.4367 22.4226C13.601 22.7688 12.8867 23.355 12.3841 24.1072C11.8815 24.8593 11.6133 25.7436 11.6133 26.6482C11.6133 27.8613 12.0952 29.0246 12.9529 29.8824C13.8107 30.7401 14.974 31.222 16.1871 31.222ZM16.1871 24.2089C16.6719 24.1968 17.1494 24.3295 17.5585 24.59C17.9676 24.8506 18.2897 25.2272 18.4837 25.6717C18.6777 26.1162 18.7348 26.6085 18.6477 27.0856C18.5606 27.5628 18.3332 28.0031 17.9946 28.3504C17.656 28.6977 17.2216 28.9361 16.7468 29.0353C16.272 29.1345 15.7785 29.0898 15.3292 28.9071C14.8799 28.7244 14.4952 28.412 14.2244 28.0096C13.9536 27.6072 13.8088 27.1333 13.8087 26.6482C13.8085 26.0117 14.0571 25.4003 14.5015 24.9446C14.9458 24.4888 15.5507 24.2248 16.1871 24.2089Z"
                fill="white"
            />
            <path
                d="M15.1813 44.2574L24.3288 35.1099L29.177 39.9581L24.8777 44.2574H27.9269L39.3003 32.884L48.8748 42.3822V39.333L40.0321 30.5361C39.8294 30.335 39.5554 30.2222 39.2698 30.2222C38.9843 30.2222 38.7103 30.335 38.5075 30.5361L30.6406 38.403L25.0454 32.823C24.8427 32.6219 24.5687 32.5091 24.2831 32.5091C23.9975 32.5091 23.7235 32.6219 23.5208 32.823L12.1626 44.2574H15.1813Z"
                fill="white"
            />
            <path
                d="M49.0878 6.14202C49.0878 5.73768 48.9271 5.34989 48.6412 5.06397C48.3553 4.77806 47.9675 4.61743 47.5632 4.61743H14.0222C13.6178 4.61743 13.23 4.77806 12.9441 5.06397C12.6582 5.34989 12.4976 5.73768 12.4976 6.14202V7.66662H49.0878V6.14202Z"
                fill="white"
            />
            <path
                d="M52.1051 12.2402C52.1051 11.8358 51.9445 11.448 51.6585 11.1621C51.3726 10.8762 50.9848 10.7156 50.5805 10.7156H10.9411C10.5367 10.7156 10.149 10.8762 9.86305 11.1621C9.57713 11.448 9.4165 11.8358 9.4165 12.2402V13.7648H52.1051V12.2402Z"
                fill="white"
            />
        </svg>
    );
}
