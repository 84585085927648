import React, { useState } from "react";
import { BLOG_TYPE, IPageMdl, PAGE_TYPE } from "pages/_models/PageMdl";
import { ComponentRenderer } from "components/ComponentRenderer";
import { PageRendererContext } from "pages/PageContext";
import { PageRendererStore } from "pages/_stores/PageRendererStore";
import { i18nextInstance } from "_common/i18n/IntlProvider";
import { TLang } from "_configs/sharedConfig";
import { Container, NoSsr } from "@material-ui/core";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { useGetItemsForBreadCrumb } from "_common/_utils/pageUtils";
import { PageMeta } from "pages/PageMeta";
import { useHomePage } from "_common/_utils/hookUtils";
import clsx from "clsx";
import styles from "./_css/pageRenderer.module.css";
import { ArticlesSimilar } from "blog/ArticlesSimilar";
import { HomeContactUs } from "home/HomeContactUs";
import { ArticleSideBar } from "blog/ArticleSideBar";
import { AC_TAGS } from "users/_models/UserMdl";
import { BlogHeader } from "pages/blog/BlogHeader";
import { HomeCardTypes } from "home/HomeCardTypes";
import { HomeCardCities } from "home/HomeCardCities";

type Props = {
    page: IPageMdl;
};

export function PageRenderer(props: Props) {
    const isHomePage = useHomePage();
    const items = useGetItemsForBreadCrumb(props.page);
    const localizedPage = props.page.localized[i18nextInstance.language as TLang];
    const isBlogPage = props.page.type === PAGE_TYPE.ARTICLE;
    const [isOpen, setIsOpen] = useState(true);
    if (!localizedPage) return null;
    return (
        <PageRendererContext pageRendererStore={new PageRendererStore()}>
            <PageMeta page={props.page} />
            {!isHomePage && (
                <Container>
                    <Breadcrumb currentItem={{ itemLabel: localizedPage.title }} items={items} />
                </Container>
            )}
            {isBlogPage && (
                <>
                    <BlogHeader localizedPage={localizedPage} page={props.page} isOpen={isOpen} />
                </>
            )}
            {isBlogPage ? (
                <>
                    <NoSsr>
                        <ArticleSideBar isOpen={isOpen} setIsOpen={setIsOpen} blog={props.page} page={props.page} />
                    </NoSsr>
                    <div className={"flex_row"}>
                        <div className={styles.placeholder} style={{ minWidth: isOpen ? 320 : 0 }} />
                        <div className={styles.blogContainer}>
                            <div className={"flex_column_center"}>
                                <div>
                                    <ComponentRenderer component={localizedPage.component} isRoot />
                                </div>
                                <NoSsr>
                                    <div className={clsx("mh_20", styles.similarArticle)}>
                                        <ArticlesSimilar
                                            blogId={props.page._id}
                                            blogType={props.page.blogType as BLOG_TYPE}
                                        />
                                    </div>
                                </NoSsr>
                                <div className={"mt_40"}>
                                    <HomeCardTypes />
                                </div>
                                <div className={"mt_20"}>
                                    <HomeCardCities />
                                </div>
                                <HomeContactUs
                                    className={"mb_60"}
                                    acTags={AC_TAGS.L7_BLOG}
                                    blogType={props.page.blogType}
                                    blogTitle={localizedPage.title}
                                />
                            </div>
                        </div>
                        <div className={styles.placeholder} />
                    </div>
                </>
            ) : (
                <ComponentRenderer component={localizedPage.component} isRoot />
            )}
        </PageRendererContext>
    );
}
