import React from "react";
import styles from "./_css/propertyOrUnitGalleryMobile.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import { userStore } from "users/_stores/userStore";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { UnitStore } from "units/_stores/UnitStore";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import play from "../../assets/images/icons/play.svg";
import photos from "../../assets/images/icons/photos.svg";
import floorPlans from "../../assets/images/icons/floorPlans.svg";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";
import clsx from "clsx";
import { reformatImagesForGallery } from "_common/_utils/propertyUtils";
import { PropertyOrUnitGalleryOpenBtn } from "properties/gallery/PropertyOrUnitGalleryOpenBtn";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { PhaseLabel } from "properties/gallery/PhaseLabel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDevicePixelRatio } from "_common/_utils/useDevicePixelRatio";
import { UnitLeftLabel } from "properties/gallery/UnitLeftLabel";
import { UnitLeftLabelForUnit } from "properties/gallery/UnitLeftLabelForUnit";
import { PhaseLabelForUnit } from "properties/gallery/PhaseLabelForUnit";
import { NoSsr } from "@material-ui/core";

SwiperCore.use([Navigation, Thumbs]);

type Props = {
    store: PropertyStore | UnitStore;
    resource: RESOURCE;
    onFooter?: boolean;
    numberOfUnitLeft: number;
};

export function PropertyOrUnitGalleryMobile(props: Props) {
    const store = props.resource === RESOURCE.PROPERTIES ? (props.store as PropertyStore) : (props.store as UnitStore);
    const devicePixelRatio = useDevicePixelRatio();
    const item =
        props.resource === RESOURCE.PROPERTIES
            ? (props.store as PropertyStore).property
            : (props.store as UnitStore).unit;
    const { t } = useTranslation();
    if (!item || !item.photos || item.photos.length === 0) return null;
    const reformattedItem = reformatImagesForGallery({ ...item });
    if (userStore.isLogged && !userStore.favoritesPropertiesStates.isSucceeded) userStore.fetchFavoritesProperties();
    return (
        <div id={"propertyGalleryMobile"} className={"position_relative"}>
            {props.resource === RESOURCE.PROPERTIES && (item as TPropertyListingMdl).sold && (
                <div className={clsx(styles.tag, "flex_center_center")}>{t("unit.soldOut")}</div>
            )}
            {!(item as TPropertyListingMdl)?.hideInventory && (
                <div className={clsx("position_absolute flex_row mt_20", styles.unitsLabel)}>
                    {props.resource === RESOURCE.UNITS ? (
                        <UnitLeftLabelForUnit numberOfUnitLeft={props.numberOfUnitLeft} />
                    ) : (
                        <UnitLeftLabel numberOfUnitLeft={props.numberOfUnitLeft} />
                    )}
                </div>
            )}
            {!(item as TPropertyListingMdl)?.hideInventory && (
                <NoSsr>
                    <div className={clsx("position_absolute flex_row mt_20", styles.phaseLabel)}>
                        {props.resource === RESOURCE.UNITS ? <PhaseLabelForUnit /> : <PhaseLabel />}
                    </div>
                </NoSsr>
            )}
            <Swiper navigation loop observer observeParents initialSlide={0}>
                {reformattedItem.photos.map((image, index) => {
                    let imageUrl;
                    if (devicePixelRatio > 1) {
                        imageUrl = image.url;
                    } else {
                        imageUrl = image.thumbnail ?? image.url;
                    }
                    return (
                        <SwiperSlide key={image.url + index} className={styles.swiperContainer}>
                            <div className={"position_relative"}>
                                {index === 0 ? (
                                    <img
                                        onClick={() => store.openGallery()}
                                        alt={`${reformattedItem.localized.title}-${index}`}
                                        title={`${reformattedItem.localized.title} photo ${index}`}
                                        className={styles.mainPhoto}
                                        src={imageUrl}
                                        srcSet={
                                            props.resource === RESOURCE.PROPERTIES
                                                ? `${
                                                      (item as TPropertyListingMdl)?.premiumPhotos?.[0]?.sm ?? imageUrl
                                                  } 600w, ${
                                                      (item as TPropertyListingMdl)?.premiumPhotos?.[0]?.md ?? imageUrl
                                                  } 960w, ${
                                                      (item as TPropertyListingMdl)?.premiumPhotos?.[0]?.lg ?? imageUrl
                                                  } 1920w`
                                                : imageUrl
                                        }
                                    />
                                ) : (
                                    <LazyLoadImage
                                        onClick={() => store.openGallery()}
                                        alt={`${reformattedItem.localized.title}-${index}`}
                                        title={`${reformattedItem.localized.title} photo ${index}`}
                                        className={styles.mainPhoto}
                                        src={imageUrl}
                                    />
                                )}
                                {image?.title && (
                                    <div
                                        className={clsx(styles.overlay, "flex_column_center")}
                                        onClick={() => store.openGallery(0, image?.title as GALLERY_TABS)}
                                    >
                                        <LazyLoadImage
                                            src={
                                                image?.title === GALLERY_TABS.VIDEO
                                                    ? play
                                                    : image?.title === GALLERY_TABS.GALLERY
                                                    ? photos
                                                    : floorPlans
                                            }
                                            className={styles.icon}
                                            alt={"overlay see more"}
                                        />
                                        <div className={styles.textOverlay}>
                                            {image?.title === GALLERY_TABS.VIDEO
                                                ? "See more videos"
                                                : image?.title === GALLERY_TABS.GALLERY
                                                ? "See more photos"
                                                : "See more floors plans"}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div
                className={clsx("position_absolute flex_center_center", styles.navigation)}
                onClick={() => store.openGallery()}
            >
                <PropertyOrUnitGalleryOpenBtn />
            </div>
        </div>
    );
}
