import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./_css/premiumProperties.module.css";
import "swiper/swiper-bundle.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import SwiperCore, { Pagination } from "swiper";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet-async";
import { reformatUrlForOriginalName } from "_common/_utils/alphaNumUtils";

SwiperCore.use([Pagination]);

type Props = {
    properties: TPropertyListingMdl[];
};

export function PremiumProperties(props: Props) {
    return (
        <>
            <Helmet>
                <link
                    rel="preload"
                    as="image"
                    href={props.properties?.[0]?.photos?.[0]?.url}
                    imagesrcset={`${
                        props.properties?.[0]?.premiumPhotos?.[0]?.sm ?? props.properties?.[0]?.photos[0].url
                    } 600w, ${
                        props.properties?.[0]?.premiumPhotos?.[0]?.md ?? props.properties?.[0]?.photos[0].url
                    } 960w, ${
                        props.properties?.[0]?.premiumPhotos?.[0]?.lg ?? props.properties?.[0]?.photos[0].url
                    } 1920w`}
                    sizes={"100vw"}
                />{" "}
                <link
                    rel="preload"
                    as="image"
                    href={props.properties?.[2]?.photos?.[0]?.url}
                    imagesrcset={`${
                        props.properties?.[2]?.premiumPhotos?.[0]?.sm ?? props.properties?.[2]?.photos[0].url
                    } 600w, ${
                        props.properties?.[2]?.premiumPhotos?.[0]?.md ?? props.properties?.[2]?.photos[0].url
                    } 960w, ${
                        props.properties?.[2]?.premiumPhotos?.[0]?.lg ?? props.properties?.[2]?.photos[0].url
                    } 1920w`}
                    sizes={"100vw"}
                />{" "}
                <link
                    rel="preload"
                    as="image"
                    href={props.properties?.[1]?.photos?.[0]?.url}
                    imagesrcset={`${
                        props.properties?.[1]?.premiumPhotos?.[0]?.sm ?? props.properties?.[1]?.photos[0].url
                    } 600w, ${
                        props.properties?.[1]?.premiumPhotos?.[0]?.md ?? props.properties?.[1]?.photos[0].url
                    } 960w, ${
                        props.properties?.[1]?.premiumPhotos?.[0]?.lg ?? props.properties?.[1]?.photos[0].url
                    } 1920w`}
                    sizes={"100vw"}
                />
            </Helmet>
            <Swiper
                id={"premiumPropertyImg"}
                navigation
                loop
                autoplay={{ delay: 5000, disableOnInteraction: true }}
                pagination={{
                    clickable: true,
                }}
                lazy={{
                    loadPrevNext: true,
                }}
                initialSlide={0}
            >
                {props.properties.map((property, index) => {
                    if (!property) return;
                    const isImageToDisplay = !!property.photos?.[0]?.url || !!property.premiumPhotos?.[0]?.sm;
                    return (
                        <SwiperSlide key={property._id}>
                            <Link
                                to={URLS.property(property.localized.urlAlias)}
                                aria-label={property.localized.title}
                                aria-labelledby={property.localized.title}
                            >
                                <div className="position_relative">
                                    <div className={clsx(styles.place, "flex_row position_absolute p_20 pv_5")}>
                                        <div>
                                            <div className={styles.pinTitle}>{property.localized.title}</div>
                                            <div className={styles.cityRegion}>
                                                {property.address.city},{" "}
                                                <span className={styles.region}>
                                                    {reformatUrlForOriginalName(property.address.province)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {isImageToDisplay && (
                                        <>
                                            {index < 3 ? (
                                                <img
                                                    src={property?.photos?.[0]?.url}
                                                    alt={property?.photos?.[0]?.alt}
                                                    className={styles.img}
                                                    srcSet={`${
                                                        property?.premiumPhotos?.[0]?.sm ?? property?.photos?.[0]?.url
                                                    } 600w, ${
                                                        property?.premiumPhotos?.[0]?.md ?? property?.photos?.[0]?.url
                                                    } 960w, ${
                                                        property?.premiumPhotos?.[0]?.lg ?? property?.photos?.[0]?.url
                                                    } 1920w`}
                                                />
                                            ) : (
                                                <LazyLoadImage
                                                    src={property?.photos?.[0]?.url}
                                                    alt={property?.photos?.[0]?.alt}
                                                    className={styles.img}
                                                    srcSet={`${
                                                        property?.premiumPhotos?.[0]?.sm ?? property?.photos?.[0]?.url
                                                    } 600w, ${
                                                        property?.premiumPhotos?.[0]?.md ?? property?.photos?.[0]?.url
                                                    } 960w, ${
                                                        property?.premiumPhotos?.[0]?.lg ?? property?.photos?.[0]?.url
                                                    } 1920w`}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </Link>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
}
