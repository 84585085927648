import React from "react";
import styles from "./_css/input.module.css";
import clsx from "clsx";
import { FieldError } from "react-hook-form/dist/types/form";

type Props<T = string> = React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
> & {
    error?: FieldError;
    onValueChange?: (value: T, event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    resizeable?: boolean;
    rows?: number;
    theme?: "white" | "gray" | "label";
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
    ({ error, className, onValueChange, onChange, ...props }, ref) => {
        return (
            <>
                <textarea
                    {...props}
                    className={clsx(
                        styles.input,
                        styles.input_default,
                        styles.textarea,
                        {
                            [styles.input_label]: props.theme === "label",
                            [styles.input_white]: props.theme === "white",
                            [styles.input_gray]: props.theme === "gray" || props.disabled,
                            [styles.input_error]: error,
                        },
                        className,
                    )}
                    ref={ref}
                    onChange={(event) => {
                        if (onChange) onChange(event);
                        if (onValueChange) onValueChange(event.target.value, event);
                    }}
                />
                {error && <div className={styles.error}>{error.message}</div>}
            </>
        );
    },
);
