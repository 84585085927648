import React from "react";

type Props = {
    color?: string;
    size?: string;
};

export function IconSquare(props: Props) {
    return (
        <svg
            width={props.size ?? "25"}
            height={props.size ?? "25"}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.91667 0H7.08333H0V7.08333V7.91667V25H6.66667V7.91667H7.91667V6.66667H25V0H7.91667ZM5.83333 9.58333H4.58333C4.35292 9.58333 4.16667 9.76958 4.16667 10C4.16667 10.2304 4.35292 10.4167 4.58333 10.4167H5.83333V12.0833H3.33333C3.10292 12.0833 2.91667 12.2696 2.91667 12.5C2.91667 12.7304 3.10292 12.9167 3.33333 12.9167H5.83333V14.5833H4.58333C4.35292 14.5833 4.16667 14.7696 4.16667 15C4.16667 15.2304 4.35292 15.4167 4.58333 15.4167H5.83333V17.0833H3.33333C3.10292 17.0833 2.91667 17.2696 2.91667 17.5C2.91667 17.7304 3.10292 17.9167 3.33333 17.9167H5.83333V19.5833H4.58333C4.35292 19.5833 4.16667 19.7696 4.16667 20C4.16667 20.2304 4.35292 20.4167 4.58333 20.4167H5.83333V22.0833H3.33333C3.10292 22.0833 2.91667 22.2696 2.91667 22.5C2.91667 22.7304 3.10292 22.9167 3.33333 22.9167H5.83333V24.1667H0.833333V7.91667H5.83333V9.58333ZM7.08333 7.08333H6.66667H0.833333V0.833333H7.08333V6.66667V7.08333ZM24.1667 5.83333H22.9167V3.33333C22.9167 3.10292 22.7304 2.91667 22.5 2.91667C22.2696 2.91667 22.0833 3.10292 22.0833 3.33333V5.83333H20.4167V4.58333C20.4167 4.35292 20.2304 4.16667 20 4.16667C19.7696 4.16667 19.5833 4.35292 19.5833 4.58333V5.83333H17.9167V3.33333C17.9167 3.10292 17.7304 2.91667 17.5 2.91667C17.2696 2.91667 17.0833 3.10292 17.0833 3.33333V5.83333H15.4167V4.58333C15.4167 4.35292 15.2304 4.16667 15 4.16667C14.7696 4.16667 14.5833 4.35292 14.5833 4.58333V5.83333H12.9167V3.33333C12.9167 3.10292 12.7304 2.91667 12.5 2.91667C12.2696 2.91667 12.0833 3.10292 12.0833 3.33333V5.83333H10.4167V4.58333C10.4167 4.35292 10.2304 4.16667 10 4.16667C9.76958 4.16667 9.58333 4.35292 9.58333 4.58333V5.83333H7.91667V0.833333H24.1667V5.83333Z"
                fill={props.color ?? "black"}
            />
        </svg>
    );
}
