import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { fetchUtils } from "_common/_utils/fetchUtils";
import i18next from "i18next";
import { TUnitDashboardListingMdl, TUnitListingMdl } from "units/_models/UnitMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { TLang } from "_configs/sharedConfig";
import { getInitialStateValue } from "_common/_utils/initialStateUtils";

export class UnitsStore extends BaseResourceStore<TUnitListingMdl> {
    count = 0;

    constructor() {
        super("units");
        this.onInit();
    }

    protected onReset() {
        this.count = 0;
        super.onReset();
    }

    getByAliasUrl(urlAlias: string, propertyAlias: string, lang: string = i18next.language, wantedLanguage = "") {
        if (!wantedLanguage) {
            for (const unitId of Object.keys(this.cache)) {
                if (
                    this.cache[unitId]?.localized.urlAlias === urlAlias &&
                    this.cache[unitId]?.property.localized.urlAlias === propertyAlias &&
                    this.cache[unitId]?.property?.inventory
                ) {
                    return this.cache[unitId];
                }
            }
        }
        const url = `${this.apiPath}/urlAlias/${urlAlias}/${propertyAlias}/${lang}/${wantedLanguage}`;
        return fetchUtils
            .get<TUnitListingMdl>(url)
            .then(({ data }) => {
                const formatedItem = this.reformatItem(data);
                this.putItemInCache(formatedItem);
                return formatedItem;
            })
            .catch((e) => console.error(e));
    }

    list(_offset?: number, _limit?: number, listId?: string, _sort?: { [p: string]: number }, _filters?: TFilter[]) {
        const url = `${this.apiPath}/property/${listId}`;
        return fetchUtils
            .get<TUnitListingMdl[]>(url)
            .then(({ data }) => {
                data.map((item) => {
                    const formatedItem = this.reformatItem(item);
                    this.putItemInCache(formatedItem);
                    return formatedItem;
                });
                return { count: data.length, items: data };
            })
            .catch((e) => console.error(e));
    }

    dashboardListing(token?: string) {
        const url = `${this.apiPath}/dashboardListing${token ? `/${token}` : ""}`;
        return fetchUtils.get<TUnitDashboardListingMdl[]>(url).then(({ data }) => {
            data.map((item) => {
                const formatedItem = this.reformatItem(item);
                this.putItemInCache(formatedItem);
                return formatedItem;
            });
            return { count: data.length, items: data };
        });
    }

    protected reformatItem(item: TUnitListingMdl) {
        const formattedItem = { ...item };
        if ("en" in formattedItem.localized) {
            formattedItem.localized = formattedItem.localized[i18next.language as TLang];
        }
        return super.reformatItem(formattedItem);
    }

    protected onInit(_fromRootCtor?: boolean) {
        super.onInit(_fromRootCtor);
        const countInitialState = getInitialStateValue("units");
        if (countInitialState) {
            this.count = countInitialState.count;
        }
    }
}

const unitsStore = new UnitsStore();
export { unitsStore };
