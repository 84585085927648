import React from "react";
import { userStore } from "users/_stores/userStore";
import { observer } from "mobx-react";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import styles from "properties/_css/propertyToFavorite.module.css";
import { toast } from "react-toastify";
import { IconButton } from "@material-ui/core";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { IconFavorite } from "properties/propertyPage/icons/IconFavorite";
import { useHistory } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";

type Props = {
    item: TPropertyListingMdl | TUnitListingMdl;
    type: RESOURCE;
    button?: boolean;
    isItemPage?: boolean;
    isForCard?: boolean;
    label?: string;
    size?: string;
    weight?: number;
    color?: string;
    direction?: "row" | "column";
    variant?: "contained" | "outlined";
    noShadow?: boolean;
    className?: string;
};

const ToFavorite = observer((props: Props) => {
    const history = useHistory();
    let isFavorite = false;
    let toggleOnClick: () => void;
    switch (props.type) {
        case RESOURCE.PROPERTIES:
            isFavorite = !!userStore.favoritesProperties.find((property) => property._id === props.item._id);
            toggleOnClick = () =>
                userStore.togglePropertyToFavorites(props.item as TPropertyListingMdl).catch((e) => {
                    toast.error(e);
                });
            break;
        case RESOURCE.UNITS:
            isFavorite = !!userStore.favoritesUnits.find((unit) => unit._id === props.item._id);
            toggleOnClick = () =>
                userStore.toggleUnitsToFavorites(props.item as TUnitListingMdl).catch((e) => {
                    toast.error(e);
                });
            break;
    }
    return (
        <div
            className={clsx(
                props.direction ? `flex_${props.direction}_center` : "flex_center_center",
                styles.container,
            )}
        >
            {props.button ? (
                <UiButton
                    aria-label={"Favorite button"}
                    className={clsx(
                        styles.favoriteButton,
                        {
                            [styles.noShadow]: props.noShadow,
                        },
                        props.className,
                    )}
                    variant={props.variant ?? "contained"}
                    onClick={() => {
                        userStore.isLogged
                            ? toggleOnClick()
                            : geoZonesStore.geoZone
                            ? userStore.openSignInWithModal()
                            : history.push(URLS.auth.signIn());
                    }}
                >
                    {isFavorite ? (
                        <IconFavorite color={props.color ?? "black"} size={props.size ?? "44"} isFavorite />
                    ) : (
                        <IconFavorite color={props.color ?? "black"} size={props.size ?? "44"} />
                    )}
                    {props.label && (
                        <div
                            className={clsx(styles.label, "ml_5")}
                            style={{ fontWeight: props.weight, color: props.color }}
                        >
                            {props.label}
                        </div>
                    )}
                </UiButton>
            ) : (
                <>
                    <IconButton
                        aria-label={"Favorite button"}
                        className={clsx(
                            "p_5",
                            styles.iconButtonFavorite,
                            {
                                [styles.isItemPage]: props.isItemPage,
                                [styles.isForCard]: props.isForCard,
                            },
                            props.className,
                        )}
                        onClick={() => {
                            userStore.isLogged
                                ? toggleOnClick()
                                : geoZonesStore.geoZone
                                ? userStore.openSignInWithModal()
                                : history.push(URLS.auth.signIn());
                        }}
                    >
                        {isFavorite ? (
                            <IconFavorite color={props.color ?? "black"} size={props.size ?? "44"} isFavorite />
                        ) : (
                            <IconFavorite color={props.color ?? "black"} size={props.size ?? "44"} />
                        )}
                    </IconButton>
                    {props.label && (
                        <div className={styles.label} style={{ fontWeight: props.weight, color: props.color }}>
                            {props.label}
                        </div>
                    )}
                </>
            )}
        </div>
    );
});

export function ToggleToFavoriteBtn(props: Props) {
    let loading;
    if (!userStore.isLogged) return <ToFavorite {...props} />;
    switch (props.type) {
        case RESOURCE.PROPERTIES:
            if (!userStore.favoritesPropertiesStates.isSucceeded) userStore.fetchFavoritesProperties();
            loading = userStore.favoritesPropertiesStates;
            break;
        case RESOURCE.UNITS:
            if (!userStore.favoritesUnitsStates.isSucceeded) userStore.fetchFavoritesUnits();
            loading = userStore.favoritesUnitsStates;
            break;
    }
    if (!loading) return null;
    return (
        <LoadableFromLoading
            noSpinner
            noText
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            loading={loading}
            renderer={(_status, error) => {
                if (error) return null;
                return <ToFavorite {...props} />;
            }}
        />
    );
}
