import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { TPropertyConstructProgress } from "properties/_models/PropertyConstructProgressMdl";
import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { observable } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";

export class PropertyConstructProgressesAdminStore extends BaseResourceStore<TPropertyConstructProgress> {
    @observable constructProgressesState = new LoadingStateMdl<{
        items: TPropertyConstructProgress[];
        count: number;
    }>();

    constructor() {
        super("propertyConstructProgresses");
    }

    getByPropertyId(propertyId: string) {
        return this.list(0, Number.MAX_SAFE_INTEGER, undefined, undefined, [
            {
                id: "propertyId",
                type: TFilterType.ID,
                value: propertyId,
            },
        ]);
    }

    fetchConstructProgresses(propertyId: string) {
        const promise = this.getByPropertyId(propertyId);
        this.constructProgressesState.startLoading(promise);
        promise.then(({ items, count }) => {
            this.constructProgressesState.setSuccess({ items, count });
        });
        return this.constructProgressesState;
    }
}

const propertyConstructProgressesAdminStore = new PropertyConstructProgressesAdminStore();
export { propertyConstructProgressesAdminStore };
