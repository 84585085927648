import React from "react";
import { observer } from "mobx-react";
import { Paginator } from "admin/_common/list/Paginator";
import { ListStore } from "_common/list/ListStore";

type Props = {
    listStore: ListStore<any>;
    variant?: "text" | "outlined";
    shape?: "round" | "rounded";
    color?: "primary" | "secondary" | "standard";
};

export const ListStorePaginator = observer((props: Props) => {
    return (
        <Paginator
            onPageClick={(page) => props.listStore.setSelectedPage(page)}
            count={props.listStore.count}
            selectedPage={props.listStore.selectedPage}
            pageSize={props.listStore.pageSize}
            variant={props.variant}
            shape={props.shape}
            color={props.color}
        />
    );
});
