import { BaseResourceStore, TInitialState } from "_common/resources/BaseResourceStore";
import { GEO_ZONE_TYPE, TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { fetchUtils } from "_common/_utils/fetchUtils";
import { action, computed, observable } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { getResourceInitialStateValue } from "_common/_utils/initialStateUtils";
import { removeAccentFromString } from "_common/_utils/alphaNumUtils";
import _ from "lodash";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { DEFAULT_LOCATION } from "_common/_utils/searchUtils";
import { WithRequiredProperty } from "_common/types/GenericTypes";
import { TFilter } from "admin/_common/filters/TFilter";
import slugify from "slugify";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";

export class GeoZonesStore extends BaseResourceStore<WithRequiredProperty<TGeoZoneMdl, "_id">> {
    @observable geoZone: TGeoZoneMdl | undefined;
    @observable geoZoneState: LoadingStateMdl<TGeoZoneMdl | undefined> = new LoadingStateMdl<TGeoZoneMdl | undefined>(
        "IDLE",
    );

    @observable search = "";
    @observable selectedIndex = -1;
    @observable geoZones: TGeoZoneMdl[] = [];
    @observable geoZonesState: LoadingStateMdl<TGeoZoneMdl[]> = new LoadingStateMdl<TGeoZoneMdl[]>();
    @observable sortedChildGeoZones:
        | Pick<TGeoZoneMdl, "properties" | "_id" | "address" | "type" | "name">[]
        | undefined = undefined;
    @observable sortedChildGeoZonesState: LoadingStateMdl<
        Pick<TGeoZoneMdl, "properties" | "_id" | "address" | "type" | "name">[]
    > = new LoadingStateMdl();

    constructor() {
        super("geoZones");
        this.onInit();
    }

    @computed get address() {
        return {
            city: this.geoZone?.address?.city ?? undefined,
            province: this.geoZone?.address?.province ?? undefined,
            provinceLong: this.geoZone?.address?.provinceLong ?? undefined,
            neighbourhood: this.geoZone?.address?.neighbourhood ?? undefined,
        };
    }

    @computed get isType() {
        return {
            isCity: this.geoZone?.type === GEO_ZONE_TYPE.CITY,
            isProvince: this.geoZone?.type === GEO_ZONE_TYPE.PROVINCE,
            isNeighbourhood: this.geoZone?.type === GEO_ZONE_TYPE.NEIGHBORHOOD,
        };
    }

    @computed get isSearching() {
        return this.search.length > 1;
    }

    @action setSearch = _.debounce((s: string) => {
        this.search = s;
        this.fetchGeozones(slugify(s), 5);
        propertiesStore.fetchPropertiesForSearch(removeAccentFromString(s), 5);
    }, 400);

    @action resetGeoZone() {
        this.geoZone = undefined;
        this.geoZoneState.setStatus("IDLE");
    }

    @action setGeoZone(geoZone: TGeoZoneMdl) {
        this.geoZone = geoZone;
    }

    @computed get selectedResult() {
        return this.geoZones[this.selectedIndex];
    }

    @action setSelectedIndex(index: number) {
        this.selectedIndex = index;
    }

    @action pressArrowDown() {
        if (this.geoZones) {
            if (this.selectedIndex === this.geoZones.length - 1) {
                this.selectedIndex = 0;
            } else {
                this.selectedIndex += 1;
            }
        }
    }

    @action pressArrowUp() {
        if (this.geoZones) {
            if (this.selectedIndex <= 0) {
                this.selectedIndex = this.geoZones.length - 1;
            } else {
                this.selectedIndex -= 1;
            }
        }
    }

    @action setGeoZoneFromResults(
        results: {
            geometry: { location: { lat: () => number; lng: () => number } };
            address_components: { types: string; long_name: string; short_name: string }[];
            formatted_address: string;
            types: string[];
        },
        regionLocation?: { osmId: number; coordinates: number[][] | number[][][] } | undefined,
    ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.geoZone = this.getGeoZoneFromResult(results, regionLocation);
    }

    @action
    async createGeoZone(
        results: {
            geometry: { location: { lat: () => number; lng: () => number } };
            address_components: { types: string; long_name: string; short_name: string }[];
            formatted_address: string;
            types: string[];
        },
        regionLocation?: { osmId: number; coordinates: number[][] | number[][][] } | undefined,
    ) {
        this.resetGeoZone();
        this.geoZoneState.startLoading();
        const geoZone = this.getGeoZoneFromResult(results, regionLocation);
        this.geoZone = await this.create(geoZone);
        if (this.geoZone) this.geoZoneState.setSuccess(this.geoZone);
        else this.geoZoneState.setStatus("IDLE");
    }

    private getGeoZoneFromResult(
        results: {
            geometry: { location: { lat: () => number; lng: () => number } };
            address_components: { types: string; long_name: string; short_name: string }[];
            formatted_address: string;
            types: string[];
        },
        regionLocation?: { osmId: number; coordinates: number[][] | number[][][] } | undefined,
    ): Omit<TGeoZoneMdl, "localized"> {
        const addressComponents = results.address_components;
        const isNeighborhood = results.types.includes("sublocality") || results.types.includes("neighborhood");
        const locality = addressComponents.find((addressComponent) => addressComponent.types.includes("locality"));
        const region = addressComponents.find((addressComponent) =>
            addressComponent.types.includes("administrative_area_level_1"),
        );
        const neighbourhood = addressComponents.find((addressComponent) =>
            addressComponent.types.includes("neighborhood"),
        );
        const sublocality = addressComponents.find((addressComponent) =>
            addressComponent.types.includes("sublocality"),
        );
        const zoneName = results.formatted_address.split(",")[0];
        const geoZone = {
            name: removeAccentFromString(results.formatted_address),
            location: {
                type: "Point",
                coordinates: [results.geometry.location.lng(), results.geometry.location.lat()],
            },
            osmId: regionLocation ? regionLocation?.osmId.toString() : "",
            address: {
                city: removeAccentFromString(locality ? locality?.short_name ?? locality?.long_name : zoneName ?? ""),
                province: region?.short_name ?? region?.long_name ?? "",
                provinceLong: region?.long_name ?? "",
                neighbourhood: neighbourhood?.short_name ?? sublocality?.short_name ?? "",
            },
            type: isNeighborhood ? GEO_ZONE_TYPE.NEIGHBORHOOD : GEO_ZONE_TYPE.CITY,
            slug: "",
            zoneLocations: [DEFAULT_LOCATION],
        };
        if (regionLocation) {
            const zoneLocations = this.getZoneLocation(regionLocation);
            if (zoneLocations) {
                geoZone["zoneLocations"] = zoneLocations;
            }
        }
        return geoZone;
    }

    async getOpenStreetMapGeoZoneFromGeographicName(
        formattedAddress: string,
    ): Promise<{ osmId: number; coordinates: number[][] } | undefined> {
        const { data: zones } = await fetchUtils.get<
            { category: string; geojson: { coordinates: any }; osm_id: number }[]
        >(`https://nominatim.openstreetmap.org/search.php?q=${formattedAddress}&polygon_geojson=1&format=jsonv2`);

        if (zones && zones.length > 0) {
            const zoneObject = zones.find((zone) => zone.category === "boundary") ?? zones[0];
            return { coordinates: zoneObject.geojson.coordinates, osmId: zoneObject.osm_id };
        } else return undefined;
    }

    protected onInit(_fromRootCtor?: boolean) {
        this.geoZoneState.startLoading();
        const initialState = getResourceInitialStateValue("geoZone") as
            | TInitialState<WithRequiredProperty<TGeoZoneMdl, "_id">>
            | undefined;
        if (initialState) {
            this.geoZoneState.setSuccess(this.geoZone);
            this.geoZone = initialState.items[0];
        } else this.geoZoneState.setStatus("IDLE");
    }

    private getZoneLocation(regionLocation: { osmId: number; coordinates: any[] } | undefined) {
        if (regionLocation?.coordinates && typeof regionLocation.coordinates[0] !== "number") {
            let locationLvl: number[][] = regionLocation.coordinates[0];
            const LIMIT = 4;
            let counter = 0;
            while (typeof locationLvl[0][0] !== "number" && counter <= LIMIT) {
                counter++;
                locationLvl = JSON.parse(JSON.stringify(locationLvl[0]));
            }
            return locationLvl.map((location) => ({
                lng: location[0],
                lat: location[1],
            }));
        } else {
            return undefined;
        }
    }

    fetchGeozones(value?: string, limit = 10, type?: GEO_ZONE_TYPE) {
        if (!this.geoZoneState.isLoading) {
            this.geoZonesState.startLoading();
            const filters: TFilter[] = [];
            value ? filters.push({ id: "slug", type: TFilterType.STRING, value }) : "";
            type ? filters.push({ id: "slug", type: TFilterType.STRING, value }) : "";

            const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
            const url = `${this.apiPath}/listing?limit=${limit}${filtersParam}`;
            return fetchUtils.get<{ items: TGeoZoneMdl[] }>(url).then(
                action(({ data: { items } }) => {
                    this.geoZones = items;
                    this.geoZonesState.setSuccess(items);
                }),
            );
        }
    }
    computeGeoZone(geoZoneId: string) {
        return fetchUtils.post<{ items: TGeoZoneMdl }>(`${this.apiPath}/computeGeoZone/${geoZoneId}`);
    }

    fetchSortedNeighborhoods(type: PROPERTY_TYPE) {
        if (!this.sortedChildGeoZonesState.isLoading && this.geoZone) {
            this.sortedChildGeoZonesState.startLoading();
            const url = `${this.apiPath}/childGeoZones/${this.geoZone._id}` + (type ? `/${type}` : "");
            const promise = fetchUtils.get<TGeoZoneMdl[]>(url);
            promise
                .then(
                    action(({ data }) => {
                        this.sortedChildGeoZones = data;
                        this.sortedChildGeoZonesState.setSuccess(data);
                    }),
                )
                .catch((e) => console.error(e));
        }
        return this.sortedChildGeoZonesState;
    }

    protected onReset() {
        this.geoZone = undefined;
        this.geoZones = [];
        this.geoZonesState = new LoadingStateMdl<TGeoZoneMdl[]>();
        this.geoZoneState = new LoadingStateMdl<TGeoZoneMdl | undefined>("IDLE");
        this.sortedChildGeoZones = [];
        this.sortedChildGeoZonesState = new LoadingStateMdl<
            Pick<TGeoZoneMdl, "properties" | "_id" | "address" | "type" | "name">[]
        >("IDLE");
        this.cache = {};
        this.listsStores = {};
        this.onInit();
    }
}

export const geoZonesStore = new GeoZonesStore();
