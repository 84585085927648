import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TUnitMdl } from "units/_models/UnitMdl";
import { TLang } from "_configs/sharedConfig";
import { s6 } from "_common/_utils/coreUtils";
import { createFilesData, storeFile } from "_common/_utils/fileUtils";

export class UnitsAdminStore extends BaseResourceStore<TUnitMdl> {
    constructor() {
        super("units");
    }

    async duplicate(itemId: string) {
        let item = await this.getAsync(itemId);
        if (!item) throw { key: "ITEM_NOT_FOUND" };
        for (const lang in item.localized) {
            item.localized[lang as TLang].urlAlias = item.localized[lang as TLang].urlAlias + s6();
            item.localized[lang as TLang].title = item.localized[lang as TLang].title + " - copy";
        }
        const photos = [];
        const photosForFileData = [];
        for (const photo of item.photos) {
            const blobImg = await fetch(photo.url).then((image) => image.blob());
            const blobUrl = storeFile(blobImg);
            photosForFileData.push(blobUrl);
            photos.push({ url: blobUrl });
        }
        const filesData = await createFilesData(photosForFileData, `photos.*.url`, 1920);
        item = {
            ...item,
            photos,
            floorPlans: [],
            price: { min: item.price.min * 100, max: (item.price?.max ?? 0) * 100 },
            priceInPesos: { min: (item?.priceInPesos?.min ?? 0) * 100, max: (item?.priceInPesos?.max ?? 0) * 100 },
        };
        delete item._id;
        return this.duplicateItem(item, filesData);
    }

    protected reformatItem(item: TUnitMdl) {
        const reformattedItem = super.reformatItem(item);
        return {
            ...reformattedItem,
            price: {
                min: reformattedItem.price.min / 100,
                max: (reformattedItem.price.max ?? 0) / 100,
            },
            priceInPesos: {
                min: (reformattedItem?.priceInPesos?.min ?? 0) / 100,
                max: (reformattedItem?.priceInPesos?.max ?? 0) / 100,
            },
        };
    }
}

const unitsAdminStore = new UnitsAdminStore();
export { unitsAdminStore };
