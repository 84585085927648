import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Link } from "react-router-dom";
import styles from "./_css/sliderLinks.module.css";
import { URLS } from "_configs/URLS";
import i18next from "i18next";
import clsx from "clsx";
import { Container } from "@material-ui/core";
import { TCity } from "_common/regions/regions";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { useForceRefreshPage } from "_common/_utils/hookUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    type: "propertyType" | "cities";
    className?: string;
    items: TCity[] | { name: PROPERTY_TYPE; img: string; nbOfProperties?: number }[];
};

type SlideProps = {
    type: "propertyType" | "cities";
    item: TCity[] | { name: PROPERTY_TYPE; img: string; nbOfProperties?: number };
    index: number;
};

SwiperCore.use([Autoplay, Navigation]);

function HomeCardListSlide(props: SlideProps) {
    const { t } = useTranslation();
    const { isBuyingOrIsRentingUrl, refreshPage, url } = useForceRefreshPage(
        props.type === "cities"
            ? URLS.buy(
                  i18next.language,
                  t("provinces.short." + props.item.region)
                      .replace(/ /g, "_")
                      .toLowerCase(),
                  props.item.name.replace(/ /g, "_").toLowerCase(),
              )
            : URLS.buy(i18next.language, t("property.typesUrl." + props.item.name).toLowerCase()),
    );
    return (
        <Link
            aria-labelledby={props.item.name}
            aria-label={props.item.name}
            onClick={(e) => {
                e.preventDefault();
                if (isBuyingOrIsRentingUrl) {
                    refreshPage();
                }
            }}
            to={url}
            className={clsx("flex_column_center", styles.item)}
        >
            <LazyLoadImage src={props.item.img} alt={props.item.name} className={styles.img} />
            <div className={styles.imgLabel}>
                {props.type === "propertyType"
                    ? t(`homePage.swiperLinks.${props.type}.${props.item.name.toLowerCase()}`)
                    : props.item.name}
            </div>
        </Link>
    );
}

export function HomeCardList(props: Props) {
    const { t } = useTranslation();
    return (
        <div id="homeCard">
            <Container>
                <h2 className={clsx("textAlign_center mb_60", styles.title)}>
                    {t(`homePage.swiperLinks.${props.type}.title`)}
                </h2>
            </Container>
            <div className={clsx("position_relative", styles.container)}>
                <Swiper
                    centeredSlides
                    loop
                    slidesPerView={5}
                    autoHeight
                    zoom={{
                        maxRatio: 5,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1.5,
                        },
                        680: {
                            slidesPerView: 2.5,
                        },
                        // 960: {
                        //     slidesPerView: 2.5,
                        // },
                        1150: {
                            slidesPerView: 3,
                        },
                        1280: {
                            slidesPerView: 3.5,
                        },
                        1400: {
                            slidesPerView: 4,
                        },
                        1500: {
                            slidesPerView: 4,
                        },
                        1650: {
                            slidesPerView: 4.5,
                        },
                        1800: {
                            slidesPerView: 5,
                        },
                    }}
                    spaceBetween={20}
                    className={styles.swiperContainer}
                    initialSlide={0}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {props.items.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <HomeCardListSlide type={props.type} item={item} index={index} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
}
