import React from "react";
import { observer } from "mobx-react";
import { useFormContext } from "react-hook-form";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { notifierStore } from "components/misc/contactUs/_stores/notifierStore";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { useTranslation } from "react-i18next";
import { TContactForm } from "components/misc/contactUs/ContactFormComponent";
import styles from "./_css/contactFormBtn.module.css";
import clsx from "clsx";
import { AC_TAGS } from "users/_models/UserMdl";
import { BLOG_TYPE } from "pages/_models/PageMdl";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { ButtonNotification } from "components/misc/notifications/ButtonNotification";
import { RecaptchaDisclaimer } from "components/misc/notifications/RecaptchaDisclamer";

type Props = {
    isForAppointment?: boolean;
    acTags?: AC_TAGS;
    blogType?: BLOG_TYPE;
    blogTitle?: string;
};
export const ContactFormBtn = observer((props: Props) => {
    const { handleSubmit } = useFormContext<TContactForm>();
    const { pathname } = useLocation();
    const { loading, setPromise } = useLoadingFromPromise();
    const isLoading = loading?.status === "LOADING";
    const { t } = useTranslation();
    return (
        <>
            {loading?.isSucceeded && <div id={"successRequest"}>{t("contactPage.succeededSend")}</div>}
            <ErrorBlock error={loading?.error} />
            <div className="p_20 pt_30 pb_10 flex_center_center">
                <ButtonNotification
                    className={clsx(styles.btn, "ph_40")}
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleSubmit<TContactForm>((data) => {
                        if (!isLoading) {
                            props.isForAppointment
                                ? setPromise(
                                      notifierStore.askForAgentAppointment({
                                          ...data,
                                          lang: i18next.language,
                                          url: pathname,
                                      }),
                                  )
                                : setPromise(
                                      notifierStore.contact({
                                          ...data,
                                          acTags: props.acTags,
                                          blogType: props.blogType,
                                          blogTitle: props.blogTitle,
                                          lang: i18next.language,
                                          url: pathname,
                                      }),
                                  );
                        }
                    })}
                >
                    {isLoading ? t("loaders.message") : t("words.submit")}
                </ButtonNotification>
            </div>
            <RecaptchaDisclaimer />
        </>
    );
});
