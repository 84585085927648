import React, { lazy } from "react";
import Suspenser from "_common/loaders/Suspenser";

const LazyMobileMenu = lazy(() => import("shared/main/header/menu/mobile/_MobileMenu"));

type Props = {
    isDashboardPage?: boolean;
};

export function MobileMenu(props: Props) {
    return (
        <Suspenser>
            <LazyMobileMenu isDashboardPage={props.isDashboardPage} />
        </Suspenser>
    );
}
