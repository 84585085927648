import React from "react";
import { IPageMdl } from "pages/_models/PageMdl";
import i18next from "i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

import clsx from "clsx";
import styles from "./_css/articleSearchCard.module.css";
import { observer } from "mobx-react";

type Props = {
    blog: IPageMdl;
};

export const ArticleSearchCard = observer((props: Props) => {
    const lang = i18next.language;
    return (
        <Link
            to={"/" + lang + props.blog.localized[i18next.language].url}
            className={clsx("pl_20 pv_20 flex_row_center", styles.container)}
        >
            <div className={"flex-4"}>{props.blog.localized[i18next.language].title}</div>
            <div className={"flex-1 flex_row justifyContent_flexEnd"}>
                <LazyLoadImage className={styles.image} src={props.blog.localized[i18next.language].image} />
            </div>
        </Link>
    );
});
