import React from "react";

type Props = {
    color?: string;
    size?: string;
};

export function IconShare(props: Props) {
    return (
        <svg
            width={props.size ?? "44"}
            height={props.size ?? "44"}
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.9999 14.8999C35.5898 14.8999 38.4999 11.9898 38.4999 8.3999C38.4999 4.81005 35.5898 1.8999 31.9999 1.8999C28.4101 1.8999 25.4999 4.81005 25.4999 8.3999C25.4999 11.9898 28.4101 14.8999 31.9999 14.8999Z"
                stroke={props.color ?? "black"}
                strokeWidth="2.5"
                strokeMiterlimit="10"
            />
            <path
                d="M31.9999 39C35.5898 39 38.4999 36.0899 38.4999 32.5C38.4999 28.9101 35.5898 26 31.9999 26C28.4101 26 25.4999 28.9101 25.4999 32.5C25.4999 36.0899 28.4101 39 31.9999 39Z"
                stroke={props.color ?? "black"}
                strokeWidth="2.5"
                strokeMiterlimit="10"
            />
            <path
                d="M7.8999 26.8999C11.4898 26.8999 14.3999 23.9898 14.3999 20.3999C14.3999 16.8101 11.4898 13.8999 7.8999 13.8999C4.31005 13.8999 1.3999 16.8101 1.3999 20.3999C1.3999 23.9898 4.31005 26.8999 7.8999 26.8999Z"
                stroke={props.color ?? "black"}
                strokeWidth="2.5"
                strokeMiterlimit="10"
            />
            <path
                d="M14.3999 20.0999L27.7999 27.4998"
                stroke={props.color ?? "black"}
                strokeWidth="2.5"
                strokeMiterlimit="10"
            />
            <path
                d="M12.2999 25L25.5999 32.5"
                stroke={props.color ?? "black"}
                strokeWidth="2.5"
                strokeMiterlimit="10"
            />
            <path
                d="M12.2999 15.8999L25.6999 8.3999"
                stroke={props.color ?? "black"}
                strokeWidth="2.5"
                strokeMiterlimit="10"
            />
            <path
                d="M14.9999 20.5999L28.2999 13.2"
                stroke={props.color ?? "black"}
                strokeWidth="2.5"
                strokeMiterlimit="10"
            />
        </svg>
    );
}
