import React from "react";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./_css/breadcrumb.module.css";
import clsx from "clsx";
import { refreshPage } from "_common/_utils/pageUtils";

export type TBreadcrumbItem = {
    itemLabelKey?: string;
    itemLabel?: string;
    url?: string;
    forceReload?: boolean;
};

type Props = {
    currentItem: TBreadcrumbItem;
    items?: TBreadcrumbItem[];
    noMarginBottom?: boolean;
    noHome?: boolean;
};

export function Breadcrumb(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, { ["mb_20"]: !props.noMarginBottom })}>
            <ul className={clsx("container")}>
                {!props.noHome && (
                    <li className="flex_row_center">
                        <Link to={URLS.home()}>{t("words.home")}</Link>
                    </li>
                )}
                {props.items &&
                    props.items.map((item, idx) => (
                        <li className="flex_row_center" key={idx}>
                            <Link
                                onClick={(e) => {
                                    if (item.forceReload && item.url) {
                                        e.preventDefault();
                                        refreshPage(item.url);
                                    }
                                }}
                                to={item.url}
                            >
                                {item.itemLabel ?? t(item.itemLabelKey ?? "")}
                            </Link>
                        </li>
                    ))}
                <li>{props.currentItem.itemLabel ?? t(props.currentItem.itemLabelKey ?? "")}</li>
            </ul>
        </div>
    );
}
