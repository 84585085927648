import React from "react";
import { QuestionComponent } from "components/base/question/QuestionComponent";
import { Container } from "@material-ui/core";
import styles from "./_css/homeFaq.module.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import i18next from "i18next";

const QUESTIONS = [
    {
        question: "homePage.faq.question1",
        answer: "homePage.faq.answer1",
        isOpen: true,
    },
    {
        question: "homePage.faq.question2",
        answer: "homePage.faq.answer2",
        isOpen: false,
    },
    {
        question: "homePage.faq.question3",
        answer: "homePage.faq.answer3",
        isOpen: false,
    },
];

export function HomeFaq() {
    const { t } = useTranslation();
    const lang = i18next.language;
    return (
        <Container>
            <div className={styles.container}>
                <h2 className={clsx(styles.title, "textAlign_center")}>{t("homePage.faq.title")}</h2>
                {QUESTIONS.map((faq, idx) => (
                    <QuestionComponent
                        key={idx}
                        open={faq.isOpen}
                        collapse
                        value={{
                            question: t(faq.question),
                            answer: t(faq.answer),
                        }}
                    />
                ))}
                <h2 className={clsx(styles.title, "mt_80 textAlign_center")}>{t("homePage.faq.seeMoreTitle")}</h2>
                <div className={"flex_center_center"}>
                    <Link to={URLS.faq(lang === "zh" ? "en" : lang)}>
                        <UiButton className={clsx(styles.btn)} variant={"contained"} color={"inherit"}>
                            {t("homePage.faq.seeMore")}
                        </UiButton>
                    </Link>
                </div>
            </div>
        </Container>
    );
}
