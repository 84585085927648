import sharedConfig from "_configs/sharedConfig";

export abstract class Recaptcha {
    getReCaptchaToken() {
        return new Promise<string>((resolve, reject) => {
            (window as any).grecaptcha.ready(() => {
                (window as any).grecaptcha
                    .execute(sharedConfig.reCaptcha.siteKey, { action: "submit" })
                    .then((token: string) => {
                        resolve(token);
                    })
                    .catch((error: Error) => {
                        reject(error);
                    });
            });
        });
    }
}
