import { i18nextInstance } from "_common/i18n/IntlProvider";

export const URLS = {
    home: (lang?: string) => `/${lang === "en" || !lang ? "" : lang || i18nextInstance.language}`,

    auth: {
        base: (lang?: string) => `/${lang || i18nextInstance.language}/auth`,
        signIn: (lang?: string) => `/${lang || i18nextInstance.language}/auth/${i18nextInstance.t("routes.signIn")}`,
        signUp: (lang?: string) => `/${lang || i18nextInstance.language}/auth/${i18nextInstance.t("routes.signUp")}`,
        askResetPassword: (lang?: string) =>
            `/${lang || i18nextInstance.language}/auth/${i18nextInstance.t("routes.askResetPassword")}`,
        resetPassword: (lang?: string) =>
            `/${lang || i18nextInstance.language}/auth/${i18nextInstance.t("routes.resetPassword")}`,
        verified: (lang?: string) => `/${lang || i18nextInstance.language}/auth/${i18nextInstance.t("routes.verify")}`,
    },

    user: {
        profile: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.profile")}`,
    },

    dashboard: {
        myListing: (lang: string) =>
            `/${lang || i18nextInstance.language}/dashboard/${i18nextInstance.t("routes.myListing")}`,
        promotion: (lang: string) =>
            `/${lang || i18nextInstance.language}/dashboard/${i18nextInstance.t("routes.promotion")}`,
        dashboardProfile: (lang: string) =>
            `/${lang || i18nextInstance.language}/dashboard/${i18nextInstance.t("routes.builderProfile")}`,
        home: (lang?: string) => `/${lang || i18nextInstance.language}/dashboard`,
    },

    unit: (propertyAlias: string, unitAlias: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t(
            "routes.properties",
        )}/${propertyAlias}/${i18nextInstance.t("routes.units")}/${unitAlias}`,

    property: (propertyAlias: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.properties")}/${propertyAlias}`,

    about: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.about")}`,

    buy: (lang?: string, p1?: string, p2?: string, p3?: string, p4?: string, p5?: string, p6?: string, p7?: string) => {
        let url = `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.buy")}`;
        if (p1) {
            url += `/${p1}`;
            if (p2) {
                url += `/${p2}`;
                if (p3) {
                    url += `/${p3}`;
                    if (p4) {
                        url += `/${p4}`;
                        if (p5) {
                            url += `/${p5}`;
                            if (p6) {
                                url += `/${p6}`;
                                if (p7) {
                                    url += `/${p7}`;
                                }
                            }
                        }
                    }
                }
            }
        }
        return url;
    },

    sell: (lang?: string, region?: string, city?: string, propertyType?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.sell")}/${region}/${city}/${propertyType}`,
    compare: (type: string, lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.compare")}/${type}`,
    blog: (lang?: string, blogType?: string) => {
        let url = `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.blog")}`;
        if (blogType) {
            url += `/${blogType}`;
        }
        return url;
    },
    contact: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.contact")}`,
    policy: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.policy")}`,
    terms: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.terms")}`,
    faq: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.faq")}`,
    testimonials: (lang?: string) => `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.testimonials")}`,
    localFavorites: (lang?: string) =>
        `/${lang || i18nextInstance.language}/${i18nextInstance.t("routes.localFavorites")}`,
    admin: () => "/zisla-admin",
    map: () => "/map",
};
